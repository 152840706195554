<template>
  <Landing :reset-query="false"/>
</template>

<script>
  import Landing from "./Landing";
  import {mapActions} from "vuex";

  export default {
    name: "QueryLanding",
    components: {Landing},
    data() {
      return {
      }
    },
    methods: {
      ...mapActions(['updateFilter']),
      filterQuery(query) {
        this.updateFilter({
          searchText: query,
        })
      }
    },
    watch: {
      '$route.params.query': {
        handler(new_val) {
          console.log('listen query change', new_val),
          this.filterQuery(new_val);
        },
        immediate: true,
      }
    }
  }
</script>