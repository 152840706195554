import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)
const state = {
  isMobileDevice: false,
  token: null,
  authModal: {
    show: false,
    activeTab: 'signIn'
  },
  currentUser: null,
  isLoading: false,
  topCreators: [],
  trends: {
    current_page: 1,
    data: [],
    has_next: true,
    per_page: 24,
  },
  tags: [],
  collectionModal: {
    show: false,
    object: null,
  },
  collections: {},
  otherUsersCollections: {},
  tabs: {
    'gallery': {isActive: true},
    'likedGifs': {isActive: false},
    'collection': {isActive: false},
  },
  activeTab: 'gallery',
  channelTypeOptions: [
    {value: 'public', text: 'Public'},
    {value: 'listed', text: 'Listed'},
    {value: 'private', text: 'Private'},
  ],
  voicememe_statuses: null,
  collection_statuses: null,
  comments: {
    current_page: 1,
    data: [],
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
  notifications: {
    current_page: 1,
    data: [],
    per_page: 10,
    has_next: true
  },
  likedMemes: {
    current_page: 1,
    data: [],
    per_page: 10,
    has_next: false
  },
  filter: {
    username: '',
    tag: '',
    searchText: '',
    error: ''
  },
  currentCollection: {},
  showOverlay: false,
  hideHeader: false,
  youtubeUrlModal: {
    show: false
  },
  profileColor: '#fe7153',
  colorPalette: [
    {color: '#5b10b3'},
    {color: '#a272f8'},
    {color: '#7bb7ff'},
    {color: '#1eebf0'},
    {color: '#562b76'},
    {color: '#b42ed3'},
    {color: '#e643c3'},
    {color: '#ff7dbb'},
    {color: '#ab2581'},
    {color: '#f53b75'},
    {color: '#ff8886'},
    {color: '#febd68'},
    {color: '#5badb3'},
    {color: '#87db90'},
    {color: '#5be499'},
    {color: '#00ffde'},
  ],
  showMenu: true,
  notificationsTotal: 0,
  isMuted: false,
  scrollDisabled: false,
  infoPopup: {
    show: false,
    text: '',
  },
  infoModal: {
    show: false,
    text: '',
  },
  memeResultsTopPos: null,
  shareModal: {
    show: false,
    link: null,
    uuid: null
  },
  extensionModal: {
    show: false,
    time: 0
  },
  randomAnimate: false,
  isClicked: false,
  openAppSticky: false,
  trendingMemes: [],
}
export default new Vuex.Store({
  state,
  actions: {
    async getMe({commit}) {
      await axios.get('auth/me').then((res) => {
        commit('setCurrentUser', res.data.data)
      }).catch(() => {
        commit('setToken', null);
        commit('setCurrentUser', null);
      })
    },
    openAuthModal({commit}, payload = 'signIn') {
      commit('openAuthModal', payload)
    },
    hideAuthModal({commit}) {
      commit('hideAuthModal')
    },
    async register({commit, dispatch}, payload) {
      commit('startLoader');
      let res;
      await axios.post('user/register/', payload).then((response) => {
        res = dispatch('attempt', response.data.data.access_token)
      }).catch((error) => {
        res = error.response.data
      });
      commit('stopLoader');

      return res
    },
    async login({commit, dispatch}, payload) {
      commit('startLoader');
      let res;
      let url = payload.provider ? 'auth/social-token' : 'auth/token';
      await axios.post(url, payload).then((response) => {
        res = dispatch('attempt', response.data.data.access_token)
      }).catch((error) => {
        res = error.response.data
      });
      commit('stopLoader');
      return res
    },
    async attempt({commit, dispatch, state}, token) {
      if (token) {
        commit('setToken', token)
      }
      if (!state.token) {
        return
      }
      let response;
      await axios.get('auth/me').then((res) => {
        commit('setCurrentUser', res.data.data)
        dispatch('getStatuses')
        dispatch('getNotificationsTotal')
        response = res
      }).catch(() => {
        commit('setToken', null);
        commit('setCurrentUser', null);
      })
      commit('stopLoader');
      return response.data
    },
    async getTopCreators({commit}) {
      let response = await axios.get('homepage/creators/')
      if (response.data.result) {
        commit('getTopCreators', response.data.data)
      }
    },
    async getTrends({commit}, {page = '', per_page = '', q = '', username = '', tags = ''}) {
      let res;
      await axios.get(`web/search?page=${page}&per_page=${per_page}&username=${username}&q=${q}&tags=${tags}`)
        .then((response) => {
          commit('setTrends', response.data.data)
          res = response.data;
        }).catch((error) => {
          res = error.response.data
        });
      return res
    },
    async getTrendingMemes({commit}) {
      let res;
      await axios.get(`web/trending?per_page=14`)
        .then((response) => {
          commit('setTrendingMemes', response.data.data.data)
          res = response.data;
        }).catch((error) => {
          res = error.response.data
        });
      return res
    },
    async getRelatedTrends({commit}, uuid) {
      let res;
      await axios.get(`voicememe/${uuid}/related`)
        .then((response) => {
          commit('setTrends', response.data)
          res = response.data;
        }).catch((error) => {
          res = error.response.data
        });
      return res
    },
    clearTrends({commit}) {
      commit('clearTrends')
    },
    async getTags({commit}) {
      let response = await axios.get('homepage/tags/')
      if (response.data.result) {
        commit('getTags', response.data.data)
        return true
      } else {
        return false
      }
    },
    async logOut({commit}) {
      commit('startLoader')
      let response = await axios.post('auth/logout/')

      if (response.data.result) {
        commit('logOut')
        commit('stopLoader')
      }
      return response.data
    },
    showCollectionModal({commit}, object = null) {
      commit('showCollectionModal', object)
    },
    hideCollectionModal({commit}) {
      commit('hideCollectionModal')
    },
    async createCollection({commit}, payload) {
      let res;
      await axios.post(`user/collections/`, payload).then((response) => {
        res = response.data
        commit('createCollection', res.data)
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    async getCollectionData(_, {page, per_page, uuid}) {
      let res;
      await axios.get(`user/collections/${uuid}?page=${page}&per_page=${per_page}`).then((response) => {
        res = response.data
      }).catch((error) => {
        if (error.response.status === 404) {
          Vue.prototype.$helper.redirectTo404();
        }
        res = error.response.data
      });
      return res
    },
    async updateProfile({commit}, payload) {
      commit('startLoader');
      let res;
      await axios.patch('user/profile/', payload).then((response) => {
        res = response
        commit('setCurrentUser', res.data.data)
      }).catch((error) => {
        res = error.response.data
      });
      commit('stopLoader');
      return res
    },
    async getCollections({commit}, username) {
      let res;
      await axios.get(`user/${username}/collections/`).then((response) => {
        res = response
        commit('setCollections', {data: res.data.data, username})
      }).catch((error) => {
        res = error.response.data
        commit('setCollections', {})
      });
      return res
    },
    async deleteCollection({commit}, uuid) {
      let res;
      await axios.delete(`user/collections/${uuid}`).then((response) => {
        res = response
        commit('deleteCollection', uuid)
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    async updateCollection({commit}, payload) {
      let res;
      await axios.patch(`user/collections/${payload.uuid}`, payload).then((response) => {
        res = response
        commit('updateCollection', payload)
      }).catch((error) => {
        res = error.response.data
      });
      return res.data
    },
    async addMemeToCollection(_, payload) {
      let response = await axios.post(`user/collections/${payload.collection_uuid}/add`,
        {voice_memes: payload.voice_memes})
      return response.data
    },
    async removeMemeFromCollection(_, payload) {
      let response = await axios.delete(`user/collections/${payload.collection_uuid}/remove`,
        {data: {voice_memes: payload.voice_memes}})
      return response.data
    },
    setActiveTab({commit}, tab) {
      commit('setActiveTab', tab)
    },
    async createMeme({commit}, payload) {
      commit('startLoader');

      let res;
      await axios.post(`/voicememe/create-with-video`, payload).then((response) => {
        res = response.data
      }).catch((error) => {
        res = error.response.data
      });
      commit('stopLoader');

      return res
    },
    async createMemeFromGallery({commit}, payload) {
      commit('startLoader');
      let res;
      await axios.post(`voicememe`, payload).then((response) => {
        res = response.data
      }).catch((error) => {
        res = error.response.data
      });
      commit('stopLoader');

      return res
    },
    async updateMeme({commit}, payload) {
      commit('startLoader');
      let res;
      await axios.patch(`voicememe/${payload.uuid}`, payload).then((response) => {
        res = response.data
      }).catch((error) => {
        res = error.response.data
      });
      commit('stopLoader');
      return res
    },
    async getMeme(_, payload) {
      let res;
      await axios.get(`voicememe/${payload}?source=web`).then((response) => {
        res = response.data
      }).catch((error) => {
        if (error.response.status === 404) {
          Vue.prototype.$helper.redirectTo404();
        }
        res = error.response.data
      });
      return res
    },
    async deleteMeme({commit}, uuid) {
      let res;
      await axios.delete(`voicememe/${uuid}`).then((response) => {
        res = response.data
        commit('deleteMeme', uuid)
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    async getComments({commit}, {page = '', per_page = '', id}) {
      let res;
      await axios.get(`comment/voicememe/${id}/?page=${page}&per_page=${per_page}`).then((response) => {
        commit('setComments', response.data.data)
        res = response.data
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    clearComments({commit}) {
      commit('clearComments')
    },
    async addComment({commit}, payload) {
      let res;
      await axios.post(`comment`, payload).then((response) => {
        commit('addComment', response.data.data)
        res = response.data;
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    async updateComment({commit}, payload) {
      let res;
      await axios.patch(`comment/${payload.uuid}`, payload.data).then((response) => {
        commit('updateComment', response.data.data)
        res = response.data;
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    async deleteComment({commit}, uuid) {
      let res;
      await axios.delete(`comment/${uuid}`).then((response) => {
        res = response
        commit('deleteComment', uuid)
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    async getUserProfile(_, username) {
      let res;
      await axios.get(`user/${username}`).then((response) => {
        res = response.data
      }).catch((error) => {
        if (error.response.status === 404) {
          Vue.prototype.$helper.redirectTo404();
        }
        res = error.response.data
      });
      return res
    },
    async getFileUuid({commit}, payload) {
      commit('startLoader')
      let res;
      await axios.post('file', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        res = response.data;
      }).catch((error) => {
        res = error.response.data
      });
      commit('stopLoader');
      return res
    },
    async follow(_, username) {
      let res;
      await axios.post(`user/${username}/follow`).then((response) => {
        res = response.data;
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    async unfollow(_, username) {
      let res;
      await axios.delete(`user/${username}/unfollow`).then((response) => {
        res = response.data;
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    updateFilter({commit}, payload) {
      commit('updateFilter', payload)
    },
    updateFilterError({commit}, payload) {
      commit('updateFilterError', payload)
    },
    async removeReaction(_, payload) {
      let res;
      await axios.delete(`reaction`, {data: payload}).then((response) => {
        res = response.data;
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    async addReaction(_, payload) {
      let res;
      await axios.post(`reaction`, payload).then((response) => {
        res = response.data;
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    async getLinkFileId({commit}, payload) {
      commit('startLoader');

      let res;
      await axios.post(`/file/link`, payload).then((response) => {
        res = response.data;
      }).catch((error) => {
        res = error.response.data
      });
      commit('stopLoader');

      return res
    },
    async getStatuses({commit}, payload) {
      let res;
      await axios.get(`/config/`, payload).then((response) => {
        res = response.data;
        commit('setStatuses', res.data);
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    async getLikedMemes({commit}, {username, page = 1, per_page = 14}) {
      let res;
      await axios.get(`user/${username}/liked?page=${page}&per_page=${per_page}`).then((response) => {
        res = response.data
        commit('setLikedMemes', res.data)
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    clearLikedMemes({commit}) {
      commit('clearLikedMemes')
    },
    updateCurrentCollection({commit}, payload) {
      commit('updateCurrentCollection', payload)
    },
    showOverlay({commit}) {
      commit('showOverlay')
    },
    hideOverlay({commit}) {
      commit('hideOverlay')
    },
    setProfileColor({commit}, color) {
      commit('setProfileColor', color)
    },
    updateHideHeader({commit}, payload) {
      commit('updateHideHeader', payload)
    },
    showYoutubeUrlModal({commit}, payload) {
      commit('showYoutubeUrlModal', payload)
    },
    showMenu({commit}, payload) {
      commit('showMenu', payload)
    },
    async getNotificationsTotal({commit}) {
      let res;
      await axios.get(`/user/notifications/count-new`).then((response) => {
        res = response.data
        commit('setNotificationsTotal', res.data)
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    async getNotifications({commit}, {page = 1, per_page = 10}) {
      let res;
      await axios.get(`/user/notifications?page=${page}&per_page=${per_page}`).then((response) => {
        commit('setNotifications', response.data.data)
        res = response.data
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    clearNotifications({commit}) {
      commit('clearNotifications')
    },
    changeIsMuted({commit}, payload) {
      commit('changeIsMuted', payload)
    },
    async seeRenderedNotifications({dispatch}, payload) {
      let res;
      await axios.post(`user/notifications/see`, payload).then((response) => {
        res = response.data;
        dispatch('getNotifications')
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    disableScroll({commit}, payload) {
      commit('disableScroll', payload)
    },
    updateInfoPopup({commit}, payload) {
      commit('updateInfoPopup', payload)
    },
    updateInfoModal({commit}, payload) {
      commit('updateInfoModal', payload)
    },
    updateIsMobileDevice({commit}, payload) {
      commit('updateIsMobileDevice', payload)
    },
    updateMemeResultsTopPos({commit}, payload) {
      commit('updateMemeResultsTopPos', payload)
    },
    async shareCounterRequest(_, uuid) {
      let res;
      let user_uuid = state.currentUser ? state.currentUser.uuid : ''
      await axios.post(`/web/share/${uuid}?customer_id=${user_uuid}`).then((response) => {
        res = response.data
      }).catch((error) => {
        res = error.response.data
      });
      return res
    },
    updateShareModal({commit}, payload) {
      commit('updateShareModal', payload)
    },
    updateExtensionModal({commit}, payload) {
      commit('updateExtensionModal', payload)
    },
    updateRandomAnimate({commit}, payload) {
      commit('updateRandomAnimate', payload)
    },
    updateIsClicked({commit}, payload) {
      commit('updateIsClicked', payload)
    },
    updateOpenAppSticky({commit}, payload) {
      commit('updateOpenAppSticky', payload)
    },
    async increaseView(_, slug) {
      let res;
      let user_uuid = state.currentUser ? state.currentUser.uuid : ''
      await axios.post(`/web/view/${slug}?customer_id=${user_uuid}`).then((response) => {
        res = response.data;
      }).catch(() => {
      });
      return res
    },
    async increaseDownload(_, slug) {
      let res;
      let user_uuid = state.currentUser ? state.currentUser.uuid : ''
      await axios.post(`/web/download/${slug}?customer_id=${user_uuid}`).then((response) => {
        res = response.data;
      }).catch(() => {
      });
      return res
    }
  },
  mutations: {
    openAuthModal(state, value) {
      state.authModal.show = true
      state.authModal.activeTab = value
    },
    hideAuthModal(state) {
      state.authModal.show = false
      state.authModal.activeTab = 'signIn'
    },
    setToken(state, token) {
      state.token = token;
    },
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
    },
    startLoader(state) {
      state.isLoading = true;
    },
    stopLoader(state) {
      state.isLoading = false;
    },
    getTopCreators(state, payload) {
      state.topCreators = payload
    },
    setTrends(state, payload) {
      if (payload.data.length) {
        state.trends.data = [...state.trends.data, ...payload.data];
        state.trends.has_next = payload.has_next;
        state.trends.per_page = payload.per_page;
        state.trends.current_page = payload.current_page;
      }
    },
    setTrendingMemes(state, payload) {
      if (payload.length) {
        state.trendingMemes = payload.filter(g => payload.indexOf(g) < 14);
      }
    },
    clearTrends(state) {
      state.trends.data = [];
    },
    deleteMeme(state, uuid) {
      state.trends.data = [...state.trends.data.filter(c => c.uuid !== uuid)]
    },
    getTags(state, payload) {
      state.tags = payload
    },
    logOut(state) {
      state.token = null;
      state.currentUser = null;
    },
    showCollectionModal(state, object) {
      state.collectionModal.show = true
      state.collectionModal.object = object
    },
    hideCollectionModal(state) {
      state.collectionModal.show = false
      state.collectionModal.object = null
    },
    createCollection(state, payload) {
      state.collections.data.unshift(payload)
    },
    setCollections(state, payload) {
      if (state.currentUser && payload.username === state.currentUser.username) {
        state.collections = payload.data
      } else {
        state.otherUsersCollections = payload.data
      }
    },
    deleteCollection(state, uuid) {
      state.collections.data = [...state.collections.data.filter(c => c.uuid !== uuid)]
    },
    updateCollection(state, collection) {
      let inx = state.collections.data.findIndex(c => c.uuid === collection.uuid)
      state.collections.data[inx] = collection;
      state.collections.data = [...state.collections.data];
    },
    setActiveTab(state, tab) {
      state.activeTab = tab;
    },
    setComments(state, comments) {
      state.comments.current_page = comments.current_page;
      state.comments.data = [...state.comments.data, ...comments.data];
      state.comments.from = comments.from;
      state.comments.last_page = comments.last_page;
      state.comments.per_page = comments.per_page;
      state.comments.to = comments.to;
      state.comments.total = comments.total;
    },
    clearComments(state) {
      state.comments.data = [];
    },
    updateComment(state, comment) {
      let inx = state.comments.data.findIndex(c => c.uuid === comment.uuid)
      state.comments.data[inx] = comment;
      state.comments.data = [...state.comments.data];
    },
    addComment(state, comment) {
      state.comments.data.unshift(comment)
    },
    deleteComment(state, uuid) {
      state.comments.data = [...state.comments.data.filter(c => c.uuid !== uuid)]
    },
    updateFilter(state, payload) {
      state.filter.username = payload.username ? payload.username : '';
      state.filter.tag = payload.tag ? payload.tag : '';
      state.filter.searchText = payload.searchText ? payload.searchText : '';
    },
    updateFilterError(state, payload) {
      state.filter.error = payload;
    },
    setStatuses(state, payload) {
      state.voicememe_statuses = payload.voicememe_statuses;
      state.collection_statuses = payload.collection_statuses;
    },
    setLikedMemes(state, memes) {
      state.likedMemes.current_page = memes.current_page;
      state.likedMemes.data = [...state.likedMemes.data, ...memes.data];
      state.likedMemes.per_page = memes.per_page;
      state.likedMemes.has_next = memes.has_next;
    },
    clearLikedMemes(state) {
      state.likedMemes.data = [];
    },
    updateCurrentCollection(state, collection) {
      state.currentCollection = collection
    },
    hideOverlay(state) {
      state.showOverlay = false
    },
    showOverlay(state) {
      state.showOverlay = true
    },
    setProfileColor(state, color) {
      state.profileColor = color;
    },
    updateHideHeader(state, value) {
      state.hideHeader = value
    },
    showYoutubeUrlModal(state, value) {
      state.youtubeUrlModal.show = value
      state.showOverlay = value
    },
    showMenu(state, value) {
      state.showMenu = value
    },
    setNotificationsTotal(state, total) {
      state.notificationsTotal = total.count
    },
    setNotifications(state, notifications) {
      state.notifications.current_page = notifications.current_page;
      state.notifications.data = [...state.notifications.data, ...notifications.data];
      state.notifications.per_page = notifications.per_page;
      state.notifications.has_next = notifications.has_next;
    },
    clearNotifications(state) {
      state.notifications.current_page = 1;
      state.notifications.data = [];
      state.notifications.per_page = 10;
      state.notifications.has_next = true;
    },
    changeIsMuted(state, isMuted) {
      state.isMuted = isMuted
    },
    disableScroll(state, val) {
      state.scrollDisabled = val
    },
    updateInfoPopup(state, data) {
      state.infoPopup.show = data.show
      state.infoPopup.text = data.text ? data.text : 'Link copied successfully'
    },
    updateInfoModal(state, data) {
      state.infoModal.show = data.show
      state.infoModal.text = data.text
      state.infoModal.callback = data.callback
    },
    updateIsMobileDevice(state, val) {
      state.isMobileDevice = val
    },
    updateMemeResultsTopPos(state, val) {
      state.memeResultsTopPos = val
    },
    updateShareModal(state, val) {
      state.shareModal.show = val.show
      state.shareModal.link = val.link
      state.shareModal.uuid = val.uuid
      state.shareModal.meme = val.meme
    },
    updateExtensionModal(state, {show, time}) {
      state.extensionModal.show = show
      state.extensionModal.time = time
    },
    updateRandomAnimate(state, val) {
      state.randomAnimate = val
    },
    updateIsClicked(state, val) {
      state.isClicked = val
    },
    updateOpenAppSticky(state, val) {
      state.openAppSticky = val
    }
  }
})
