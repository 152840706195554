<template>
  <div class="responsive-modal" v-if="show">
    <div class="close-btn" @click="hideModal($event)">
      <b-icon icon="x" class="close-btn-icon"></b-icon>
    </div>
    <div class="content">
      <slot name="modal-content"></slot>
      <button v-if="submitText" class="button-general" @click="submit($event)">{{ submitText }}</button>
    </div>
  </div>
</template>

<script>
  import {mapActions} from "vuex";

  export default {
    name: "ResponsiveModal",
    props: {
      submitText: {
        type: String,
        default: ''
      },
      show: {
        type: Boolean
      },
    },
    data() {
      return {
        showModal: false,
      }
    },
    watch: {
      show() {
        if (this.show) {
          this.showOverlay()
        }
      }
    },
    methods: {
      ...mapActions(['showOverlay', 'hideOverlay']),
      hideModal(e) {
        e.stopPropagation();
        this.$emit('hideModal', false)
        this.hideOverlay();
      },
      submit(e) {
        e.stopPropagation();
        this.$emit('onSubmit')
        this.hideOverlay();
      }
    },

  }
</script>

<style lang="scss" scoped>
  .responsive-modal {
    width: 100%;
    left: 0;
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;


    .content {
      background: #ffffff;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
      border-radius: 13px;
      padding: 2rem;

      .button-general {
        background-color: #1a1a1a;
        font-family: "Open Sans", sans-serif;
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        color: #fff;
      }
    }

    .close-btn {
      align-self: flex-end;
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 50%;
      margin-bottom: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      .close-btn-icon {
        width: 25px;
        height: 25px;
      }

    }
  }

</style>