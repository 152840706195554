<template>
  <transition name="fade">
    <div v-if="extensionModal.show" class="extension-modal">
      <div class="close-btn btn-hover" @click="hideModal()">
        <img src="../../assets/images/cross.svg" alt="close">
      </div>
      <div>
        <div class="text">
          Psst!
          <br>
          Check our cool <strong>Browser Extension</strong>.
        </div>
        <div class="extension-gif">
          <video loop autoplay muted>
            <source src="../../assets/images/extension.mp4" type="video/mp4"/>
          </video>
          <video loop autoplay muted>
            <source src="../../assets/images/messaging.mp4" type="video/mp4"/>
          </video>
        </div>
        <div class="text">
          Send and View <strong>Clips & Memes</strong> instantly.
        </div>
        <div @click="hideModal()">
          <a class="btn-hover"
             href="https://chrome.google.com/webstore/detail/kikliko-gifs-with-sound/eecamihehojmibkhhpfijgpmnbikhjgi"
             target="_blank">
            <img src="../../assets/images/extension_light.svg" alt="extension">
            Install extension
          </a>
        </div>
        <div class="text btn-hover" @click="hideModal()">Not now</div>
      </div>

    </div>
  </transition>
</template>
<script>
  import {mapActions, mapState} from "vuex";

  export default {
    name: "ExtensionModal",
    watch: {
      'extensionModal.show': {
        handler() {
          if (this.extensionModal.show) {
            this.showOverlay()
          }
        }
      }
    },
    computed: {
      ...mapState(['extensionModal'])
    },
    methods: {
      ...mapActions(['updateExtensionModal', 'hideOverlay', 'showOverlay']),
      hideModal() {
        let currentTime = new Date()
        localStorage.setItem('notNowClickTime',  currentTime.getTime().toString())
        this.hideOverlay()
        this.updateExtensionModal({show: false, time: currentTime.getTime()})
      }
    },
  }
</script>

<style lang="scss" scoped>
  .extension-modal {

    border-radius: 12px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    position: fixed;
    padding: 22px 44px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
    width: 450px;

    .close-btn {
      float: right;
      margin-right: -30px;
      width: 24px;

      img {
        width: 100%;
      }
    }

    .extension-gif {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 49.5%;
      }

      video {
        object-fit: fill;
        width: 49.5%;
        height: 185px;
      }
    }

    .text {
      font-family: "Exo 2", sans-serif;
      font-size: 20px;
    }

    a {
      margin: 20px 0;
      height: 55px;
      width: 100%;
      border-radius: 60px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
      background: rgb(255, 114, 71);
      background: linear-gradient(90deg, rgba(255, 114, 71, 1) 0%, rgba(255, 115, 113, 1) 100%);
      color: #ffffff !important;
      font-size: 22px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 24px;
        margin-right: 10px;
      }
    }
  }

</style>