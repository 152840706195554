<template>
  <div class="comment-container d-flex">
    <b-avatar :src="data.user.avatar_url"/>
    <div class="ml-2 w-100">
      <div class="avatar d-flex justify-content-between align-items-md-start">
        <div>
          <router-link :to="{name:'user', params:{id: data.user.username}}">
            <span class="username">@{{ data.user.username }}</span>
          </router-link>
          <div class="date">{{ data.created_at }}</div>
        </div>
        <div class="align-content-end modify-btns d-flex" v-if="currentUser">
          <div class="edit pointer btn-hover" @click="onEditClick()"
               v-if="data.user && (data.user.username === currentUser.username)">
            <img src="../../assets/icons/pen.svg" alt="edit" class="mr-2">
            <div>Edit</div>
          </div>
          <div class="remove pointer btn-hover" @click="deleteComment(data.uuid)"
               v-if="data.user && (data.user.username === currentUser.username)">
            <img src="../../assets/icons/bin.svg" alt="remove" class="mr-2">
            <div>Delete</div>
          </div>
        </div>
      </div>
      <div class="comment">
        <p>{{ data.comment }}</p>
        <div class="error-msg">{{ commentError }}</div>
      </div>
    </div>

  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";

  export default {
    name: "Comment",
    props: {
      data: {
        type: Object,
        default: () => {
        },
      },
    },
    data() {
      return {
        commentError: ''
      }
    },
    computed: {
      ...mapState(['currentUser'])
    },
    methods: {
      ...mapActions(['deleteComment', 'updateComment']),
      onEditClick() {
        this.$emit('onEdit', this.data.uuid)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .comment-container {
    margin: 1rem 0;
    font-family: "Open Sans", sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    border-bottom: 1px solid #f0f0f0;

    .avatar {
      .username {
        font-size: 15px;
        font-weight: bold;
        color: #1a1a1a;
      }

      span {
        width: 3rem;
        height: 3rem;
      }

      .date {
        font-family: "Open Sans", sans-serif;
        font-size: 12px;
        color: #696969;
      }

      .icon {
        cursor: pointer;
      }
    }

    .comment {
      font-size: 15px;
      font-weight: normal;
      color: #1a1a1a;
      margin-top: 15px;


      textarea {
        font-weight: normal;
        padding: 1rem 3.5rem 1rem 1rem;
      }

      .done {
        cursor: pointer;
        right: -1rem;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
    }

    .modify-btns {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 500;
      color: #707070;

      .edit,
      .remove {
        display: flex;
        align-items: center;
      }

      .edit {
        margin-right: 40px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .comment-container {
      margin: 0.5rem 0;

      .avatar {
        margin: 15px 0;

        span {
          width: 2.5rem;
          height: 2.5rem;
        }

        .username {
          font-size: 12px;
        }

        .date {
          font-size: 10px;
        }
      }

      .comment {
        font-size: 12px;
        margin-top: 0;
      }

    }
  }

  @media only screen and (max-width: 992px) {
    .comment-container {
      .avatar {
        margin: 5px 0;

        span {
          width: 2rem;
          height: 2rem;
        }
      }

    }
  }

  @media only screen and (max-width: 428px) {
    .comment-container {
      border-bottom: none;
    }
  }
</style>