<template>
  <div class="notification btn-hover" @click="goToMeme">
    <div class="d-flex align-items-center">
      <template v-if="item.user_from">
        <b-avatar v-if="item.user_from.avatar_url" :src="item.user_from.avatar_url" class="mr-3"
                  size="1.5rem"></b-avatar>
        <b-avatar v-else class="mr-3" size="2rem"></b-avatar>
      </template>
      <span v-if="item.type === 'like'" class="mr-3 text text-wrap"><span
          class="font-weight-bold">@{{ item.user_from.username }}</span> liked your meme</span>
      <span v-if="item.type === 'follow'" class="mr-3 text text-wrap"><span
          class="font-weight-bold">@{{ item.user_from.username }} </span> is now following you</span>

      <span v-if="item.type === 'video_processed' && item.relation && item.relation.title" class="mr-3 text text-wrap">Your video <span
          class="font-weight-bold">{{ item.relation.title }} </span> has been uploaded</span>

      <span v-if="item.type === 'video_review_pending' && item.relation && item.relation.title"
            class="mr-3 text text-wrap">Your video <span
          class="font-weight-bold">{{ item.relation.title }} </span> is in under review </span>

      <span v-if="item.type === 'video_review_rejected' && item.relation && item.relation.title"
            class="mr-3 text text-wrap">Your video <span
          class="font-weight-bold">{{ item.relation.title }} </span> has been rejected</span>

      <span v-if="item.type === 'video_review_approved' && item.relation && item.relation.title"
            class="mr-3 text text-wrap">Your video <span
          class="font-weight-bold">{{ item.relation.title }} </span> has been approved </span>
    </div>

    <picture
        v-if="item.relation && item.relation.file && (item.relation.file.thumbnail_url || item.relation.file.thumbnail_url_webp)">
      <source :srcset="item.relation.file.thumbnail_url_webp" v-if="item.relation.file.thumbnail_url_webp"
              type="image/webp">
      <source :srcset="item.relation.file.thumbnail_url" v-if="item.relation.file.thumbnail_url" type="image/gif">
      <img class="gif meme-gif" :src="item.relation.file.thumbnail_url"
           :alt="item.relation && item.relation.title ? item.relation.title : ''" width="22" height="22">
    </picture>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    goToMeme() {
      if (this.item.relation && this.$route.params.id !== this.item.relation.slug) {
        this.$router.path = '/';
        this.$router.push({name: 'meme.view', params: {id: this.item.relation.slug, uuid: this.item.relation.uuid}});
        this.$emit('closeNotifications')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notification {
  padding: 7px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .text {
    font-size: 13px;
  }

  @media only screen and (min-width: 429px) {
    max-width: 300px;
    min-width: 262px;
  }
}
</style>