<template>
  <div class="settings-page">
    <responsive-header title="Settings" @backClick="$router.go(-1)"/>
    <div class="list-container">
      <div class="color-dropdown">
        <div class="row position-relative w-100 list-item" id="color-dropdown"
             @click="showColorPallet()">
          <div class="col-2">
            <b-icon icon="circle-fill" :style="{color: profileColor, fill:profileColor}" aria-hidden="true"
                    class="color-picker-icon"></b-icon>
          </div>
          <div class="col-10 list-item-label">Profile color
          </div>
          <responsive-modal class="color-palette" submit-text="Save" :show="isColorDropdownVisible"
                            @hideModal="onHideColorPallet" @onSubmit="setColor">
            <template slot="modal-content">
              <div class="header">
                <div class="bullet" :style="{background:profilePreviewColor?profilePreviewColor:profileColor}">
                </div>
                <div class="text">Choose theme color</div>
              </div>

              <div class="bullets">
                <div v-for="(c, index) in colorPalette" :key="index" class="bullet" :style="{background:c.color}"
                     @click="setPreviewColor(c.color)">
                </div>
              </div>
            </template>
          </responsive-modal>
        </div>
      </div>

      <router-link @click="setActiveTab('liked-gifs')"
                   :to="{ name:'profile.liked-gifs', params:{id:currentUser.username}}">

        <div class="row w-100 list-item">
          <div class="col-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"
                 :style="{color: profileColor, fill:profileColor}">
              <path fill="none" d="M0 0H40V40H0z"/>
              <path
                d="M-21.965 916.035a6.575 6.575 0 0 0-2.618-2.4 6.263 6.263 0 0 0-3.809-.7 6.625 6.625 0 0 0-3.515 1.652c-.117.1-.233.21-.369.334a.873.873 0 0 0-.085-.1 6.419 6.419 0 0 0-4.865-1.917 6.243 6.243 0 0 0-4.27 1.841 6.287 6.287 0 0 0-1.9 5.429 6.455 6.455 0 0 0 1.961 3.9c2.406 2.4 4.254 4.251 6.394 6.394l1.527 1.532.1.1a1.579 1.579 0 0 0 1.129.487h.018a1.581 1.581 0 0 0 1.121-.462l.2-.2 2.322-2.323c1.847-1.848 3.471-3.472 5.606-5.61a6.249 6.249 0 0 0 1.939-4.508 6.584 6.584 0 0 0-.886-3.449z"
                transform="translate(52.268 -902.734)"/>
            </svg>
          </div>
          <div class="col-10 list-item-label">Liked
          </div>
        </div>


      </router-link>
      <router-link @click="setActiveTab('collection')"
                   :to="{ name:'profile.collection', params:{id:currentUser.username}}">
        <div class="row w-100 list-item">
          <div class="col-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"
                 :style="{color: profileColor, fill:profileColor}">
              <path fill="none" d="M0 0H40V40H0z"/>
              <path
                d="M915.782 899.445v-1.216-6.08a.98.98 0 0 0-1.087-1.086h-16.512a.981.981 0 0 0-1.119 1.114v19.188a1.131 1.131 0 0 0 .55 1.078.9.9 0 0 0 .432.117 1.267 1.267 0 0 0 .765-.336l.01-.008q3.054-2.377 6.125-4.76l1.482-1.151.084.065 1.377 1.071c2.015 1.566 4.1 3.186 6.171 4.8a1.069 1.069 0 0 0 1.176.187 1.021 1.021 0 0 0 .549-1q-.003-5.99-.003-11.983z"
                transform="translate(-886.422 -881.92)"/>
            </svg>
          </div>
          <div class="col-10 list-item-label">Collections
          </div>
        </div>

      </router-link>
      <router-link dropdown-item :to="{name: 'account.settings'}">
        <div class="row w-100 list-item">
          <div class="col-2">
            <b-icon icon="gear" aria-hidden="true" class="cameleon color-picker-icon ml-2 mr-1" size="3rem"></b-icon>
          </div>
          <div class="col-10 list-item-label">Account Settings
          </div>
        </div>
      </router-link>
      <router-link :to="{name: 'documentation.privacy'}" @click="setActiveTab('privacy')">
        <div class="row w-100 list-item">
          <div class="col-2">
            <img src="../../assets/icons/privacy.svg" alt="privacy" class="help-privacy"/>
          </div>
          <div class="col-10 list-item-label">Privacy
          </div>
        </div>
      </router-link>
      <router-link :to="{name:'api'}">
        <div class="row w-100 list-item">
          <div class="col-2">
            <b-icon icon="journal-bookmark" class="api-reference mt-1 m-2"></b-icon>
          </div>
          <div class="col-10 list-item-label">API
          </div>
        </div>

      </router-link>
      <div class="row w-100 list-item logout">
        <div class="col-2"></div>
        <div class="col-10 list-item-label">
          <div @click="onLogOutClick()"><strong>Log out</strong></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import ResponsiveHeader from "./ResponsiveHeader";
  import ResponsiveModal from "./ResponsiveModal";

  export default {
    name: "Settings",
    metaInfo() {
      return {
        title: 'Settings',
      };
    },
    components: {ResponsiveModal, ResponsiveHeader},
    data() {
      return {
        isColorDropdownVisible: false,
        profilePreviewColor: ''
      }
    },
    methods: {
      ...mapActions(['setActiveTab', 'updateHideHeader', 'logOut', 'setProfileColor', 'showMenu']),
      async onLogOutClick() {
        let res = await this.logOut()
        if (res.result) {
          localStorage.removeItem('token')
          if (this.$route.path !== '/') {
            this.$router.path = '/';
            await this.$router.push({name: 'main'});
          }
          this.updateHideHeader(false)

        }
        /*global gapi*/
        let auth2;
        if (gapi && gapi.auth2) {
          auth2 = gapi.auth2.getAuthInstance();
          auth2.signOut()
        }
      },
      setColor() {
        this.setProfileColor(this.profilePreviewColor);
        this.onHideColorPallet(false);
        localStorage.setItem('profileColor', this.profileColor)
      },
      setPreviewColor(color) {
        this.profilePreviewColor = color;
      },
      showColorPallet() {
        this.isColorDropdownVisible = true;
        this.showMenu(false)
      },
      onHideColorPallet(val) {
        this.isColorDropdownVisible = val;
        this.showMenu(true)
      }
    },
    mounted() {
      this.updateHideHeader(true)
    },
    beforeDestroy() {
      this.updateHideHeader(false)
    },
    computed: {
      ...mapState(["currentUser", 'profileColor', 'colorPalette'])
    },
  }
</script>

<style lang="scss" scoped>
  .api-reference {
    height: 23px;
    width: 23px;
  }

  .list-item {
    display: flex;
    align-items: center;

    .list-item-label {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      height: 50px;
      display: flex;
      align-items: center;
    }
  }


  #color-dropdown {
    /deep/ .btn {
      color: unset !important;
    }
  }

  .color-dropdown {
    .color-picker-icon {
      margin-left: 8px;
      margin-right: 2px;
      width: 30px;
      height: auto;
    }

    &:hover {
      background: unset !important;
      opacity: unset !important;
    }

    .color-palette {
      top: 2rem;

      .header {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .bullet {
          margin-right: 30px;
          margin-bottom: 0;
        }

        .text {
          font-family: "Open Sans", sans-serif;
          font-size: 20px;
          font-weight: 500;
          color: #1a1a1a;
        }
      }

      .bullets {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        gap: 10px;
        padding: 20px 0;
      }

      .bullet {
        width: 57px;
        height: 57px;
        border-radius: 50%;
        margin-bottom: 10px;


        &:hover {
          border: 8px solid black;
        }
      }
    }
  }

  .settings-page {
    .color-picker-icon {
      margin-left: 8px;
      margin-right: 6px;
      width: 27px;
      height: auto;
    }
  }

  .settings-page {
    margin: 0;

    .list-container {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-left: 30px;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      margin-top: 75px;
    }
  }

  .help-privacy {
    width: 20px;
    margin: 5px 13px 5px 10px;
  }

  .logout {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;

    .list-item-label{
     border-bottom: none;
    }
  }
</style>