<template>
  <div class="gif-container parent" :id="data.uuid" :ref="`parent_${data.uuid}`">
    <div style="height : 100%" @click="onBoxClick()" ref="video">
      <router-link :to="boxRouteObject ? boxRouteObject : {name:'meme.view', params: {id: data.slug}}">
        <video class="meme-video" :muted="isMuted || !isVisible" loop :id="`video_${data.uuid}`"
               v-if="videoPlayingWithSound===data.uuid || showVideo" :autoplay="showVideo"
               :style="{minHeight : '100%'}" playsinline>
          <source :src="data.file.url" type="video/mp4"/>
        </video>
      </router-link>
      <transition name="fade">
        <picture :style="{maxHeight : '100%'}" v-if="isVisible">
          <source :srcset="data.file.thumbnail_url_webp" type="image/webp">
          <source :srcset="data.file.thumbnail_url" type="image/gif">
          <img class="gif meme-gif" :src="data.file.thumbnail_url" :alt="data.title">
        </picture>
      </transition>
    </div>
    <div class="top-settings" v-if="isVisible">
      <div v-if="showVolumeButton">
        <b-icon v-if="isMuted || !showVideo"
                :class="(animateVolumeIcon && !volumeOnClicked) || (randomAnimate && index % 4 === 0)? 'volume animated-audio' : 'volume' "
                @click.stop="toggleVolume" icon="volume-mute-fill"></b-icon>
        <b-icon v-else class="volume" @click.stop="toggleVolume" icon="volume-up-fill"></b-icon>
      </div>
      <div v-else class="title color-danger"> {{ data.title }}</div>
      <div :class="['menu', {'hover-depended-icon': !isMobileDevice}, {'hover-depended-icon-visible': showVideo}]">
        <img class="menu-icon" src="../../assets/icons/dots_light.svg" alt="menu"
             @click="showCollectionsList" v-if="showSettingsButton">
      </div>
      <add-to-collection-modal :is-mine="isMine" :meme="data" :show-collections="showCollections"
                               @onHideCollectionsList="hideCollectionsList"/>
    </div>
    <div class="tag" v-if="tag && isVisible">{{ tag }}</div>
    <transition name="fade">
      <div class="bottom-settings" v-if="showBottomSettings && isVisible">
        <div @click="onReactClick"
             :class="['mouse-pointer', 'btn-hover', {'hover-depended-icon': !isMobileDevice}, {'hover-depended-icon-visible': showVideo}]"
             style="cursor: pointer">
          <div>
            <svg v-if="reacted" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
              <g id="Group_4310" transform="translate(0 -0.136)">
                <path :fill="profileColor" id="Path_733" class="cls-1"
                      d="M209.714,594.887A6.811,6.811,0,0,0,207,592.4a6.483,6.483,0,0,0-3.945-.722,6.859,6.859,0,0,0-3.641,1.711c-.121.108-.241.217-.383.346a.9.9,0,0,0-.088-.1,6.649,6.649,0,0,0-5.038-1.986,6.716,6.716,0,0,0-6.4,7.529,6.684,6.684,0,0,0,2.031,4.036c2.492,2.484,4.406,4.4,6.622,6.622l1.587,1.589.1.107a1.637,1.637,0,0,0,1.169.5h.019a1.639,1.639,0,0,0,1.16-.479l.2-.2,2.4-2.405c1.913-1.914,3.594-3.6,5.805-5.81a6.473,6.473,0,0,0,2.011-4.668A6.824,6.824,0,0,0,209.714,594.887Z"
                      transform="translate(-185.632 -588.798)"/>
                <rect fill="none" id="Rectangle_2110" class="cls-2" width="26" height="26"
                      transform="translate(0 0.136)"/>
              </g>
            </svg>

            <svg v-else xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
              <g id="Group_4306" transform="translate(0 -0.422)">
                <path fill="#ffffff" id="Path_2408" class="cls-1"
                      d="M198.552,676.035h-.005a2.667,2.667,0,0,1-1.912-.815l-.1-.105-1.841-1.844c-2.1-2.108-3.963-3.97-6.353-6.354a7.686,7.686,0,0,1-2.319-4.626,7.521,7.521,0,0,1,2.279-6.474,7.414,7.414,0,0,1,5.089-2.182,7.627,7.627,0,0,1,5.172,1.729,7.829,7.829,0,0,1,3.86-1.691,7.449,7.449,0,0,1,4.565.833,7.791,7.791,0,0,1,3.089,2.844l.015.027a7.819,7.819,0,0,1,1.046,4.115,7.5,7.5,0,0,1-2.322,5.37l-8.394,8.4A2.656,2.656,0,0,1,198.552,676.035Zm-4.846-20.395c-.082,0-.165,0-.247.005a5.446,5.446,0,0,0-3.755,1.611,5.533,5.533,0,0,0-1.69,4.776,5.709,5.709,0,0,0,1.729,3.446c2.408,2.4,4.268,4.264,6.373,6.374l1.97,1.976a.639.639,0,0,0,.454.2h0a.641.641,0,0,0,.458-.184l8.409-8.416a5.505,5.505,0,0,0,1.717-3.96,5.461,5.461,0,0,0-3.071-5.175,5.464,5.464,0,0,0-3.357-.622,5.829,5.829,0,0,0-3.1,1.459l-1.206,1.091-.66-.892A5.661,5.661,0,0,0,193.705,655.641Z"
                      transform="translate(-185.137 -651.592)"/>
                <rect fill="none" id="Rectangle_2110" class="cls-2" width="25.997" height="26"
                      transform="translate(0 0.422)"/>
              </g>
            </svg>
            <!--            <span class="total">{{ totalReactions }}</span>-->
            <span class="total"></span>
          </div>
        </div>
        <div>
          <!--          <div>-->
          <!--            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">-->
          <!--              <g id="Group_4303" transform="translate(0.001 0)">-->
          <!--                <g id="Group_4302" transform="translate(-0.001 2.141)">-->
          <!--                  <path fill="#ffffff" id="Path_2402" class="cls-1"-->
          <!--                        d="M276.149,658.706v-1.42a4.309,4.309,0,0,0-.035-.494,4.055,4.055,0,0,0-4-3.575H254.633a4.075,4.075,0,0,0-4.028,4.048v9.461a4.306,4.306,0,0,0,.04.523,4.024,4.024,0,0,0,1.135,2.313,3.94,3.94,0,0,0,2.367,1.163c.2.018.406.03.608.037v2.415a2.164,2.164,0,0,0,1.25,1.974,2.19,2.19,0,0,0,.924.207,2.158,2.158,0,0,0,1.39-.512c1.441-1.215,3.508-2.963,4.827-4.081,4.115,0,5.679,0,8.8,0a4.836,4.836,0,0,0,1.121-.122,4.043,4.043,0,0,0,3.084-3.959C276.151,663.433,276.15,661.549,276.149,658.706Zm-1.937,7.985a2.108,2.108,0,0,1-1.6,2.067,2.693,2.693,0,0,1-.659.069c-3.2,0-4.764,0-9.08,0a1.2,1.2,0,0,0-.772.283c-1.3,1.1-3.514,2.974-5.034,4.256a.218.218,0,0,1-.25.027.225.225,0,0,1-.129-.209v-4.357h-1.364a9.054,9.054,0,0,1-.977-.03,2,2,0,0,1-1.194-.6,2.1,2.1,0,0,1-.594-1.209,2.423,2.423,0,0,1-.022-.276v-9.443a2.129,2.129,0,0,1,2.1-2.113h17.47a2.117,2.117,0,0,1,2.084,1.876,2.572,2.572,0,0,1,.02.267v1.406C274.215,661.549,274.215,663.431,274.211,666.691Z"-->
          <!--                        transform="translate(-250.605 -653.218)"/>-->
          <!--                  <path fill="#ffffff" id="Path_2403" class="cls-1"-->
          <!--                        d="M279.543,664.13a1.74,1.74,0,0,0-1.241.52,1.78,1.78,0,0,0,1.231,3.037h.009a1.738,1.738,0,0,0,1.229-.508,1.8,1.8,0,0,0,.009-2.535A1.736,1.736,0,0,0,279.543,664.13Z"-->
          <!--                        transform="translate(-260.353 -657.12)"/>-->
          <!--                  <path fill="#ffffff" id="Path_2404" class="cls-1"-->
          <!--                        d="M260.638,664.131h-.005a1.741,1.741,0,0,0-1.244.517,1.8,1.8,0,0,0,.006,2.527,1.731,1.731,0,0,0,1.233.513h0a1.738,1.738,0,0,0,1.239-.516,1.789,1.789,0,0,0,.521-1.246,1.76,1.76,0,0,0-.512-1.268A1.734,1.734,0,0,0,260.638,664.131Z"-->
          <!--                        transform="translate(-253.57 -657.12)"/>-->
          <!--                  <path fill="#ffffff" id="Path_2405" class="cls-1"-->
          <!--                        d="M268.827,664.617l-.015.015a1.786,1.786,0,0,0,1.207,3.054h.021a1.734,1.734,0,0,0,1.222-.5,1.805,1.805,0,0,0,.031-2.526A1.743,1.743,0,0,0,268.827,664.617Z"-->
          <!--                        transform="translate(-256.945 -657.119)"/>-->
          <!--                </g>-->
          <!--                <rect fill="none" id="Rectangle_2110" class="cls-2" width="25.81" height="26"-->
          <!--                      transform="translate(0.189 0)"/>-->
          <!--              </g>-->
          <!--            </svg>-->
          <!--            <span class="total">{{ data.total_comments }}</span>-->
          <!--          </div>-->
        </div>
        <div
          :class="['mouse-pointer', 'd-flex', 'align-items-center', {'hover-depended-icon': !isMobileDevice}, {'hover-depended-icon-visible': showVideo}]"
          :id="`copy-btn-${data.uuid}`">
          <img src="../../assets/images/chain_light.svg" alt="share" class="not-for-mobile"
               @click="copyLink(data.slug)">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" class="for-mobile"
               @click="copyShareLink(data.slug)">
            <g id="Group_4305" transform="translate(0.045 -0.422)">
              <path fill="#ffffff" id="Path_2407" class="cls-1"
                    d="M381.365,675.865a1.329,1.329,0,0,1-.5-.1,1.4,1.4,0,0,1-.768-.76c-1.1-2.578-.452-7.038,1.449-9.943a11.619,11.619,0,0,1,8.085-5.274v-.357c0-1.224-.006-2.425.017-3.627a2,2,0,0,1,.037-.339,1.723,1.723,0,0,1,.276-.656,1.6,1.6,0,0,1,1.056-.68,1.558,1.558,0,0,1,1.209.3l.137.119q4.657,4.386,9.336,8.82h0a1.65,1.65,0,0,1,.021,2.751q-4.631,4.42-9.286,8.813a1.617,1.617,0,0,1-.784.4,1.55,1.55,0,0,1-1.207-.27,1.68,1.68,0,0,1-.671-1.082,1.984,1.984,0,0,1-.028-.278c-.023-1.228-.019-2.444-.016-3.655V669.7c-2.561-.005-5.711,2.283-7.123,5.346a1.408,1.408,0,0,1-.684.692A1.32,1.32,0,0,1,381.365,675.865Zm-.491-1.691v0Zm8.863-6.479a5.427,5.427,0,0,1,1.157.121l.767.167,0,2.069c0,.964-.005,1.931.005,2.9q4.321-4.077,8.636-8.2-4.36-4.131-8.731-8.249c-.012.962-.009,1.935-.006,2.925l0,2.224-.909.054a9.651,9.651,0,0,0-7.507,4.483,10.923,10.923,0,0,0-1.6,6.735C383.481,669.829,386.826,667.695,389.737,667.695Z"
                    transform="translate(-378.109 -651.758)"/>
              <rect fill="none" id="Rectangle_2110" class="cls-2" width="26" height="26"
                    transform="translate(-0.045 0.422)"/>
            </g>
          </svg>
          <!--          <span class="total">{{ data.total_shares }}</span>-->
          <span class="total"></span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>import {mapActions, mapState} from "vuex";
import AddToCollectionModal from "./AddToCollectionModal";

export default {
  name: "GifBox",
  components: {AddToCollectionModal},
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    showBottomSettings: {
      type: Boolean,
      default: true,
    },
    showVolumeButton: {
      type: Boolean,
      default: true,
    },
    currentUser: {
      type: Object,
      default: () => {
      },
    },
    tag: {
      type: String
    },
    isMuted: {
      type: Boolean,
      default: true,
    },
    videoPlayingWithSound: {
      type: String
    },
    showSettingsButton: {
      type: Boolean,
      default: true
    },
    isTag: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
    },
    boxRouteObject: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      showVideo: false,
      error: '',
      reacted: false,
      totalReactions: 0,
      isMine: false,
      showCollections: false,
      loading: false,
      isVisible: true,
      showPopup: false,
      isMutedMobile: true,
      animateVolumeIcon: false,
      shouldAnimate: false,
      volumeOnClicked: null,

      increaseViewTimeout: null
    }
  },
  watch: {
    currentUser() {
      if (this.currentUser) {
        this.isMine = this.data.author.username === this.currentUser.username;
      }
    },
    showVideo() {
      if (this.showVideo) {
        this.increaseViewTimeout = setTimeout(() => {
          this.increaseView(this.data.slug)
        }, 1000)
        this.$nextTick(() => {
          let vid = document.getElementById(`video_${this.data.uuid}`)//this.$refs.video
          vid.play().catch(() => {
          })
          if (vid.paused) {
            this.animateVolumeIcon = true
            vid.muted = true;
            this.changeIsMuted(true)
            vid.play().catch(() => {
            })
          }
          if (!this.isClicked) {
            this.animateVolumeIcon = true
          }
        })
      } else {
        clearTimeout(this.increaseViewTimeout)
      }
    },
    isMuted() {
      if (!this.isMuted) {
        this.updateRandomAnimate(false)
      }
    }
  },
  mounted() {
    this.volumeOnClicked = localStorage.getItem('volumeOnClicked')
    const parent = this.$refs[`parent_${this.data.uuid}`]
    if (!this.isMobileDevice) {
      parent.addEventListener('mouseenter', () => {
        this.showVideo = true;
        this.updateRandomAnimate(false)
      });
      parent.addEventListener('mouseleave', () => {
        this.showVideo = false;
        this.animateVolumeIcon = false
      });
    }
    this.$nextTick(() => {
      window.addEventListener('scroll', this.isInViewPort)
    })

    this.reacted = this.data.liked;
    this.totalReactions = this.data.total_reactions;
    if (this.currentUser) {
      this.isMine = this.data.author.username === this.currentUser.username;
    }
    setTimeout(() => {
      if (!this.showVideo) {
        this.updateRandomAnimate(true)
      }
    }, 2000)
  },
  computed: {
    ...mapState(['profileColor', 'isMobileDevice', 'randomAnimate', 'isClicked']),
  },
  methods: {
    ...mapActions(['addMemeToCollection', 'showCollectionModal', 'deleteMeme',
      'removeReaction', 'addReaction', 'showOverlay', 'increaseView',
      'hideOverlay', 'updateInfoPopup', 'openAuthModal', 'shareCounterRequest', 'updateShareModal', 'changeIsMuted', 'updateRandomAnimate', 'updateIsClicked']),
    toggleVolume() {
      this.volumeOnClicked = localStorage.getItem('volumeOnClicked')
      if (!this.volumeOnClicked) {
        localStorage.setItem('volumeOnClicked', 'on')
      }
      this.updateIsClicked(true)
      if (this.isMobileDevice) {
        this.showVideo = !this.showVideo
        if (this.showVideo) {
          this.increaseView(this.data.slug)
        }
        this.$emit('onVolumeChange', {isMuted: !this.showVideo, uuid: this.data.uuid})
      } else {
        this.animateVolumeIcon = false//!this.isMuted
        this.$emit('onVolumeChange', {isMuted: !this.isMuted})
      }
    },

    async onReactClick() {
      if (!this.currentUser) {
        this.openAuthModal();
        return
      }
      if (this.loading) {
        return
      }
      let response;
      let data = {
        model: "voicememe",
        id: this.data.uuid,
      }
      this.loading = true;
      if (this.reacted) {
        this.totalReactions -= 1;
        response = await this.removeReaction(data)
      } else {
        this.totalReactions += 1;
        response = await this.addReaction({...data, type: "like"})
      }
      this.reacted = !this.reacted;
      if (!response.result) {
        this.error = response.errors.message[0]
      }
      this.loading = false;
    },
    onBoxClick() {
      if (this.$route.name === 'meme.view') {
        window.scrollTo(0, 200)
      }
      this.$emit('onBoxClick')
    },
    async copyShareLink(link) {
      this.shareCounterRequest(this.data.uuid)
      let url = this.$helper.getMemeUrl(link);
      this.updateShareModal({show: true, link: url, uuid: this.data.uuid, meme: this.data});
    },
    async copyLink(link) {
      this.shareCounterRequest(this.data.uuid)
      let url = this.$helper.getMemeUrl(link);
      const copyText = document.createElement('input');
      // this.updateShareModal({show:true, link: url, file: this.data.file.url});
      document.body.appendChild(copyText);
      copyText.setAttribute('value', url)
      copyText.select();
      document.execCommand("copy");
      document.body.removeChild(copyText);
      this.updateInfoPopup({show: true});
    },
    showCollectionsList() {
      if (!this.currentUser) {
        this.openAuthModal();
        return
      }
      this.showCollections = true;
      this.showOverlay();
    },
    hideCollectionsList() {
      this.showCollections = false;
      this.hideOverlay();
    },
    isInViewPort() {
      const rect = this.$refs[`parent_${this.data.uuid}`] ? this.$refs[`parent_${this.data.uuid}`].getBoundingClientRect() : null;
      if (rect) {
        // checking for partial visibility
        this.isVisible = rect.top < window.innerHeight + 350 && rect.bottom >= 0;
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .gif-container {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .gif {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
      border: solid 1px #d8d8d8;
    }

    .meme-gif {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .meme-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }

    .top-settings {
      .volume {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0.3rem 0.7rem;
        //width: 25px;
      }

      .menu {
        width: 25px;
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 0.3rem;

        img {
          width: 100%;
        }
      }

      .title {
        font-family: "Open Sans", sans-serif;
        font-size: 26px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
      }

      .menu-icon {
        position: relative;
      }

      img {
        cursor: pointer;
      }

      svg {
        cursor: pointer;
        color: #ffffff;
        font-size: 2rem;
      }


      /deep/ .dropdown-menu.show {
        transform: translate3d(0px, 64px, 0px) !important;
        top: -3em !important;
        left: -9em !important;
        will-change: transform !important;
        margin: 1em;
        padding: 1em;
        border-radius: 12px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;

        .dropdown-item,
        .dropdown-header {
          text-align: center;
          font-family: "Open Sans", sans-serif;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.5;
          letter-spacing: normal;
          color: #1a1a1a;
          padding: 0;
        }
      }
    }

    .tag {
      position: absolute;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
    }

    .bottom-settings {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      color: #ffffff;
      width: 100%;
      padding: 0 0.5rem 0.5rem 0.5rem;

      .total {
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        margin-left: 5px;
      }

      .cameleon-icon {
        width: 40px;
        height: auto;
      }

    }
  }

  @media only screen and (max-width: 1366px) {
    .gif-container {
      .top-settings,
      .bottom-settings {
        img {
          width: 30px;
        }

        .cameleon-icon {
          width: 30px;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .custom-container {
      max-width: 800px
    }
  }

  @media only screen and (max-width: 768px) {
    .gif-container {
      .top-settings,
      .bottom-settings {
        img {
          width: 15px;
        }

        .cameleon-icon {
          width: 15px;
        }

        .total {
          font-size: 11px;
        }
      }

      .tag {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .gif-container {
      .top-settings,
      .bottom-settings {
        img {
          width: 40px;
        }

        .cameleon-icon {
          width: 40px;
        }

        .total {
          font-size: 13px;
        }
      }

      .tag {
        font-size: 8px;
      }
    }
  }

  @media only screen and (max-width: 428px) {
    .gif-container {
      .top-settings,
      .bottom-settings {
        img,
        .cameleon-icon {
          width: 30px;
        }

        .total {
          font-size: 12px;
        }
      }
    }
  }

  .hover-depended-icon {
    opacity: 0;
    transition: all 0.3s;

    &.hover-depended-icon-visible {
      opacity: 1;
    }
  }

</style>
