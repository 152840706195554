<template>
  <div>
    <div class="tab-content">
      <trending :key="username" :is-me="isMe">
        <template slot="new-btn">
          <router-link to="/create" class="create-new-gif-btn grid-item">
            <div class="collection  btn-hover">
              <div class="plus-btn" :style="{background:profileColor}">
                +
              </div>
              <span>Create new <br>GIF with Sound</span>
            </div>
          </router-link>
        </template>
      </trending>
    </div>
  </div>

</template>

<script>
  import {mapActions, mapState} from "vuex";
  import Trending from "../widgets/Trending";

  export default {
    name: "GalleryTab",
    components: {Trending},
    computed: {
      ...mapState({
        currentUser: state => state.currentUser,
        username: state => state.filter.username,
        profileColor: state => state.profileColor,
      }),
    },
    data() {
      return {
        isMe: false,
        gallery: [],
        error: ''
      }
    },
    metaInfo() {
      return {
        title: `@${this.$route.params.id.toUpperCase()} Gallery`,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `Find ${this.$route.params.id.toUpperCase()} GIFs on KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`,
          },
        ],
      };
    },
    methods: {
      ...mapActions(['getTrends', 'updateFilter', 'updateFilterError']),
      init() {
        this.updateFilter({username: this.$route.params.id})
        if (this.currentUser) {
          this.isMe = this.$route.params.id === this.currentUser.username
        }
      }
    },
    watch: {
      currentUser() {
        this.init();
      },
      $route() {
        this.init();
      },
    },
    beforeMount() {
      this.init();
    },
    beforeDestroy() {
      this.updateFilterError('')
    }
  }
</script>

<style lang="scss" scoped>

  /deep/ .grid-view {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-item:first-child{
    grid-row: span 2;
  }


  .create-new-gif-btn {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    min-width: 33.3%;

    .plus-btn {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      color: #ffffff;
      font-family: "Open Sans", sans-serif;
      font-size: 41px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.12;
      letter-spacing: normal;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 576px) {
    /deep/ .grid-view {
      grid-template-rows: 200px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (max-width: 428px) {
    .create-new-gif-btn {
      display: none;
    }
  }

</style>