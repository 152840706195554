import Vue from 'vue';
import {extend} from 'vee-validate';
import {ValidationProvider} from 'vee-validate';
import {required,email} from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('email', {
  ...email,
});
