<template>
  <div class="youtube-url-modal">
    <div class="close-btn" @click="showYoutubeUrlModal(false)">
      <b-icon icon="x" class="close-btn-icon"></b-icon>
    </div>
    <div class="youtube-url">
      <div v-if="error" class="error-text">{{ error }}</div>
      <div class="paste-url">
        <input type="text" placeholder="Enter custom Url" ref="videoUrl" v-model="url">
        <img src="../../../src/assets/icons/go_btn.svg" alt="go" class="go-btn" @click.stop="onPasteUrl()"/>
      </div>
      <button class="paste-from-clipboard" @click="pasteFromClipboard()">Paste from clipboard</button>
      <b-spinner  v-if="loading" class="mt-2" style="width: 1rem; height: 1rem"></b-spinner>

    </div>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";

  export default {
    name: "YoutubeUrlModal",
    methods: {
      ...mapActions(['showYoutubeUrlModal']),
      async onPasteUrl() {

        if (this.loading) {
          return
        }
        this.loading = true;
        if (!this.youtube_parser(this.url)) {
          this.loading = false;
          this.error = "Please enter valid URL"
        } else {
          this.error = ''
          setTimeout(() => {
            this.showCreateOptionsModal = false;
            this.$router.path = '/';
            this.$router.push({name: 'preview', params: {link: this.url}});
            this.url = ''
            this.loading = false;
            this.showYoutubeUrlModal(false);
            this.$emit('onCloseYoutubeModal')
          }, 1000);
        }
      },
      youtube_parser(url) {
        let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
      },
      pasteFromClipboard() {
        navigator.clipboard.readText()
          .then(text => {
            this.url = text;
          })
          .catch(err => {
            this.error = err;
          });
      }
    },
    data() {
      return {
        url: '',
        loading: false,
        error: ''
      }
    }, computed: {
      ...mapState(['youtubeUrlModal'])
    },
  }

</script>

<style lang="scss" scoped>
  .youtube-url-modal {
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: -1;

    .youtube-url {
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: 500;
      padding: 40px 30px 100px 40px;
      border-radius: 26px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.16);
      background-color: #fafafa;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .paste-url {
        height: 63px;
        position: relative;
        width: 100%;
      }

      .error-text {
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 38px

      }

      .go-btn {
        position: absolute;
        top: -25px;
        right: -30px;
      }

      .paste-from-clipboard {
        height: 63px;
        border-radius: 32px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.5);
        background-color: #1a1a1a;
        margin-top: 18px;
        width: 100%;
        color: #f0f0f0;
      }
    }

    .close-btn {
      align-self: flex-end;
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 50%;
      margin-bottom: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      .close-btn-icon {
        width: 25px;
        height: 25px;
      }
    }
  }

</style>