import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    // Unauthenticated user redirect to main page
    if (to.matched.some(m => m.meta.auth === true) && !router.app.$store.state.currentUser) {
        return router.push({name: 'main'});
    }
    return next();
});

export default router;