import { render, staticRenderFns } from "./ExtensionModal.vue?vue&type=template&id=0882ef3c&scoped=true&"
import script from "./ExtensionModal.vue?vue&type=script&lang=js&"
export * from "./ExtensionModal.vue?vue&type=script&lang=js&"
import style0 from "./ExtensionModal.vue?vue&type=style&index=0&id=0882ef3c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0882ef3c",
  null
  
)

export default component.exports