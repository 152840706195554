<template>
  <div class="responsive-header">
    <div class="d-flex justify-content-between align-items-center">
      <b-icon @click="goBack()" icon="chevron-left" class="go-back-btn"></b-icon>
      <section-title :main="title" size="md" responsive class="title"/>
      <div class="right-button" style="min-width: 33px">
        <slot name="right-button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import SectionTitle from "../widgets/SectionTitle";
  import {mapActions} from "vuex";

  export default {
    name: "ResponsiveHeader",
    props: {
      title: {
        type: String
      }
    },
    components: {SectionTitle},
    methods: {
      ...mapActions(['hideOverlay', 'disableScroll']),
      goBack() {
        this.disableScroll(false)
        this.hideOverlay()
        this.$emit('backClick')
      },
    }
  }
</script>

<style lang="scss" scoped>
  .responsive-header {
    display: none;
  }

  @media only screen and (max-width: 428px) {
    .responsive-header {
      display: block;
      position: fixed;
      width: 100%;
      margin: 0 -10px;
      background: #ffffff;
      top: 0;
      padding: 10px 15px 10px 10px ;
      z-index: 4;
      border-bottom: 1px solid #f0f0f0;
    }
    .go-back-btn {
      display: inline-block;
      float: left;
    }

    .right-button {
      display: inline-block;
      float: right;
    }
    .info-text {
      border-bottom: none;
    }
  }
</style>