<template>
  <div class="search-page for-mobile">
    <section-title main="Search" size="md" responsive/>
    <div class="position-relative search-res">
      <form @submit.prevent="filterTrends()">
        <input type="text" placeholder="Search by" name="search" v-model="searchText" autocomplete="off">
        <button type="submit" class="position-absolute search-button">
          <img class="search-icon" src="../../../src/assets/icons/search.svg" alt="search"/>
        </button>
      </form>
    </div>
    <div class="history-container">
      <transition-group name="fade">
        <div class="history-text" v-for="(history, index) in searchHistory" :key="`search-history_${index}`"
             @click="onSearchHistoryClick(history)">
          <img src="../../assets/images/search_history.svg" alt="search history"/>{{ history }}
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
  import SectionTitle from "../../../src/components/widgets/SectionTitle";
  import {mapActions} from "vuex";

  export default {
    name: "Search",
    components: {SectionTitle},
    data() {
      return {
        searchText: '',
        searchHistory: []
      }
    },
    methods: {
      ...mapActions(['updateFilter', 'updateHideHeader']),
      async filterTrends() {
        this.$emit('onSearchClick')
        if (this.searchText) {
          if (this.searchHistory.length > 13) {
            this.searchHistory = this.searchHistory.filter((s, i) => i < 12)
          }
          this.searchHistory.unshift(this.searchText)
          this.searchHistory = new Set(this.searchHistory)
          localStorage.setItem("searchHistory", JSON.stringify([...this.searchHistory]));
          this.$router.replace({name: 'query.view', params: {query: this.searchText}});
        } else {
          this.updateFilter({searchText: ''})
          if (this.$route.path !== '/') {
            this.$router.path = '/';
            this.$router.push({name: 'main'});
        }
        }
      },
      onSearchHistoryClick(history) {
        this.searchText = history
      }
    },
    mounted() {
      this.updateHideHeader(true)
      this.searchHistory = localStorage.getItem("searchHistory");
      this.searchHistory = (this.searchHistory) ? JSON.parse(this.searchHistory) : [];
    },
  }
</script>

<style lang="scss" scoped>

  .search-page {
    background: #ffffff;
    z-index: 1;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    margin-left: -10px;
    padding: 22px;

    .history-container {
      padding: 40px 20px;

      .history-text {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #707070;
        margin-bottom: 30px;

        img {
          margin-right: 20px;
        }
      }
    }


    .search-res {
      width: 100%;
      margin-top: 21px;

      input {
        height: 55px;
      }

      .search-button {
        background: #ffffff;
        right: 18px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
</style>