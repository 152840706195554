<template>
  <div>
    <SectionTitle main="Account" secondary="settings" class="main-title"/>
    <responsive-header title="Account Settings" @backClick="$router.go(-1)"/>
    <div class="account-settings-container pb-5">
      <div class="row account-settings">
        <div class="col-lg-6 col-sm-12 settings-cont">
          <div class="user-img-container d-flex flex-column align-items-center">
            <div class="user-img d-flex align-items-center justify-content-center">
              <img v-if="avatarUrl" :src="avatarUrl" alt="user">
              <img v-else-if="currentUser && currentUser.avatar_url" :src="currentUser.avatar_url" alt="user">
              <img v-else src="../assets/icons/user_dark.svg" alt="user">
            </div>
            <label class="error-msg" v-for="err in imgErrors" :key="err">{{ err }}</label>
            <div class="upload-btn-wrapper upload-btn btn-hover">
              <span v-if="currentUser && currentUser.avatar_url">
                Change image
              </span>
              <span v-else>
                Upload from gallery
              </span>
              <input type="file" name="avatar" @change="onFileChange"
                     accept="'image/jpeg', 'image/jpg', 'image/png','image/gif'"/>
            </div>
            <div v-if="currentUser && currentUser.avatar_url" class="upload-btn btn-hover"
                 @click="onDeleteImageClick()">
              Delete image
            </div>
            <div v-else class="upload-btn btn-hover">Random</div>
          </div>
        </div>
        <b-form @submit.prevent="onSaveProfileClick" class="col-lg-6 col-sm-12 settings-cont">
          <div class="user-settings-form d-flex flex-column">
            <div class="mb-4 mt-4">
              <SectionTitle main="Personal" secondary="Info" size="md"/>
            </div>
            <div class="input-widget">
              <validation-provider rules="" v-slot="{ errors }">
                <input type="text" v-model="username" placeholder="@Username" name="username"/>
                <span class="error-msg">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="input-widget">
              <input type="text" v-model="displayName" placeholder="Display name" name="displayName"/>
            </div>
            <div class="input-widget">
              <validation-provider rules="email" v-slot="{ errors }">
                <input type="text" v-model="email" placeholder="Email address" name="email"/>
                <span class="error-msg">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="mb-4 mt-4">
              <SectionTitle main="Channel" secondary="Settings" size="md"/>
            </div>
            <div class="input-widget">
              <textarea placeholder="Bio" v-model="bio" rows="3"/>
            </div>
            <div class="input-widget social">
              <input type="text" placeholder="Website" v-model="website"/>
            </div>
            <div class="input-widget social">
              <input type="text" placeholder="Facebook profile" v-model="facebook_url"/>
              <img src="../assets/icons/facebook_colored.svg" class="social-icon" alt="facebook"/>
            </div>
            <div class="input-widget social">
              <input type="text" placeholder="Instagram profile" v-model="instagram_url"/>
              <img src="../assets/images/instagram.svg" class="social-icon" alt="instagram"/>
            </div>
            <div class="input-widget social">
              <input type="text" placeholder="Tiktok profile" v-model="tiktok_url"/>
              <img src="../assets/images/tiktok.svg" class="social-icon" alt="tiktok"/>
            </div>
<!--            <div class="plus d-flex justify-content-center align-items-center">+</div>-->
            <div class="mb-4 mt-4">
              <SectionTitle main="Privacy" secondary="Settings" size="md"/>
            </div>
            <div class="input-widget">
              <input type="password" v-model="password" placeholder="Password" name="password"/>
            </div>
            <div class="input-widget">
              <input type="password" v-model="confirmPassword" placeholder="Repeat password"/>
            </div>
            <span class="error-msg mb-3" v-if="!passwordsMatch">Passwords do not match</span>
            <b-button class="submit-btn btn-hover mr-3" type="submit" :disabled="isLoading">
              <b-spinner v-if="isLoading"></b-spinner>
              <span v-else>SAVE</span>
            </b-button>
          </div>
        </b-form>
        <div class="submit-btn-res" @click="onSaveProfileClick()">
          <b-button class="submit-btn1" :disabled="isLoading">
            <b-spinner v-if="isLoading"></b-spinner>
            <span v-else>SAVE</span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SectionTitle from "./widgets/SectionTitle";
  import {mapActions, mapState} from "vuex";
  import ResponsiveHeader from "./MobileDeviceComponents/ResponsiveHeader";

  export default {
    name: "AccountSettings",
    metaInfo() {
      return {
        title: 'Account Settings',
      };
    },
    components: {ResponsiveHeader, SectionTitle},
    data() {
      return {
        channel_public: true,
        username: '',
        displayName: '',
        email: '',
        bio: '',
        website: '',
        password: '',
        confirmPassword: '',
        serverError: '',
        avatar: {
          uuid: null,
          url: null,
        },
        avatarUrl: null,
        imgErrors: null,
        avatarDeleted: false,
        facebook_url: null,
        instagram_url: null,
        tiktok_url: null,
      }
    },
    computed: {
      ...mapState(['currentUser', 'isLoading']),
      passwordsMatch() {
        return this.password === this.confirmPassword
      }
    },
    watch: {
      currentUser() {
        if (this.currentUser) {
          this.fillUserData(this.currentUser);
        }
      },
      'avatar.uuid': {
        deep: true,
        handler() {
          this.avatarUrl = this.avatar.url
        }
      }
    },
    mounted() {
      if (this.currentUser) {
        this.fillUserData(this.currentUser);
      }
      this.updateHideHeader(true)
      this.showMenu(false)
    },
    beforeDestroy() {
      this.updateHideHeader(false)
      this.showMenu(true)
    },
    methods: {
      ...mapActions(['updateProfile', 'getFileUuid', 'updateHideHeader', 'showMenu', 'updateInfoPopup', 'showOverlay', 'updateInfoModal']),
      fillUserData(user) {
        this.username = user.username;
        this.displayName = user.display_name;
        this.email = user.email;
        this.bio = user.bio;
        this.website = user.website_url;
        this.instagram_url = user.instagram_url;
        this.facebook_url = user.facebook_url;
        this.tiktok_url = user.tiktok_url;
      },
      async onSaveProfileClick() {
        this.serverError = '';
        let data = {
          username: this.username,
          email: this.email,
          display_name: this.displayName,
          website_url: this.website,
          bio: this.bio,
          instagram_url: this.instagram_url,
          facebook_url: this.facebook_url,
          tiktok_url: this.tiktok_url,
          // behance_url: "http://google4.com",
        }
        if (this.password && this.passwordsMatch) {
          data.password = this.password;
          data.password_confirmation = this.confirmPassword
        }

        if (!this.passwordsMatch) {
          return
        }
        let avatar;
        if (this.avatarDeleted) {
          avatar = {avatar_id: ''}
        } else {
          avatar = this.avatar.uuid ? {avatar_id: this.avatar.uuid} : {}
        }

        let response = await this.updateProfile({...data, ...avatar})
        if (!response.data.result) {
          let fields = [
            'username',
            'email',
            'display_name',
            'website_url',
            'bio',
            'avatar_id',
            'instagram_url',
            'facebook_url',
            'tiktok_url',
            'behance_url',
            'message'
          ]
          fields.map(field => {
            if (response.errors[field]) {
              this.serverError = response.errors[field][0]
              this.updateInfoModal({show: true, text: this.serverError})
            }
          })
        } else {
          this.updateInfoPopup({show: true, text: 'Profile updated successfully'})
        }
      },
      async onFileChange(e) {
        this.imgErrors = null
        let files = e.target.files || e.dataTransfer.files;

        let formData = new FormData();
        formData.append("file", files[0]);
        formData.append("type", "avatar");
        let response = await this.getFileUuid(formData)
        if (response.result) {
          this.avatar.uuid = response.data.uuid;
          this.avatar.url = response.data.url;
        } else {
          if (response.errors.file) {
            this.imgErrors = response.errors.file
          }
        }
      },
      onDeleteImageClick() {
        this.avatarUrl = require('../assets/icons/user_dark.svg');
        this.avatar.uuid = null;
        this.avatar.url = null;
        this.avatarDeleted = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/style";

  .account-settings {
    padding-top: 5rem;
    padding-bottom: 5rem;
    border-radius: 12px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);

    .submit-btn-res {
      display: none;
    }

    .user-settings-form {
      width: 90%;

      .social {
        position: relative;

        /deep/ input {
          padding-right: 55px;
        }

        .social-icon {
          position: absolute;
          right: 0;
          width: 20px;
          top: 50%;
          transform: translate(-50%, -50%)
        }
      }
    }

    .input-widget {
      font-size: 14px;
    }


    .user-img-container {

      .user-img {
        width: 200px;
        border: solid 5px #1a1a1a;
        border-radius: 50%;
        height: 200px;
        margin-bottom: 40px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .upload-btn {
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.17;
        text-align: left;
        width: 33.3%;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 50px;
        margin-bottom: 30px;
        border-radius: 32px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
        cursor: pointer;
      }

      .upload-btn-wrapper {
        position: relative;
        overflow: hidden;

        input[type=file] {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          height: 100%;
          cursor: pointer;
        }
      }
    }

    .submit-btn {
      width: 180px;
      height: 40px;
      border-radius: 32px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
      background-color: #1a1a1a;
      color: #ffffff;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
    }

    .plus {
      font-family: "Open Sans", sans-serif;
      font-size: 30px;
      font-weight: 1000;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #1a1a1a;
      width: 60px;
      height: 60px;
      border-radius: 32px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.04);
      background-color: #fff;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 1366px) {
    .account-settings {
      .user-img-container {
        .button {
          width: 50%;
        }
      }
    }
  }

  @media only screen and (max-width: 428px) {
    .main-title {
      display: none;
    }

    .account-settings {
      padding-top: 10px;
      margin-right: 0;
      margin-left: 0;
      padding-bottom: 0;
      box-shadow: none;

      .submit-btn {
        display: none;
      }

      .submit-btn-res {
        position: fixed;
        display: block;
        bottom: 0;
        background: #ffffff;
        width: 100%;
        height: 122px;
        margin-left: -10px;
        padding: 15px 25px 40px 25px;

        .submit-btn1 {
          width: 100%;
          height: 65px;
          padding: 20.5px 168px 22.5px 170px;
          border-radius: 32px;
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
          background-color: #1a1a1a;
          font-family: "Open Sans", sans-serif;
          font-weight: bold;
        }
      }

      .input-widget {
        margin-bottom: 7px;
      }

      .settings-cont {
        padding-left: 25px;
        padding-right: 25px;
      }

      .user-settings-form {
        width: 100%;
      }

      .user-img-container {
        margin-top: 60px;

        .user-img {
          width: 100px;
          height: 100px;
        }

        .upload-btn {
          width: 100%;
        }
      }
    }
  }
</style>