<template>
  <div>
    <b-modal v-model="collectionModal.show" hide-footer hide-header @show="clearData">
      <div class="collection-modal">
        <div class="modal-content">
          <div class="collection-form">
            <b-icon icon="x" class="close-btn" @click="hideCollectionModal()"></b-icon>
            <div class="modal-title">
              <section-title main="title" secondary="and privacy" size="md"/>
            </div>
            <b-form @submit.prevent="onSubmit()" class="login-form">
              <div class="input-widget">
                <validation-provider rules="required" v-slot="{ errors }">
                  <input type="text" v-model="title" placeholder="Title" name="title" autocomplete="off"/>
                  <span class="error-msg" v-if="!titleErrorMsg">{{ errors[0] }}</span>
                  <span class="error-msg">{{ titleErrorMsg }}</span>
                </validation-provider>
              </div>
              <div class="input-widget">
                <b-form-select v-model="selectedStatus" :options="collection_statuses"></b-form-select>
              </div>
              <button class="create-btn btn-hover " type="submit">
                <span v-if="collectionModal.object && collectionModal.object.uuid">SAVE</span>
                <span v-else>CREATE COLLECTION</span>
              </button>
            </b-form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import SectionTitle from "./widgets/SectionTitle";

  export default {
    name: "CollectionModal",
    components: {SectionTitle},
    data() {
      return {
        title: '',
        description: '',
        titleErrorMsg: '',
        selectedStatus: 1,
      }
    },
    computed: {
      ...mapState({
        collectionModal: state => state.collectionModal,
        editObject: state => state.collectionModal.object,
        collection_statuses: state => state.collection_statuses,
      }),
    },
    watch: {
      editObject() {
        if (this.editObject && this.editObject.uuid) {
          this.title = this.collectionModal.object.title;
          this.description = this.collectionModal.object.description;
        }
      }
    },
    methods: {
      ...mapActions(['hideAuthModal', 'createCollection', 'hideCollectionModal', 'updateCollection', 'getCollections', 'updateInfoPopup']),
      clearData() {
        this.title = '';
        this.description = '';
        this.titleErrorMsg = '';
      },
      async onSubmit() {
        let data = {
          title: this.title,
          description: this.description,
          status: this.selectedStatus
        }
        let response;
        let infoMsg = ''
        if (this.editObject && this.editObject.uuid) {
          response = await this.updateCollection({...data, uuid: this.editObject.uuid});
          infoMsg = 'Updated'
        } else {
          response = await this.createCollection(data);
          infoMsg = 'Collection Created'
        }
        if (response.result) {
          this.updateInfoPopup({show: true, text: infoMsg})
          this.hideCollectionModal()
        } else {
          if (response.errors.title) {
            this.titleErrorMsg = response.errors.title[0]
          }
        }
      }
    }

  }
</script>

<style lang="scss" scoped>


  /deep/ .modal-dialog {
    max-width: 360px;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%) !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;

    .modal-content {
      border-radius: 12px;
    }
  }

  .collection-modal {
    .modal-content {
      border: none;

      .close-btn {
        width: 32px;
        height: 32px;
        float: right;
        cursor: pointer;
      }

      .modal-title {
        clear: both;

        /deep/ .section-title {
          margin-bottom: 25px;
        }
      }

      .collection-form {
        padding: 5px 11px;

        /deep/ .input-widget {
          margin-bottom: 16px;
        }

        input {
          width: 100%;
        }

        .create-btn {
          width: 100%;
          height: 50px;
          margin-top: 15px;
          border-radius: 32px;
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
          background-color: #1a1a1a;
          cursor: pointer;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (max-width: 428px) {
    /deep/ .modal-dialog {
      width: 100%;
      top: 100%;
      transform: translate(-50%, -100%) !important;
      margin: 0 !important;

      .modal-content {
        border-radius: 12px 12px 0 0;
      }
    }
  }

</style>