import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router';
import FileHelper from './helpers/File';
import Helper from "./helpers/Helper";
import './axios'
import "./plugins";
import VueYoutube from 'vue-youtube'
import VueMeta from 'vue-meta'
import VueJsonLD from 'vue-jsonld'

Vue.config.productionTip = false
Vue.prototype.$getAndroidAppUrl = "https://kikliko.com/getapp"
Vue.prototype.$appPlayStoreUrl = "market://details?id=com.kikliko"
Vue.prototype.$getIosAppUrl = ""

require('../src/store/subscriber')

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueYoutube)
Vue.use(FileHelper)
Vue.use(Helper)
Vue.use(VueMeta)
Vue.use(VueJsonLD)
export const EventBus = new Vue();


store.dispatch('attempt', localStorage.getItem('token')).finally(() => {
  new Vue({
    render: h => h(App),
    router,
    store
  }).$mount('#app')
})
