import Landing from '../components/Landing.vue';
import AccountSettings from "../components/AccountSettings";
import Profile from "../components/Profile";
import CreateMeme from "../components/CreateMeme";
import GifView from "../components/GifView";
import Extensions from "../components/Extensions";
import ApiReference from "../components/ApiReference";
import LikedGifsTab from "../components/views/LikedGifsTab";
import GalleryTab from "../components/views/GalleryTab";
import CollectionsTab from "../components/views/CollectionsTab";
import CollectionInnerView from "../components/CollectionInnerView";
import Preview from "../components/Preview";
import Documentation from "../components/Documentation";
import About from "../components/views/About";
import Terms from "../components/views/Terms";
import Privacy from "../components/views/Privacy";
import Dmca from "../components/views/Dmca";
import Settings from "../components/MobileDeviceComponents/Settings";
import CommunityGuidelines from "../components/views/CommunityGuidelines";
import TagLanding from "../components/TagLanding";
import QueryLanding from "../components/QueryLanding";
import Error404 from "../components/views/Error404";

export default [
  {
    path: '/',
    name: 'main',
    component: Landing,
  },
  {
    path: '/tag/:tag',
    name: 'tag.view',
    component: TagLanding,
  },
  {
    path: '/q/:query',
    name: 'query.view',
    component: QueryLanding,
  },
  {
    path: '/account',
    component: AccountSettings,
    name: 'account.settings',
    meta: {'auth': true},
  },
  {
    path: '/user/:id',
    component: Profile,
    name: 'user',
    redirect: {name: 'profile.gallery'},
    children: [
      {
        path: 'collection',
        name: 'profile.collection',
        component: CollectionsTab,
      },
      {
        path: 'collection/:collectionId',
        name: 'collection.inner',
        component: CollectionInnerView,
        props: true,
      },
      {
        path: 'liked-gifs',
        name: 'profile.liked-gifs',
        component: LikedGifsTab,
      },
      {
        path: 'gallery',
        name: 'profile.gallery',
        component: GalleryTab,
        props: true,
      },
    ],
  },
  {
    path: '/create',
    name: 'create',
    component: CreateMeme,
    meta: {'auth': true},
  },
  {
    path: '/preview',
    name: 'preview',
    component: Preview,
    meta: {'auth': true},
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component: Preview,
    meta: {'auth': true},
  },
  {
    path: '/extensions',
    component: Extensions,
  },
  {
    path: '/api',
    component: ApiReference,
    name: 'api',
  },
  {
    path: '/documentation',
    component: Documentation,
    name: 'documentation',
    redirect: {name: 'documentation.about'},
    children: [
      {
        path: 'about',
        name: 'documentation.about',
        component: About,
      },
      {
        path: 'terms',
        name: 'documentation.terms',
        component: Terms,
      },
      {
        path: 'privacy',
        name: 'documentation.privacy',
        component: Privacy,
      },
      {
        path: 'dmca',
        name: 'documentation.dmca',
        component: Dmca,
      },
      {
        path: 'community-guidelines',
        name: 'documentation.community_guidelines',
        component: CommunityGuidelines,
      },
    ],
  },
  {
    path: '/settings/',
    name: 'settings',
    component: Settings,
    meta: {'auth': true},
  },
  {
    path: '/error/404/',
    name: 'error.404',
    component: Error404,
  },
  {
    path: '/getapp',
    redirect: {name: 'main'},
  },
  {
    path: '/apireference',
    component: ApiReference,
    name: 'old.api',
  },
  {
    path: '/:id',
    name: 'meme.view',
    component: GifView,
    props: true,
  },
  {
    path: '/+:tag',
    name: 'old.tag.view',
    component: TagLanding,
  },
  {
    path: '/@:id',
    component: Profile,
    name: 'old.user',
    redirect: {name: 'old.profile.gallery'},
    children: [
      {
        path: 'collection',
        name: 'old.profile.collection',
        component: CollectionsTab,
      },
      {
        path: 'collection/:collectionId',
        name: 'old.collection.inner',
        component: CollectionInnerView,
        props: true,
      },
      {
        path: 'liked-gifs',
        name: 'old.profile.liked-gifs',
        component: LikedGifsTab,
      },
      {
        path: 'gallery',
        name: 'old.profile.gallery',
        component: GalleryTab,
        props: true,
      },
    ],
  },
  {
    path: '/view/:id',
    name: 'old.meme.view',
    component: GifView,
    props: true,
  },
  { path: '*', beforeEnter: (to, from, next) => { next({name: 'error.404'}) } },
];