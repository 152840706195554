<template>
  <div class="create-meme">
    <section-title secondary="new meme" main="create"/>
    <div class="row mb-5">
      <div class="col-md-6 col-sm-6 upload-btn-wrapper">
        <div class="card-container">
          <div class="upload-card">
            <img class="mb-5" src="../assets/icons/gallery.svg" alt="gallery">
            <span>Upload from gallery</span>
          </div>
        </div>
        <input type="file" name="file" @change="onFileChange"/>
      </div>
      <div class="col-md-6 col-sm-6">
        <div class="card-container">
          <div class="upload-card">
            <img class="mb-3" src="../assets/icons/youtube.svg" alt="Youtube">
            <input type="text" placeholder="Paste URL" ref="videoUrl" v-model="url">
            <label v-if="error" class="error-msg mt-2">{{ error }}</label>
            <b-spinner v-if="loading" class="mt-2"></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import SectionTitle from "./widgets/SectionTitle";

  export default {
    name: "CreateMeme",
    metaInfo() {
      return {
        title: 'Create GIF',
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `Create GIF on KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`
          },
        ],
      };
    },
    components: {SectionTitle},
    data() {
      return {
        file: null,
        error: '',
        url: '',
        loading: false,
      }
    },
    mounted() {
      this.$nextTick(function () {
        this.$refs.videoUrl.addEventListener('paste', this.onPasteUrl);
      })
    },
    beforeDestroy() {
      this.$refs.videoUrl.removeEventListener('paste', this.onPasteUrl)
    },

    methods: {
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.$router.path = '/';
        this.$router.push({name: 'preview', params: {files: files}});
      },
      youtube_parser(url) {
        let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
      },
      async onPasteUrl(event) {
        this.loading = true;
        let paste = (event.clipboardData || window.clipboardData).getData('text');
        this.url = paste;
        if (!this.youtube_parser(paste)) {
          this.loading = false;
          this.error = "Check the video URL!"
        } else {
          this.error = ''
          event.preventDefault();
          setTimeout(() => {
            this.$router.path = '/';
            this.$router.push({name: 'preview', params: {link: paste}});
          }, 1000);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../style';

  .create-meme {

    .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
    }

    .upload-btn-wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      height: 100%;
      cursor: pointer;
    }

    .card-container {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 12px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
      border: solid 1px #d8d8d8;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .upload-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        img {
          width: 150px;
        }

        span {
          font-family: "Open Sans", sans-serif;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          color: #1a1a1a;
        }

        input {
          box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
          width: 325px;
          padding: 15px;
          border-radius: 32px;
          border: solid 1px #d8d8d8;
          background-color: #fff;
          text-align: center;
        }
      }
    }

  }
</style>