<template>
  <div>
<!--    <div class="d-flex justify-content-center">-->
<!--      <img src="../../assets/icons/no_comments.png" alt="no_comment"/>-->
<!--    </div>-->
    <div class="d-flex justify-content-center">
      <div class="d-flex justify-content-center">
        <h1 class="float-left display-3 mr-4">404</h1>
        <div>
          <h5 class="pt-3">Oops! You're lost.</h5>
          <p class="text-muted">The page was not found.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  metaInfo() {
    return {
      title: '404 Not Found',
    };
  },
}
</script>

<style lang="scss" scoped>
</style>
