<template>
  <div :class="scrollDisabled? 'stop-scrolling' : '' ">
    <div id="app" class="custom-container custom-container-padding">
      <Header/>
      <router-view></router-view>
      <default-layout/>
    </div>
    <div class="overlay" v-if="showOverlay">
    </div>
    <div class="responsive-sticky open-app-sticky for-mobile-flex" v-if="openAppSticky">
      Use app for full experience
      <button :style="{background: profileColor}" @click="onOpenApp">Open</button>
    </div>
    <open-app :show="openAppModal && !openAppSticky" @openApp="onOpenApp" @notNow="onNotNow" v-if="isMobileDevice"/>
    <cookies v-if="!cookiesAccepted && showCookiesAgreement" @onAcceptCookies="onAcceptCookies()"/>
  </div>
</template>
<script>

  import DefaultLayout from "./components/DefaultLayout";
  import Header from "./components/widgets/Header";
  import {mapActions, mapState} from "vuex";
  import OpenApp from "./components/MobileDeviceComponents/OpenApp";
  import Cookies from "./components/widgets/Cookies";

  export default {
    name: 'App',
    metaInfo: {
      title: 'KikLiko - Clips & Memes',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`,
        },
      ],
      titleTemplate: '%s | KikLiko - Clips & Memes'
    },
    components: {Cookies, OpenApp, DefaultLayout, Header},
    data() {
      return {
        polling: null,
        openAppModal: false,
        mobileOperatingSystem: '',
        cookiesAccepted: '',
        showCookiesAgreement: false,
        loginError: ''
      }
    },
    watch: {
      currentUser: {
        handler() {
          if (this.currentUser) {
            this.updateNotifications()
            this.getCollections(this.currentUser.username)
          } else {
            clearInterval(this.polling)
          }
        },
        immediate: true
      },
    },
    computed: {
      ...mapState(['showOverlay', 'hideHeader', 'currentUser', 'profileColor', 'scrollDisabled',
        'isMobileDevice', 'extensionModal', 'openAppSticky'])
    },
    methods: {
      ...mapActions(['getNotificationsTotal', 'getCollections', 'setProfileColor', 'updateIsMobileDevice',
        'updateExtensionModal', 'updateOpenAppSticky', 'hideOverlay', 'login', 'hideAuthModal']),
      updateNotifications() {
        this.polling = setInterval(() => {
          this.getNotificationsTotal()
        }, 10000);
      },
      checkDevice() {
        this.updateIsMobileDevice(window.innerWidth <= 428)
      },
      showExtensionsModal() {
        if (!this.isMobileDevice) {
          let lastClickToNotNow = localStorage.getItem('notNowClickTime') || 0
          if (parseInt(lastClickToNotNow) + 24 * 60 * 60 * 1000 < new Date().getTime() && this.shouldSuggestChromeExtension()) {
            this.updateExtensionModal({show: true, time: 0})
          }
        }
      },
      shouldSuggestChromeExtension() {
        return navigator.userAgent.indexOf("Chrome") >= 0 && !document.getElementsByTagName('html')[0].getAttribute('chrome-ext-v')
      },
      isPageSpeedBot() {
        return navigator && navigator.userAgent && navigator.userAgent.indexOf("Chrome-Lighthouse") >= 0
      },
      onOpenApp() {
        this.updateOpenAppSticky(true)
        this.openAppModal = false;
        if (this.mobileOperatingSystem === 'android') {
          setTimeout(() => {
            window.location = this.$appPlayStoreUrl;
          }, 25)
          window.location = this.$getAndroidAppUrl;
        } else if (this.mobileOperatingSystem === 'ios') {
          return
          //window.location = this.$getIosAppUrl;
        }
      },
      onNotNow() {
        this.updateOpenAppSticky(true)
        this.hideOverlay()
      },
      getMobileOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor;

        this.mobileOperatingSystem = 'unknown'
        if (/android/i.test(userAgent)) {
          this.mobileOperatingSystem = "android";
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          this.mobileOperatingSystem = "ios";
        }
      },
      onAcceptCookies() {
        this.showCookiesAgreement = false
      },
      showGoogleAuth() {
        /* global google */
        google.accounts.id.initialize({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          callback: this.onOneTapSignedIn
        })

        google.accounts.id.prompt(notification => {
          if (notification.isNotDisplayed()) {
            console.log('NotDisplayedReason', notification.getNotDisplayedReason())
          } else if (notification.isSkippedMoment()) {
            console.log('SkippedReason', notification.getSkippedReason)
          } else if (notification.isDismissedMoment()) {
            console.log('DismissedReason', notification.getDismissedReason)
          }
        })
      },
      async onOneTapSignedIn(response) {
        const resp = await this.login({
          provider: 'google',
          token: response.credential,
        })

        if (resp.result) {
          this.loginError = '';
        } else {
          this.loginError = response.errors.message;
        }
        this.hideAuthModal()
      }
    },
    mounted() {
      this.getMobileOperatingSystem();
      if (!localStorage.getItem('profileColor')) {
        localStorage.setItem('profileColor', this.profileColor)
      }
      this.setProfileColor(localStorage.getItem('profileColor'))
      window.addEventListener('resize', this.checkDevice)
      this.checkDevice()
      setTimeout(() => {
        if (!this.$route.query['ignore-cookies'] && !this.isPageSpeedBot()) {
          if (this.isMobileDevice) {
            if (this.mobileOperatingSystem === "android") {
              this.openAppModal = true
            }
          } else {
            this.showExtensionsModal()
          }
        }
      }, 10000)

      setTimeout(() => {
        if (!this.$route.query['ignore-cookies']) {
          this.showCookiesAgreement = true
          this.cookiesAccepted = localStorage.getItem('cookiesAccepted')
        }
      }, 1500)

      let userLogged = localStorage.getItem('token')
      if (!userLogged) {
        setTimeout(() => {
          this.showGoogleAuth()
        }, 3000)
      }
    },
    beforeDestroy() {
      clearInterval(this.polling)
      window.removeEventListener('resize', this.checkDevice)
    }
  }
</script>

<style lang="scss">
  @import 'src/style';


  @font-face {
    font-family: "Exo 2";
    src: local("Exo 2"),
    url(./fonts/Exo2.ttf) format("truetype");
  }

  #app {
    position: relative;
    font-family: "Open Sans", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 150px;
    margin-top: 20px;
  }

  .custom-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
    width: 100%;
  }

  .custom-container-padding{
    padding-right: 15px;
    padding-left: 15px;
  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.7;
    background-color: #2b2a27d4;
    left: 0;
  }


  @media only screen and (max-width: 1600px) {
    .custom-container {
      max-width: 1000px
    }
  }

  @media only screen and (max-width: 1200px) {
    .custom-container {
      max-width: 900px
    }
  }

  @media only screen and (max-width: 992px) {
    .custom-container {
      max-width: 770px;
    }
  }

  @media only screen and (max-width: 768px) {
    .custom-container {
      max-width: 520px
    }
  }

  @media only screen and (max-width: 428px) {
    .custom-container {
      max-width: 540px
    }
    #app {
      margin-top: 65px;
    }
    .custom-container {
      padding-right: 10px;
      padding-left: 10px;
    }
    .stop-scrolling {
      margin-top: 0;
      overflow: hidden;
      height: 100vh;
      margin-bottom: 0;
    }
  }


</style>
