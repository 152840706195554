<template>
  <div :class="{'discover': !ignoreGrid}">
    <label class="error-msg" v-if="filterError">{{ filterError }}</label>
    <div class="grid-view" v-if="data">
      <slot name="new-btn" v-if="!isLoading && isMe"></slot>
      <div v-for="(trend,index) in data" :key="`gif_${index}`" class="grid-item">
        <gif-box :data="trend" :current-user="currentUser" :key="trend.uuid"
                 :is-muted="isMobileDevice? videoPlayingWithSound!==trend.uuid :isMuted"
                 @onVolumeChange="changeVolume"
                 :video-playing-with-sound="videoPlayingWithSound" :index="index"
                 @onBoxClick="onBoxClick($event,trend)"/>
      </div>
    </div>
    <div class="grid-view" v-else>
      <slot name="new-btn" v-if="!isLoading && isMe"></slot>
      <div v-for="(trend,index) in trends.data" :key="`gif_${index}`" class="grid-item">
        <gif-box :data="trend" :current-user="currentUser" :key="trend.uuid"
                 :is-muted="isMobileDevice? videoPlayingWithSound!==trend.uuid :isMuted"
                 @onVolumeChange="changeVolume"
                 :video-playing-with-sound="videoPlayingWithSound" :index="index"
                 @onBoxClick="onBoxClick($event,trend)"/>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-3 mt-3" v-if="isLoading">
      <b-spinner></b-spinner>
    </div>
    <collection-modal/>
  </div>
</template>

<script>
  import GifBox from "./GifBox";
  import {mapActions, mapState} from "vuex";
  import CollectionModal from "../CollectionModal";

  export default {
    name: "Trending",
    components: {CollectionModal, GifBox},
    props: {
      data: {type: Array},
      isMe: {type: Boolean},
      isRelated: {type: Boolean},
      ignoreGrid: {type: Boolean, default: false},
      relatedMemeUuid: {type: String},
    },
    data() {
      return {
        isLoading: false,
        currentPage: 1,
        error: '',
        videoPlayingWithSound: '',
      }
    }, computed: {
      ...mapState({
        trends: state => state.trends,
        currentUser: state => state.currentUser,
        username: state => state.filter.username,
        tag: state => state.filter.tag,
        searchText: state => state.filter.searchText,
        filterError: state => state.filter.error,
        isMuted: state => state.isMuted,
        isMobileDevice: state => state.isMobileDevice,
      }),
    },
    watch: {
      username() {
        this.clearTrends();
        this.updateFilterError('')
      },
    },
    mounted() {
      if (!this.data) {
        window.addEventListener('scroll', this.loadTrendsHandler)
      }
      this.loadTrends();
    },
    beforeDestroy() {
      if (!this.data) {
        window.removeEventListener('scroll', this.loadTrendsHandler);
      }
      this.clearTrends();
    },
    methods: {
      ...mapActions(['getTrends', 'getCollections', 'clearTrends', 'updateFilterError', 'updateFilter', 'getRelatedTrends', 'changeIsMuted']),
      async loadTrends(page, per_page) {
        if (!this.data) {
          if (this.isLoading) {
            return
          }
          this.error = ''
          this.isLoading = true;
          let res;
          if (this.isRelated) {
            res = await this.getRelatedTrends(this.relatedMemeUuid)
          } else {
            res = await this.getTrends({page, per_page, username: this.username, tags: this.tag, q: this.searchText})
          }
          if (res.result) {
            this.isLoading = false;
          } else {
            this.updateFilterError(this.res.errors.message[0])
          }
        }
      },
      loadTrendsHandler() {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + 300 >= document.documentElement.offsetHeight;
        if (!this.trends.has_next) {
          return
        }
        if (bottomOfWindow && this.trends.data.length) {
          this.loadTrends(this.trends.current_page + 1);
        }
      },
      changeVolume(val) {
        if (!this.isMobileDevice) {
          this.changeIsMuted(val.isMuted)
        } else {
          this.videoPlayingWithSound = val.uuid
          if (val.isMuted) {
            this.videoPlayingWithSound = ''
          }
        }
      },
      onBoxClick(evt, trend) {
        this.$router.path = '/';
        this.$router.push({name: 'meme.view', params: {id: trend.slug, uuid: trend.uuid}});
      }
    }

  }
</script>

<style lang="scss" scoped>
  .grid-view {
    grid-auto-rows: 130px;
  }

  .discover {
    .grid-item:nth-child(6n) {
      grid-column: span 2;
    }

    .grid-item:nth-child(8n) {
      grid-column: span 2;
    }

    .grid-item:nth-child(9n) {
      grid-column: span 2;
      grid-row: span 2;
    }

    .grid-item:nth-child(2n) {
      grid-row-end: span 2;
    }

    .grid-item:nth-child(4n) {
      grid-row-end: span 1;
    }

    .grid-item:nth-child(6n) {
      grid-row-end: span 3;
    }

    .grid-item:nth-child(7n) {
      grid-row-end: span 2;
    }
  }

  .trending-memes {
    margin-bottom: 20px;

    .grid-view {
      grid-template-columns: repeat(7, 1fr) !important;
    }
  }

  .section {
    margin: 0 0 4rem 0;
  }

  /deep/ .modal-body {
    padding: 0 !important;
  }

  .menu {
    .new-collection-btn {
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
      padding: 0 24px;
      border-radius: 32px;
      border: solid 1px #d8d8d8;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 1200px) {
    .trending-memes {
      .grid-view {
        grid-auto-rows: 120px;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .grid-view {
      grid-template-columns: repeat(3, 1fr);
    }
    .trending-memes {
      .grid-view {
        grid-auto-rows: 100px;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .grid-view {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (max-width: 360px) {
  }

  @media only screen and (max-width: 375px) {
  }

  @media only screen and (max-width: 428px) {
    .trending-memes {
      display: flex;
      height: 140px;
      flex-flow: nowrap;
      overflow-y: hidden;
      overflow-x: auto;
      scroll-behavior: smooth;
      margin-bottom: 0;

      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
        height: 0;
      }

      .grid-view {
        display: flex;
        height: 100%;
        align-items: center;
      }

      .grid-item {
        display: inline-block;
        height: 130px;
        width: 130px;
      }
    }
    .discover {
      .grid-item:nth-child(2n) {
        grid-row-end: span 2;
      }

      .grid-item:nth-child(3n) {
        grid-row-end: span 1;
      }

      .grid-item:nth-child(4n) {
        grid-row-end: span 2;
      }

      .grid-item:nth-child(5n) {
        grid-row-end: span 1;
      }

      .grid-item:nth-child(6n) {
        grid-column: unset !important;
        grid-row-end: span 3;
      }

      .grid-item:nth-child(7n) {
        grid-row-end: span 2;
      }

      .grid-item:nth-child(8n) {
        grid-column: unset !important;
      }

      .grid-item:nth-child(9n) {
        grid-column: unset !important;
        grid-row: unset !important;
      }
    }
  }

</style>
