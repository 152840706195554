<template>
  <div>
    <div class="section scrollable mb-0">
      <div class="scroll-btn left" @click="scroll_left()"><span>&#x2039;</span></div>
      <div class="creators-container text-nowrap mr-0 ml-0 p-0">
        <div class="hash-tag-box d-inline-block" v-for="(creator,i) in topCreators" :key="i">
          <gif-box :data="creator.voice_meme" :show-bottom-settings="false" :show-settings-button="false"
                   :is-muted="isMuted" :height="'100%'"
                   :index="i" is-tag :tag="`@${ creator.username }`" @onVolumeChange="changeVolume"
                   :box-route-object="{name: 'profile.gallery', params: {id: creator.username}}"
                   @onBoxClick="onBoxClick($event, creator)"/>
        </div>
      </div>
      <div class="scroll-btn right" @click="scroll_right()"><span>&#x203A;</span></div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import GifBox from "./GifBox";

  export default {
    name: "TopCreator",
    components: {GifBox},
    computed: {
      ...mapState(['currentUser', 'topCreators', 'isMuted'])
    },
    mounted() {
      this.getTopCreators();
    },
    methods: {
      ...mapActions(['getTopCreators', 'changeIsMuted']),
      scroll_left() {
        let content = document.querySelector(".creators-container");
        content.scrollLeft -= 100;
      },
      scroll_right() {
        let content = document.querySelector(".creators-container");
        content.scrollLeft += 100;
      },
      changeVolume(val) {
        this.changeIsMuted(val.isMuted)
      },
      onBoxClick(evt, creator) {
        this.$router.path = '/';
        this.$router.push({name: 'user', params: {id: creator.username}});
      }
    }
  }
</script>

<style lang="scss" scoped>
  .creators-container {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }


  .hash-tag-box {
    position: relative;
    margin-right: 1rem;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);

    .header-img {
      object-fit: cover;
      background-color: #c5cace;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }

  .hash-tag-box-text {
    position: absolute;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
  }

  @media only screen and (max-width: 768px) {
    .hash-tag-box-text {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 576px) {
    .hash-tag-box-text {
      font-size: 8px;
    }
  }
</style>