<template>
  <div>
    <b-modal hide-header v-model="authModal.show" hide-footer @show="onShow">
      <div class="auth-modal">
        <div class="modal-content">
          <div class="close-btn" @click="hideAuthModal()">
            <b-icon icon="x" class="close-btn-icon"></b-icon>
          </div>
          <div class="d-flex text-center">
            <div :class="activeTab === 'signIn' ? 'tab active' : 'tab'" @click="changeActiveTab('signIn')">SIGN IN</div>
            <div :class="activeTab === 'signUp' ? 'tab active' : 'tab'" @click="changeActiveTab('signUp')">REGISTER
            </div>
          </div>
          <b-form @submit.prevent="onLoginClick()" v-if="activeTab === 'signIn'" class="login-form">
            <label class="error-msg">{{ loginError }}</label>
            <validation-provider rules="required" v-slot="{ errors }" class="input-widget">
              <input v-model="userName" type="text" placeholder="Username"/>
              <span class="error-msg" v-if="!fieldErrors.length">{{ errors[0] }}</span>
              <span class="error-msg">{{ userNameErrorMsg }}</span>
            </validation-provider>
            <validation-provider rules="required" v-slot="{ errors }" class="input-widget">
              <input v-model="password" name="password" type="password" placeholder="Password"/>
              <span class="error-msg" v-if="!fieldErrors.length">{{ errors[0] }}</span>
              <span class="error-msg">{{ passwordErrorMsg }}</span>
            </validation-provider>
            <b-button class="login-btn btn-hover" type="submit">
              <b-spinner v-if="isLoading"/>
              <span v-else><img src="../assets/icons/user.svg" alt="user"/>Log in</span>
            </b-button>
            <div class="forgot-password-text btn-hover">Forgot password?</div>
          </b-form>
          <b-form v-else class="login-form" @submit.prevent="onRegisterClick()">
            <label class="error-msg">{{ loginError }}</label>
            <validation-provider :rules="!userName ? 'required|email' : 'email'" v-slot="{ errors }"
                                 class="input-widget">
              <input v-model="email" name="email" type="email" placeholder="E-mail"/>
              <span class="error-msg" v-if="!fieldErrors.length">{{ errors[0] }}</span>
              <span class="error-msg">{{ emailErrorMsg }}</span>
            </validation-provider>
            <validation-provider :rules="!email ? 'required' : ''" v-slot="{ errors }" class="input-widget">
              <input v-model="userName" name="userName" type="text" placeholder="Username"/>
              <span class="error-msg" v-if="!fieldErrors.length">{{ errors[0] }}</span>
              <span class="error-msg">{{ userNameErrorMsg }}</span>

            </validation-provider>
            <validation-provider rules="required" v-slot="{ errors }" class="input-widget">
              <input v-model="password" name="password" type="password" placeholder="Password"/>
              <span class="error-msg" v-if="!fieldErrors.length">{{ errors[0] }}</span>
              <span class="error-msg">{{ passwordErrorMsg }}</span>

            </validation-provider>
            <div class="accept-terms d-flex">
              <input class="mr-4" type="checkbox" v-model="acceptTerms"/>
              <label :class="accepted ? '' : 'error-msg' ">I accept terms and conditions</label>
            </div>
            <b-button class="login-btn btn-hover" type="submit">
              <b-spinner v-if="isLoading"/>
              <span v-else>Register</span>
            </b-button>
          </b-form>
          <div class="auth-modal-footer d-flex align-items-center justify-content-center">
            <img class="btn-hover" src="../assets/icons/facebook_colored.svg" alt="facebook" @click="fbLogin"/>
            <div id="google-auth-button"></div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  export default {
    name: "Auth",
    data() {
      return {
        signIn: true,
        signUp: false,
        userName: null,
        email: null,
        password: null,
        acceptTerms: false,
        userNameErrorMsg: '',
        emailErrorMsg: '',
        passwordErrorMsg: '',
        accepted: true,
        fieldErrors: [],
        loginError: '',
        activeTab: '',
      }
    },
    computed: {
      ...mapState(['authModal', 'isLoading'])
    },
    methods: {
      ...mapActions(['login', 'hideAuthModal', 'register']),
      async onLoginClick() {

        this.fieldErrors = []
        this.userNameErrorMsg = '';
        this.passwordErrorMsg = '';

        if (!this.password) {
          this.passwordErrorMsg = 'This field is required'
          this.fieldErrors.push('password')
        }
        if (!this.userName) {
          this.userNameErrorMsg = 'This field is required'
          this.fieldErrors.push('userName')
        }

        if (this.fieldErrors.length) {
          return;
        }

        const response = await this.login({
          // username: 'waska',//this.userName,
          // password: '12345678',//his.password,
          username: this.userName,
          password: this.password,
          device_name: 'website'
        })

        if (response.result) {
          this.loginError = '';
          this.hideAuthModal();
        } else {
          this.loginError = response.errors.message;
        }
      },
      async socialLogin(provider, token) {
        const response = await this.login({
          provider: provider,
          token: token,
        })

        if (response.result) {
          this.loginError = '';
          this.hideAuthModal();
        } else {
          this.loginError = response.errors.message;
        }
      },
      fbLogin() {
        window.FB.login((response) => {
          if (response.status === 'connected') {
            this.socialLogin('facebook', response.authResponse.accessToken)
          }
        }, {scope: 'public_profile,email'});
      },
      renderGoogleButton() {
        /* global gapi */
        gapi.signin2.render('google-auth-button', {
          'scope': 'profile email',
          'width': 50,
          'height': 50,
          'theme': 'dark',
          'onsuccess': this.onSuccess,
          'onfailure': this.onFailure
        });
      },
      async onSuccess(googleUser) {
        const resp = await this.login({
          provider: 'google',
          token: googleUser.getAuthResponse(true).id_token,
        })

        if (resp.result) {
          this.loginError = '';
          this.hideAuthModal();
        }
      },
      onFailure(error) {
        this.loginError = error;
      },
      async onRegisterClick() {

        this.fieldErrors = []
        this.userNameErrorMsg = '';
        this.emailErrorMsg = '';
        this.passwordErrorMsg = '';

        !this.acceptTerms ? this.accepted = false : this.accepted = true

        if (!this.password) {
          this.passwordErrorMsg = 'The password field is required.'
          this.fieldErrors.push('password')
        }
        if (!this.acceptTerms) {
          this.fieldErrors.push('acceptTerms')
        }
        if (!this.userName && !this.email) {
          this.emailErrorMsg = 'The email field is required when username is not present.'
          this.userNameErrorMsg = 'The username field is required when email is not present.'

          this.fieldErrors.push('userName', 'email')
        }

        if (this.fieldErrors.length) {
          return
        }

        const response = await this.register({
          username: this.userName,
          password: this.password,
          email: this.email,
        })
        if (response.result) {
          this.hideAuthModal();
        } else {
          if (response.errors.password) {
            this.passwordErrorMsg = response.errors.password[0]
          }
          if (response.errors.email) {
            this.emailErrorMsg = response.errors.email[0]
          }
          if (response.errors.message) {
            this.loginError = response.errors.message[0];
          }
        }
      },
      onShow() {
        this.clearData();
        this.activeTab = this.authModal.activeTab;
        this.$nextTick(() => {
          this.renderGoogleButton();
        })
      },
      changeActiveTab(type) {
        this.activeTab = type;
        this.clearData();
        this.loginError = '';
        this.accepted = true;
        this.fieldErrors = []
        this.userNameErrorMsg = '';
        this.emailErrorMsg = '';
        this.passwordErrorMsg = '';
      },
      clearData() {
        this.userName = '';
        this.email = '';
        this.password = '';
        this.acceptTerms = false;
      }
    }

  }
</script>

<style lang="scss" scoped>
  /deep/ .modal-body {
    padding: 0 !important;
  }

  /deep/ .modal-content {
    background: unset;
    border: none;
  }

  /deep/ .modal-dialog {
    max-width: 450px;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%) !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
  }

  .auth-modal {

    .modal-content {

      .close-btn {
        display: none;
      }

      .tab {
        font-family: "Exo 2", sans-serif;
        font-size: 30px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        width: 50%;
        padding: 1.5rem;
        border-radius: 12px 12px 0 0;
        cursor: pointer;
        background-color: #1a1a1a;
        color: #f0f0f0;
      }

      .active {
        background-color: #ffffff;
        color: #1a1a1a
      }

      .login-form {
        padding: 3rem;
        margin-top: -5px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
        position: relative;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        justify-content: center;
        align-items: center;

        input {
          height: 50px;
          width: 100%;
        }

        input[type=checkbox] {
          width: 30px;
          height: 26px;
          border-radius: 7px;
          border: solid 1px #d8d8d8;
        }

        .accept-terms {
          font-family: "Open Sans", sans-serif;
          font-size: 20px;
          font-weight: 100;
          font-stretch: normal;
          font-style: normal;
          color: #1a1a1a;
          margin: 1rem 0;
        }

        .login-btn {
          width: 100%;
          height: 50px;
          margin-top: 0;
          border-radius: 32px;
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
          background-color: #1a1a1a;
          cursor: pointer;
          color: #ffffff;
          display: flex;
          justify-content: center;
          font-family: "Open Sans", sans-serif;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;

          span {
            margin-left: -4px;
          }
        }

        .forgot-password-text {
          margin-top: 45px;
          font-family: "Open Sans", sans-serif;
          font-size: 20px;
          font-weight: 100;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          cursor: pointer;
        }
      }

      .auth-modal-footer {
        padding: 2rem;
        margin-top: -1px;
        border-radius: 0 0 12px 12px;
        box-shadow: inset 0 10px 30px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;

        img {
          margin: 0 10px;
          cursor: pointer;
        }
      }
    }
  }

  /deep/ .abcRioButtonBlue {
    background-color: transparent;

    &:hover {
      background-color: transparent;
      opacity: 0.7;
    }

    .abcRioButtonIcon {
      background: transparent;
      padding: 7px 0 0 0 !important;
    }

    span {
      display: none;
    }

    svg {
      width: 35px;
      height: 35px;
    }
  }

  /deep/ .abcRioButton {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }


  @media only screen and (max-width: 428px) {

    /deep/ .modal-dialog {
      max-width: calc(100% - 20px);
    }

    .auth-modal {

      .modal-content {

        .close-btn {
          align-self: flex-end;
          width: 40px;
          height: 40px;
          background: #ffffff;
          border-radius: 50%;
          margin-bottom: 7px;
          display: flex;
          align-items: center;
          justify-content: center;

          .close-btn-icon {
            width: 25px;
            height: 25px;
          }

        }

        .tab {
          font-size: 25px;
          padding: 1rem;
        }

        .login-form {
          padding: 2.5rem;

          input[type=checkbox] {
            width: 22px;
            height: 22px;
          }

          .forgot-password-text {
            margin-top: 23px
          }

          .accept-terms {
            margin: 23px 0;
            font-size: 16px;
          }
        }

        .auth-modal-footer {
          padding: 2rem;

          img {
            width: 30px;
          }
        }

      }
    }
    /deep/ .abcRioButtonBlue {

      .abcRioButtonIcon {
        padding: 11px 0 0 0 !important;
      }

      svg {
        width: 27px;
        height: 27px;
      }
    }
  }


</style>
