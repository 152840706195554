<template>
  <div class="col-sm-3 col-md-3 p-3">
    <a class="btn-hover" :href="extension.href" target="_blank">
      <div class="extension">
        <div class="img">
          <video v-if="extension.videoSrc" loop ref="video" autoplay muted>
            <source :src="extension.videoSrc" type="video/mp4"/>
          </video>
          <img v-else :src="extension.imgSrc" class="extension-img" :alt="extension.title">
        </div>
        <div class="title">{{ extension.title }}</div>
        <div class="description">{{ extension.desc }}</div>
        <div class="version">
          <label>version {{ extension.version }}</label>
          <div class="logo">
            <img v-if="extension.logo" :src="extension.logo" alt="logo"/>
            <b-icon v-else icon="card-image"/>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
  export default {
    name: "ExtensionBox",
    props: {
      extension: {
        type: Object,
        default: () => {
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .extension {
    height: 400px;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;

    .extension-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .img {
      width: 100%;
      height: 200px;
      border: solid 1px #f0f0f0;

      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    .title {
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #1a1a1a;
      margin-top: 0.5rem;

      &:hover {
        text-decoration: underline;
      }
    }

    .description {
      font-size: 12px;
      line-height: 1.2;
      margin-top: 0.2rem;
    }

    .version {
      display: flex;
      align-items: center;
      justify-content: space-between;
      bottom: 1rem;
      position: absolute;
      width: 100%;
      padding-right: 4rem;
      font-size: 12px;

      .logo {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
        }
      ;
      }
    }
  }
</style>