<template>
  <div>
    <div class="terms-and-privacy justify-content-end ">
      <router-link to="/documentation/terms">
        <div class="general-button btn-hover mr-4 " @click="setActiveTab('terms')">Terms</div>
      </router-link>
      <router-link to="/documentation/privacy">
        <div class="general-button btn-hover" @click="setActiveTab('privacy')">Privacy</div>
      </router-link>
    </div>
    <Auth/>
    <info-popup/>
    <info-modal/>
    <extension-modal class="not-for-mobile"/>
    <share/>
  </div>

</template>

<script>
  import Auth from "./Auth";
  import {mapActions} from "vuex";
  import InfoPopup from "./widgets/InfoPopup";
  import InfoModal from "./widgets/InfoModal";
  import Share from "./widgets/Share";
  import ExtensionModal from "./widgets/ExtensionModal";

  export default {
    name: "DefaultLayout",
    components: {ExtensionModal, Share, InfoModal, InfoPopup, Auth}, methods: {
      ...mapActions(['setActiveTab']),
    }
  }
</script>

<style lang="scss" scoped>

  .terms-and-privacy {
    position: fixed;
    bottom: 3em;
    right: 3em;
    z-index: 0;
    display: flex;

    .general-button {
      width: 110px;
      height: 45px;
      padding: 13px 34px 14px 31px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      cursor: pointer;
      border-radius: 32px;
    }
  }

  @media only screen and (max-width: 428px) {
    .terms-and-privacy {
      display: none;
    }
  }


</style>