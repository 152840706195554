<template>
  <div>
    <section-title secondary="new meme" main="Create"/>
    <div class="row general-frame">
      <div class="col-md-6 col-sm-12 video-container d-flex justify-content-center position-relative">
        <div v-for="(er, index) in fileErrors" :key="'er_'+index" class="error-msg">{{ er }}</div>
        <div class="mb-4 mt-4 " v-if="voiceMeme.url">
          <video class="gif video" loop ref="video" autoplay controls muted>
            <source :src="voiceMeme.url" type="video/mp4"/>
          </video>
        </div>
        <div class="mb-4 mt-4" v-if="voiceMeme && voiceMeme.youtubeUrl">
          <youtube-video-cutter :url="youtube_parser(voiceMeme.youtubeUrl)" @rangeSet="getVideoRange"/>
        </div>
        <div class="spinner" v-if="isLoading">
          <b-spinner style="width: 5rem; height: 5rem;"></b-spinner>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="mb-4 mt-4">
          <div class="d-flex flex-column gif-form">
            <SectionTitle main="Title" secondary="and description" size="md"/>
            <validation-provider rules="required" v-slot="{ errors }" class="input-widget">
              <input v-model="title" name="title" type="text" placeholder="Enter descriptive title" autocomplete="off"/>
              <span class="error-msg" v-if="!titleErrorMsg">{{ errors[0] }}</span>
              <span class="error-msg">{{ titleErrorMsg }}</span>
            </validation-provider>
            <validation-provider rules="" v-slot="{ errors }" class="input-widget">
              <textarea v-model="description" name="description" placeholder="Provide short description"
                        autocomplete="off"/>
              <span class="error-msg">{{ errors[0] }}</span>
            </validation-provider>

            <SectionTitle main="Visibility" secondary="and hashtags" size="md"/>

            <b-form-tags input-id="tags" v-model="tags" class="form-tags"
                         placeholder="Write any hashtags that come to your mind..." separator=",;" remove-on-delete
                         add-on-change></b-form-tags>
            <div class="input-widget mt-4">
              <b-form-select v-model="selectedStatus" :options="voicememe_statuses"></b-form-select>
            </div>

            <SectionTitle main="Copyright" secondary="and source" size="md"/>

            <validation-provider rules="" v-slot="{ errors }" class="input-widget">
              <input type="text" v-model="source" name="source" placeholder="Provide source" autocomplete="off"/>
              <span class="error-msg">{{ errors[0] }}</span>
            </validation-provider>

            <div class="row not-for-mobile">
              <div class="col-md-8 col-sm-12">
                <button class="button-general publish-btn btn-hover"
                        :disabled="isLoading"
                        @click="onSubmit()">
                  <b-spinner v-if="isLoading"/>
                  <span v-else>DONE</span>
                </button>
              </div>
            </div>
            <label class="error-msg">{{ serverError }}</label>
          </div>
          <div class="submit-btn-res for-mobile" @click="onSubmit()">
            <b-button class="submit-btn1" :disabled="isLoading">
              <b-spinner v-if="isLoading"></b-spinner>
              <span v-else>DONE</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SectionTitle from "./widgets/SectionTitle";
  import {mapActions, mapState} from "vuex";
  import YoutubeVideoCutter from "./widgets/YoutubeVideoCutter";

  export default {
    name: "Preview",
    metaInfo() {
      return {
        title: 'Create GIF',
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `Create GIF on KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`
          },
        ],
      };
    },
    components: {YoutubeVideoCutter, SectionTitle},
    data() {
      return {
        isPublic: true,
        tags: [],
        title: null,
        description: null,
        source: null,
        selectedStatus: 1,
        serverError: null,
        titleErrorMsg: null,
        fileErrors: [],
        voiceMeme: {
          uuid: null,
          url: null,
          youtubeUrl: null
        },
        videoRange: [0, 10],
        editObject: null,
        isMobileDevice: null,
      }
    },
    computed: {
      ...mapState({
        voicememe_statuses: state => state.voicememe_statuses,
        isLoading: state => state.isLoading,
        currentUser: state => state.currentUser,
      }),
    },
    watch: {
      editObject() {
        if (this.editObject) {
          this.title = this.editObject.title
          this.description = this.editObject.description
          this.source = this.editObject.source
          this.voiceMeme.uuid = this.editObject.file.uuid
          this.voiceMeme.url = this.editObject.file.url
          this.tags = this.editObject.tags

          if (this.currentUser.username !== this.editObject.author.username) {
            this.$router.push({name: 'meme.view', params: {id: this.editObject.slug, uuid: this.editObject.uuid}});
          }
        }
      }
    },
    mounted() {
      this.showMenu(false)
      window.addEventListener('resize', this.checkDevice)
      this.checkDevice()

      if (!this.$route.params.files && !this.$route.params.id && !this.$route.params.link) {
        if (this.isMobileDevice) {
          this.$router.path = '/';
          this.$router.push({name: 'main'});
        } else {
          this.$router.path = '/';
          this.$router.push({name: 'create'});
        }

      }
      if (this.$route.params.files) {
        this.getFile(this.$route.params.files)
      }
      if (this.$route.params.id) {
        this.getMemeData();
      }
      if (this.$route.params.link) {
        this.voiceMeme.youtubeUrl = this.$route.params.link
      }

    },
    beforeDestroy() {
      this.showMenu(true)
    },
    methods: {
      ...mapActions(['createMeme', 'getFileUuid', 'getMeme', 'updateMeme', 'getLinkFileId', 'updateInfoModal', 'showOverlay', 'createMemeFromGallery', 'showMenu', 'updateInfoPopup']),
      async onSubmit() {
        this.titleErrorMsg = null;
        let data = {
          url: this.voiceMeme.youtubeUrl,
          seconds_from: this.videoRange[0],
          seconds_to: this.videoRange[1],

          title: this.title,
          description: this.description,
          source: this.source,
          tags: this.tags,
          status: this.selectedStatus,
        }
        let response;
        if (this.editObject && this.editObject.uuid) {
          response = await this.updateMeme({...data, uuid: this.editObject.uuid});
        } else {
          if (this.voiceMeme.youtubeUrl) {
            response = await this.createMeme(data);
          } else {
            response = await this.createMemeFromGallery({...data, file_id: this.voiceMeme.uuid});
          }
        }
        if (!response.result) {
          if (response.errors.title) {
            this.titleErrorMsg = response.errors.title[0];
          }
          this.serverError = response.errors[0]
        } else {
          if (this.voiceMeme.youtubeUrl) {
            this.$router.path = '/';
            await this.$router.push({name: 'main'});
            this.updateInfoModal({show: true, text: response.data.message})
            this.showOverlay();
          } else {
            this.updateInfoPopup({show: true, text: "Video updated successfully"})
            this.$router.path = '/';
            this.$router.push({name: 'meme.view', params: {id: response.data.slug}});
          }
        }
      },
      async getFile(files) {
        this.fileErrors = null
        let formData = new FormData();
        formData.append("file", files[0]);
        formData.append("type", "voice-meme");
        let response = await this.getFileUuid(formData)
        if (response.result) {
          this.voiceMeme.uuid = response.data.uuid;
          this.voiceMeme.url = response.data.url;
        } else {
          if (response.errors.file) {
            let errorMessage = `<div class="text-left">The file contains the following error(s): <ul><li>${response.errors.file.join('</li><li>')}</li></ul></div>`
            this.updateInfoModal({show: true, text: errorMessage, callback: () => {
              this.$router.push({name: 'create'});
            }})
            this.showOverlay();
          }
        }
      },
      async getMemeData() {
        this.error = null;
        let response = await this.getMeme(this.$route.params.id)
        if (response.result) {
          this.editObject = response.data
        } else {
          this.error = response.errors.message[0]
        }
      },
      getVideoRange(val) {
        this.videoRange = val
      },
      youtube_parser(url) {
        let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
      },
      async getYoutubeFileId() {
        let data = {
          url: this.$route.params.link,
          seconds_from: this.videoRange[0],
          seconds_to: this.videoRange[1]
        }
        let response = await this.getLinkFileId(data);
        if (response.result) {
          this.voiceMeme.uuid = response.data.uuid;
          this.voiceMeme.url = response.data.url;
        } else {
          if (response.errors.file) {
            this.fileErrors = response.errors.file
          }
        }
        return response.result
      },
      checkDevice() {
        this.isMobileDevice = window.screen.availWidth <= 428
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../style';

  .form-tags {
    /deep/ input {
      border-radius: 0;
      font-size: 12px;
    }
  }

  .video-container {
    position: relative;
    width: 100%;
    height: 500px;

    .video {
      width: 100%;
      height: 100%;
      background: black;
      border-radius: 1rem;
    }
  }

  /deep/ iframe {
    border-radius: 1rem;
    width: 100% !important
  }

  .gif-form {
    padding: 0 2rem;

    .publish-btn {
      border: none;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      background: #1a1a1a;
      color: #ffffff;
      cursor: pointer;
    }

  }

  .spinner {
    margin-bottom: 1.5rem;
    margin-top: 31%;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  @media only screen and (max-width: 428px) {
    .general-frame {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 150px;
      box-shadow: none;
    }
    .video-container {
      height: auto;
    }

    .gif-form {
      padding: 0 0.5rem;
    }
    .submit-btn-res {
      position: fixed;
      display: block;
      bottom: 0;
      background: #ffffff;
      width: 100%;
      height: 100px;
      margin-left: -25px;
      padding: 10px 15px;

      .submit-btn1 {
        width: 100%;
        height: 65px;
        padding: 20.5px 168px 22.5px 170px;
        border-radius: 32px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
        background-color: #1a1a1a;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
      }
    }
  }

</style>
