<template>
  <div>
    <b-modal v-model="shareModal.show" hide-footer centered @hidden="hidden">
      <template slot="modal-title">
        Share to
      </template>
      <b-input :value="shareModal.link" ref="memeLink" onclick="this.focus();this.select()"
               readonly="readonly"></b-input>
      <div class="social-media-container">
        <!-- Facebook -->
        <a :href="`https://www.facebook.com/sharer.php?u=${shareLink}`" target="_blank">
          <div class="icon-container btn-hover">
            <img class="social-icon" src="./../../assets/images/facebook.svg" alt="facebook"/>
            <span>Facebook</span>
          </div>
        </a>
        <!-- Messenger -->
        <a :href="`fb-messenger://share/?link=${EncodedLink}`" target="_blank" class="for-mobile">
          <div class="icon-container btn-hover">
            <img class="social-icon" src="./../../assets/images/messenger.svg" alt="messenger"/>
            <span>Messenger</span>
          </div>
        </a>
        <!-- Twitter -->
        <a :href="`https://twitter.com/share?url=${shareLink}`" target="_blank">
          <div class="icon-container btn-hover">
            <img class="social-icon" src="./../../assets/images/twitter.svg" alt="twitter"/>
            <span>Twitter</span>
          </div>
        </a>
        <!-- Reddit -->
        <a :href="`https://reddit.com/submit?url=${shareLink}`" target="_blank">
          <div class="icon-container btn-hover">
            <img class="social-icon" src="./../../assets/images/redit.svg" alt="redit"/>
            <span>Reddit</span>
          </div>
        </a>
        <!-- LinkedIn -->
        <a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${shareLink}`" target="_blank">
          <div class="icon-container btn-hover">
            <img class="social-icon" src="./../../assets/images/linkedin.svg" alt="linkedin"/>
            <span>Linked In</span>
          </div>
        </a>
        <!--whatsapp-->
        <a :href="`https://wa.me?text=${shareLink}`" target="_blank">
          <div class="icon-container btn-hover">
            <img class="social-icon" src="./../../assets/images/whatsapp.svg" alt="whatsapp"/>
            <span>WhatsApp</span>
          </div>
        </a>
        <!-- Copy -->
        <div class="btn-hover" @click="copyShareLink()">
          <div class="icon-container">
            <div class="copy-btn " :style="{background:profileColor}">
              <Sprite symbol="icon-copy" class="m-2 text-white"/>
            </div>
            <span>Copy Link</span>
          </div>
        </div>
        <!-- Download -->
        <div class="btn-hover" @click="downloadVideo()">
          <div class="icon-container">
            <div class="copy-btn " :style="{background:profileColor}">
              <Sprite symbol="icon-download" class="m-2 text-white"/>
            </div>
            <span>Download</span>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import copy from 'copy-text-to-clipboard';
  import Sprite from "./Sprite";

  export default {
    name: "Share",
    components: {Sprite},
    computed: {
      ...mapState(['shareModal', 'profileColor']),
      shareLink() {
        if (this.shareModal.link && !this.shareModal.link.includes('kikliko')) {
          //for localhost testing purposes only
          return this.shareModal.link.replace('localhost:8080', 'kikliko.com').replace('192.168.100.152:8080', 'kikliko.com')
        }
        return this.shareModal.link
      },
      EncodedLink() {
        return encodeURIComponent(this.shareLink)
      },
    },
    watch: {
      'shareModal.link': {
        handler() {
          if (this.shareModal.link) {
            setTimeout(() => {
              let url = this.$refs.memeLink
              url.select();
            }, 200)
          }
        }
      }
    },
    methods: {
      ...mapActions(['updateInfoPopup', 'updateShareModal', 'shareCounterRequest', 'increaseDownload']),
      async copyShareLink() {
        this.$refs.memeLink.$el.focus()
        this.$refs.memeLink.$el.select()
        copy(this.shareModal.link)
        this.shareCounterRequest(this.shareModal.uuid)
        this.updateShareModal({show: false, link: '', uuid: '', meme: {}});
        this.updateInfoPopup({show: true})
      },
      async downloadVideo() {
        this.$fileHelper.downloadFile(
            this.$helper.apiUrl(`/web/download-with-watermark/${this.shareModal.meme.file.uuid}`),
            this.shareModal.meme.title + '.mp4',
            () => {
              this.increaseDownload(this.shareModal.meme.slug)
            }
        )
      },
      hidden() {
        this.updateShareModal({show: false, link: '', uuid: '', meme: {}});
      }
    }
  }
</script>

<style lang="scss" scoped>
  .social-media-container {
    display: flex;
    flex-wrap: wrap;

    .social-icon {
      width: 50px;
      margin: 20px 20px 0 20px;
    }

    .icon-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .copy-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50px;
      height: 50px;
      border-radius: 7px;
      margin: 20px 20px 0 20px;

      img {
        width: 100%;
        height: 40px;
      }
    }
  }

  @media only screen and (max-width: 428px) {
    /deep/ .modal-dialog-centered {
      display: block;
      transform: translate(0, calc(100% - 323px)) !important;
    }
  }
</style>
