<template>
  <svg>
    <use v-bind="{'xlink:href': getSpriteUrl()}"></use>
  </svg>
</template>

<script>
export default {
  name: "Sprite",
  props: {
    symbol: {
      type: String,
    },
  },
  methods: {
    getSpriteUrl() {
      return require('../../assets/icons/sprites.svg') + '#' + this.symbol;
    }
  }
}
</script>
<style lang="scss" scoped>
svg {
  width:100%;
  height:100%;
}
</style>
