<template>
  <div>
    <hr class="hr">
    <div class="collection-title">
      <b-icon @click="goToCollections()" icon="chevron-left" class="go-back-btn"></b-icon>
      <div class="collection-title-text">{{
          collection && collection.title ? collection.title.toUpperCase() : ''
        }}
      </div>
      <img class="menu" src="../assets/icons/dots.svg" alt="menu" @click="showModal()">
      <collection-modal-inner v-if="modalOpen" @onHideModal="hideModal" :collection="collection"/>
    </div>
    <hr>
    <div class="error-msg" v-if="error">{{ error }}</div>
    <trending :data="voicememes ? voicememes.data: []" :key="currentUser ? currentUser.uuid : ''"/>
    <div class="d-flex justify-content-center mb-3 mt-3" v-if="isLoading">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import Trending from "./widgets/Trending";
  import CollectionModalInner from "./CollectionModalInner";

  export default {
    name: "CollectionInnerView",
    components: {CollectionModalInner, Trending},
    props: {
      item: {
        type: Object,
      }
    },
    data() {
      return {
        error: '',
        voicememes: null,
        isLoading: false,
        modalOpen: false
      }
    },
    metaInfo() {
      let title = this.collection && this.collection.title ? this.collection.title : null;
      return {
        title: title ? `@${this.$route.params.id.toUpperCase()} - ${title}` : `@${this.$route.params.id.toUpperCase()} Collection`,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `Find ${this.$route.params.id.toUpperCase()} Collection ${title ? title : ''} on KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`,
          },
        ],
      };
    },
    computed: {
      ...mapState({
        currentUser: state => state.currentUser,
        collection: state => state.currentCollection,
      }),
    },
    mounted() {
      window.addEventListener('scroll', this.loadDataHandler)
      this.loadData();
      this.updateHideHeader(true)
    },
    beforeDestroy() {
      this.hideOverlay();
      this.updateHideHeader(false)
    },
    destroyed() {
      window.removeEventListener('scroll', this.loadDataHandler);
      this.voicememes = null;
    },
    methods: {
      ...mapActions(['addMemeToCollection', 'showCollectionModal', 'getCollectionData', 'showOverlay', 'updateHideHeader','hideOverlay']),
      async loadData(page, per_page) {
        if (this.isLoading) {
          return
        }
        if (this.voicememes) {
          if ((this.voicememes.total === this.voicememes.data.length) && this.voicememes.total !== 0) {
            return
          }
        }
        this.error = ''
        this.isLoading = true;
        let res = await this.getCollectionData({page, per_page, uuid: this.$route.params.collectionId})
        if (res.result) {
          if (this.voicememes && this.voicememes.data) {
            this.voicememes = {...res.data, data: [...this.voicememes.data, ...res.data.data]};
          } else {
            this.voicememes = res.data
          }
          this.isLoading = false;
        } else {
          this.error = res.errors.message[0]
        }
      },
      loadDataHandler() {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow && this.voicememes.data.length) {
          this.loadData(this.voicememes.current_page + 1);
        }
      },
      goToCollections() {
        this.$router.go(-1)

        // this.$router.path = '/';
        // this.$router.push({name: 'profile.collection'})
      },
      showModal() {
        this.showOverlay()
        this.modalOpen = true;
      }, hideModal() {
        this.modalOpen = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .collection-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
  }

  /deep/ .grid-view {
    grid-template-columns: repeat(3, 1fr);
  }

  .menu {
    cursor: pointer;
    position: relative;
  }

  /deep/ .collection-modal {
    top: 50px;
    right: 0;
  }

  @media only screen and (max-width: 428px) {
    .collection-title-text {
      font-size: 20px;
    }
  }
</style>