<template>
  <Landing :reset-tag="false"/>
</template>

<script>
  import Landing from "./Landing";
  import {mapActions} from "vuex";

  export default {
    name: "TagLanding",
    components: {Landing},
    metaInfo() {
      return {
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `${this.$route.params.tag} GIFS from KikLiko website. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`,
          },
        ],
      };
    },
    methods: {
      ...mapActions(['updateFilter']),
      filterTag(tag) {
        this.updateFilter({
          tag: tag,
        })
      }
    },
    watch: {
      '$route.params.tag': {
        handler(new_val) {
          this.filterTag(new_val);
        },
        immediate: true,
      }
    }
  }
</script>