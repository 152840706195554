<template>
  <div class="collection-modal">
    <div class="modal-content">
      <div class="collection-form">
        <b-icon icon="x" class="close-btn" @click="hideModal()"></b-icon>
        <div class="modal-title">
          <section-title main="title" secondary="and privacy" size="md"/>
        </div>
        <b-form @submit.prevent="onSubmit()" class="login-form">
          <div class="input-widget">
            <validation-provider rules="required" v-slot="{ errors }">
              <input type="text" v-model="title" placeholder="Title" name="title"/>
              <span class="error-msg" v-if="!titleErrorMsg">{{ errors[0] }}</span>
              <span class="error-msg">{{ titleErrorMsg }}</span>
            </validation-provider>
          </div>
          <div class="input-widget">
            <b-form-select v-model="selectedStatus" :options="collection_statuses"></b-form-select>
          </div>
          <button class="create-btn btn-hover " type="submit">
            <span>SAVE</span>
          </button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import SectionTitle from "./widgets/SectionTitle";

  export default {
    name: "CollectionModalInner",
    components: {SectionTitle},
    props: {
      collection: {
        type: Object
      }
    },
    data() {
      return {
        title: '',
        titleErrorMsg: '',
        selectedStatus: 1,
      }
    },
    computed: {
      ...mapState({
        collection_statuses: state => state.collection_statuses,
      }),
    },

    mounted() {
      this.title = this.collection.title;
      this.selectedStatus = this.collection.status;
    },
    methods: {
      ...mapActions(['updateCollection', 'updateCurrentCollection', 'hideOverlay', 'updateInfoPopup']),
      async onSubmit() {
        let data = {
          title: this.title,
          status: this.selectedStatus
        }
        let response;
        response = await this.updateCollection({...data, uuid: this.collection.uuid});
        if (response.result) {
          this.updateCurrentCollection(response.data)
          this.updateInfoPopup({show: true, text: 'Updated'})
          this.hideModal()
        } else {
          if (response.errors.title) {
            this.titleErrorMsg = response.errors.title[0]
          }
        }
      },
      hideModal() {
        this.hideOverlay()
        this.$emit('onHideModal')
      }
    }

  }
</script>

<style lang="scss" scoped>

  .collection-modal {
    top: 0;
    //left: 0;
    z-index: 1;
    position: absolute;
    width: 430px;
    border-radius: 12px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.16);

    .modal-content {
      border-radius: 12px;
      border: none;

      /deep/ .section-title {
        margin-bottom: 25px;
      }

      .close-btn {
        width: 32px;
        height: 32px;
        float: right;
        cursor: pointer;
      }

      .modal-title {
        clear: both;
      }

      .collection-form {
        padding: 21px 27px;

        /deep/ .input-widget {
          margin-bottom: 16px;
        }

        input {
          width: 100%;
        }

        .create-btn {
          width: 100%;
          height: 50px;
          margin-top: 15px;
          border-radius: 32px;
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
          background-color: #1a1a1a;
          cursor: pointer;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
        }
      }
    }
  }

</style>