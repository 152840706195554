<template>
  <div ref="memes" style="min-height: 100vh">
    <div class="section not-for-mobile">
      <SectionTitle main="TOP" secondary="creators"/>
      <div class="scrollable mb-0">
        <div class="creators">
          <top-creator/>
        </div>
      </div>
    </div>
    <div class="section" ref="memeResults" v-if="!searchText && !tag">
      <SectionTitle main="Trending" secondary="Now" :key="tag"/>
      <trending :key="`${tag}_${searchText}`" :data="trendingMemes" class="trending-memes" :ignore-grid="true"/>
    </div>
    <div class="section" ref="memeResults">
      <SectionTitle :main="sectionTitle.main" :secondary="sectionTitle.secondary" :key="tag"/>
      <trending :key="`${tag}_${searchText}`"/>
    </div>
    <collection-modal/>
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import TopCreator from "./widgets/TopCreator";
  import SectionTitle from "./widgets/SectionTitle";
  import CollectionModal from "./CollectionModal";
  import Trending from "./widgets/Trending";
  // import { EventBus } from '../main'

  export default {
    name: "Landing",
    metaInfo() {
      let prefix = '';
      if (this.$route.params.tag) {
        prefix = `#${this.$route.params.tag}`
      }
      if (this.$route.params.query) {
        prefix = `${this.$route.params.query}`
      }

      return {
        title: (prefix ? `${prefix} | ` : '') + 'KikLiko - Clips & Memes',
        titleTemplate: null
      };
    },
    components: {Trending, CollectionModal, SectionTitle, TopCreator},
    props: {
      resetTag: {
        type: Boolean,
        default: true,
      },
      resetQuery: {
        type: Boolean,
        default: true,
      },
      resetUsername: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        error: '',
        sectionTitle: {
          main: 'DISCOVER',
          secondary: ''
        },
        initialX: null,
        initialY: null,
      }
    }, computed: {
      ...mapState({
        topCreators: state => state.topCreators,
        currentUser: state => state.currentUser,
        username: state => state.filter.username,
        tag: state => state.filter.tag,
        searchText: state => state.filter.searchText,
        trendingMemes: state => state.trendingMemes,
      }),
    },
    watch: {
      tag() {
        if (this.tag) {
          this.sectionTitle.secondary = `for #${this.tag}`
          this.sectionTitle.main = 'Results'
        } else {
          this.sectionTitle.secondary = ``
          this.sectionTitle.main = 'Discover'
        }
      },
      searchText() {
        if (this.searchText) {
          this.sectionTitle.secondary = `for #${this.searchText}`
          this.sectionTitle.main = 'Results'
        } else {
          this.sectionTitle.secondary = ``
          this.sectionTitle.main = 'Discover'
        }
      }
    },
    beforeMount() {
      this.resetFilter();
    },
    mounted() {
      if (this.tag || this.searchText) {
        this.sectionTitle.secondary = `for #${this.tag || this.searchText}`
        this.sectionTitle.main = 'Results'
      }
      this.$nextTick(() => {
        if (this.$refs.memes) {
          // let el = this.$refs.memes;
          // el.addEventListener("touchstart", this.startTouch, false);
          // el.addEventListener("touchmove", this.moveTouch, false);
        }
      })
      const rect = this.$refs.memeResults ? this.$refs.memeResults.getBoundingClientRect() : null;
      if (rect) {
        this.updateMemeResultsTopPos(rect.top)
      }
      this.getTrendingMemes()
    },
    methods: {
      ...mapActions(['getTopCreators', 'updateMemeResultsTopPos', 'hideOverlay', 'getTrendingMemes', 'updateFilter']),
      // moveTouch(e) {
      //   if (this.initialX === null) {
      //     return;
      //   }
      //   if (this.initialY === null) {
      //     return;
      //   }
      //   let currentX = e.touches[0].clientX;
      //   let currentY = e.touches[0].clientY;
      //
      //   let diffX = this.initialX - currentX;
      //   let diffY = this.initialY - currentY;
      //
      //   if (Math.abs(diffX) > Math.abs(diffY)) {
      //     // sliding horizontally
      //     if (diffX > 0) {
      //       EventBus.$emit("onSwipe",'left');
      //     } else {
      //       EventBus.$emit('onSwipe', 'right');
      //     }
      //   }
      //
      //   this.initialX = null;
      //   this.initialY = null;
      //
      //   e.preventDefault();
      // },
      // startTouch(e) {
      //   this.initialX = e.touches[0].clientX;
      //   this.initialY = e.touches[0].clientY;
      // },
      resetFilter() {
        this.updateFilter({
          tag: this.resetTag ? '' : this.tag,
          username: this.resetUsername ? '' : this.username,
          searchText: this.resetQuery ? '' : this.searchText,
        })
      }
    }
  }
</script>

<style lang="scss" scoped>


</style>
