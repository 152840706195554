<template>
  <transition name="fade">
    <div v-if="infoModal.show" class="info-modal">
      <div class="text" v-html="infoModal.text">
      </div>
      <button @click="hideModal()" class="btn-hover" :style="{background:profileColor}">OK</button>
    </div>
  </transition>
</template>
<script>
  import {mapActions, mapState} from "vuex";

  export default {
    name: "InfoModal",
    watch: {
      'infoModal.show': {
        handler() {
          if (this.infoModal.show) {
            this.showOverlay()
          }
        }
      }
    },
    computed: {
      ...mapState(['infoModal', 'profileColor'])
    },
    methods: {
      ...mapActions(['updateInfoModal', 'hideOverlay', 'showOverlay']),
      hideModal() {
        this.hideOverlay()
        if (this.infoModal.callback) {
          this.infoModal.callback()
        }
        this.updateInfoModal({show: false, text: null, callback: null})
      }
    },
  }
</script>

<style lang="scss" scoped>
  .info-modal {

    border-radius: 12px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    position: fixed;
    width: 25%;
    padding: 15px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      font-size: 18px;
      font-weight: bold;
      padding: 40px;
    }

    button {
      height: 50px;
      padding: 0 95px;
      border-radius: 32px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
      background-color: #1a1a1a;
      color: #ffffff;
      font-family: "Open Sans", sans-serif;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
    }
  }

  @media only screen and (max-width: 428px) {
    .info-modal {
      width: 70%;
      top: 50%;
    }
  }

</style>
