<template>
  <div class="collection">
    <div class="gif-container">
      <picture v-if="data && data.thumbnails.length" @click="goToInnerView(data)" class="picture">
        <source :srcset="data.thumbnails[0].webp" type="image/webp">
        <source :srcset="data.thumbnails[0].gif" type="image/gif">
        <img class="gif meme-gif" :src="data.thumbnails[0].gif" :alt="data.title">
      </picture>
      <picture v-if="data && data.thumbnails.length" @click="goToInnerViewResponsive(data)" class="picture-res">
        <source :srcset="data.thumbnails[0].webp" type="image/webp">
        <source :srcset="data.thumbnails[0].gif" type="image/gif">
        <img class="gif meme-gif" :src="data.thumbnails[0].gif" :alt="data.title">
      </picture>
      <img v-else class="no-items" src="../../assets/icons/gallery.svg" alt="gallery"/>
      <div class="top-settings">
        <div class="title" :style="!data.thumbnails.length? {color: 'unset'}:{}"> {{ getTitle(data.title) }}</div>
        <b-dropdown v-if="isMe" class="menu float-end" variant="outline-none" no-caret>
          <template #button-content>
            <img v-if="data.thumbnails.length" class="float-end" src="../../assets/icons/dots_light.svg" alt="menu">
            <img v-else class="float-end" src="../../assets/icons/dots.svg" alt="menu">
          </template>
          <b-dropdown-item @click="showCollectionModal(data)">
            <div class="pb-2">Edit collection</div>
          </b-dropdown-item>
          <b-dropdown-item @click="onDeleteCollection(data.uuid)">
            <div class="pb-2"><strong>Delete</strong></div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div v-if="data.thumbnails.length>1" class="collection collection-back"
         :style="{backgroundImage: `url(${data.thumbnails[1].webp})`}">
    </div>
    <div v-else class="collection collection-back" :style="{backgroundImage: `url('../../assets/icons/gallery.svg')`}">
    </div>
  </div>
</template>

<script>
  import {mapActions} from "vuex";

  export default {
    name: "Collection",
    props: {
      data: {
        type: Object,
        default: () => {
        },
      },
      showBottomSettings: {
        type: Boolean,
        default: true,
      },
      showVolumeButton: {
        type: Boolean,
        default: true,
      },
      isMe: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        isMuted: true,
        showVideo: false,
      }
    },
    methods: {
      ...mapActions(['showCollectionModal', 'deleteCollection', 'updateCollection', 'updateCurrentCollection', 'setActiveTab', 'updateInfoPopup']),
      getTitle(title) {
        if (title.length > 15)
          return title.slice(0, 15).concat('...')
        else
          return title
      },
      goToInnerView(collection) {
        this.$router.path = '/'
        this.$router.push({name: 'collection.inner', params: {collectionId: collection.uuid}})
        this.setActiveTab('collection')
        this.updateCurrentCollection(collection)
      },
      goToInnerViewResponsive(collection) {
        this.$router.path = '/'
        this.$router.push({name: 'collection.inner.responsive', params: {collectionId: collection.uuid}})
        this.setActiveTab('collection')
        this.updateCurrentCollection(collection)
      },
      onDeleteCollection(uuid) {
        this.deleteCollection(uuid)
        this.updateInfoPopup({show: true, text: 'Collection deleted'})
      }
    },
  }
</script>
<style lang="scss" scoped>
  .picture-res {
    display: none;
  }

  .collection-back {
    z-index: -1;
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .gif-container {
    position: relative;
    width: 100%;
    height: 100%;

    .no-items {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: auto
    }

    .gif {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    }

    .top-settings {
      position: absolute;
      top: 0;
      padding: 0.5rem 0 0 0.5rem;
      width: 100%;
      justify-content: space-between;
      display: flex;
      align-items: center;

      .title {
        font-family: "Open Sans", sans-serif;
        font-size: 19px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        color: #ffffff;
      }

      img {
        cursor: pointer;
        width: 25px;
      }

      svg {
        cursor: pointer;
        color: #ffffff;
        font-size: 2rem;
      }


      /deep/ .dropdown-menu.show {
        transform: translate3d(0px, 64px, 0px) !important;
        top: -3em !important;
        left: -9em !important;
        will-change: transform !important;
        margin: 1em;
        padding: 1em;
        border-radius: 12px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;

        .dropdown-item,
        .dropdown-header {
          text-align: center;
          font-family: "Open Sans", sans-serif;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.5;
          letter-spacing: normal;
          color: #1a1a1a;
          padding: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    .gif-container {
      .top-settings {
        img {
          width: 20px;
        }

        .title {
          font-size: 18px;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .gif-container {
      .top-settings {
        padding: 0.5rem 0 0 0.5rem;

        /deep/ .btn {
          padding: 0;
        }

        img {
          width: 17px;
        }

        .title {
          font-size: 15px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .gif-container {
      .top-settings {
        img {
          width: 15px;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .gif-container {
      .top-settings {
        img {
          width: 30px;
        }
      }
    }
  }

  @media only screen and (max-width: 428px) {
    .picture-res {
      display: flex;
      height: 100%;
    }
    .picture {
      display: none;
    }
    .gif-container {
      .top-settings {
        img {
          width: 30px;
        }
      }
    }
  }

</style>
