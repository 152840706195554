class FileHelper {
    constructor() {
        // Do stuff
    }

    async downloadFile(url, filename, onSuccessCallback, onFailCallback) {
        const file = await fetch(url);
        if (file.status === 200) {
            const fileBlob = await file.blob();
            const fileURL = URL.createObjectURL(fileBlob);

            const anchor = document.createElement("a");
            anchor.href = fileURL;
            anchor.download = filename;

            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            if (onSuccessCallback) {
                onSuccessCallback()
            }
        } else {
            if (onFailCallback) {
                onFailCallback()
            }
        }
    }
}

// Usage:
// this.$fileHelper.downloadFile(url, filename');
export default {
    install(Vue) {
        Vue.prototype.$fileHelper = new FileHelper();
    },
};
