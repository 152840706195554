<template>
  <div>
    <responsive-header title="Documentation" @backClick="goToBack()"></responsive-header>
    <div class="documentation">
      <section-title secondary="and help" main="Documentation" class="not-for-mobile"/>
      <div class="row mb-5 cont">
        <div class="col-md-5 col-sm-12 ">
<!--          <div class="position-relative search w-100 mb-4">-->
<!--            <input type="text" placeholder="Search documentation">-->
<!--            <img class="position-absolute search-icon" src="../assets/icons/search.svg" alt="search"/>-->
<!--          </div>-->
          <div class="general-frame menu">
            <router-link :to="{ name:'documentation.about' }">
              <div @click="setActiveTab('about')" :class="activeTab==='about'? 'active' : ''">About us</div>
            </router-link>
            <router-link :to="{ name:'documentation.privacy'}">
              <div @click="setActiveTab('privacy')" :class="activeTab==='privacy'? 'active' : ''">Privacy Policy</div>
            </router-link>
            <router-link :to="{ name:'documentation.terms' }">
              <div @click="setActiveTab('terms')" :class="activeTab==='terms'? 'active' : ''">User Terms of Service
              </div>
            </router-link>
            <router-link :to="{ name:'documentation.dmca'}">
              <div @click="setActiveTab('dmca')" :class="activeTab==='dmca'? 'active' : ''">DMCA Copyright Policy</div>
            </router-link>
            <router-link :to="{ name:'documentation.community_guidelines'}">
              <div @click="setActiveTab('community_guidelines')"
                   :class="activeTab==='community_guidelines'? 'active' : ''">Community
                Guidelines
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-md-7 col-sm-12 general-frame document p-4">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import SectionTitle from "./widgets/SectionTitle";
  import {mapActions, mapState} from "vuex";
  import ResponsiveHeader from "./MobileDeviceComponents/ResponsiveHeader";

  export default {
    name: "Documentation",
    metaInfo() {
      return {
        title: 'Documentation',
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `Documentation of KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`
          },
        ],
      };
    },
    components: {ResponsiveHeader, SectionTitle},
    computed: {
      ...mapState(['activeTab'])
    },
    mounted() {
      this.updateHideHeader(true)

    },
    beforeDestroy() {
      this.updateHideHeader(false)
    },
    methods: {
      ...mapActions(['setActiveTab', 'updateHideHeader']),
      goToBack() {
        this.$router.go(-1)
      }
    },

  }
</script>

<style lang="scss" scoped>

  .documentation {
    margin-top: 50px;
  }

  .menu {
    min-height: calc(100vh - 650px);
    padding: 2rem;
    font-size: 14px;
    display: block;
  }

  .search {
    font-size: 14px;

    img {
      cursor: pointer;
      right: 28px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  @media only screen and (max-width: 428px) {
    .cont {
      margin-right: 0;
      margin-left: 0;
      margin-top: 64px;
    }

    .documentation {

      .menu {
        margin: 0 -15px 10px -15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: unset;
        padding: 1rem;
        overflow: auto;

        /deep/ a {
          white-space: nowrap;
          margin-right: 10px;
        }

        div {
          margin-bottom: 0;
        }
      }

      .item {
        margin-top: 0;
      }
    }
  }

</style>