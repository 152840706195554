<template>
  <transition name="fade">
    <div v-if="infoPopup.show" class="info-modal" :style="{background: profileColor}" ref="copy">
      <div class="text">
        {{ infoPopup.text }}
      </div>
    </div>
  </transition>
</template>

<script>
  import {mapActions, mapState} from "vuex";

  export default {
    name: "InfoPopup",
    props: {
      hidePopup: {
        type: Function
      }
    },
    data() {
      return {
        show: ''
      }
    },
    computed: {
      ...mapState(['infoPopup', 'profileColor'])
    },
    methods: {
      ...mapActions(['updateInfoPopup','showOverlay','hideOverlay'])
    },
    watch: {
      'infoPopup.show': {
        handler() {
          if (this.infoPopup.show) {
            this.showOverlay()
            setTimeout(() => {
              this.updateInfoPopup({show: false})
              this.hideOverlay()
            }, 2000);
          }
        }

      }
    },
  }
</script>

<style lang="scss" scoped>
  .info-modal {
    position: fixed;
    width: 25%;
    padding: 10px;
    border-radius: 32px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    text-align: center;
    z-index: 10;
    font-size: 14px;
  }

  @media only screen and (max-width: 428px) {
    .info-modal {
      width: 70%;
      top: 50%;
    }
  }

</style>