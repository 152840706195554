var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"hide-header":"","hide-footer":""},on:{"show":_vm.onShow},model:{value:(_vm.authModal.show),callback:function ($$v) {_vm.$set(_vm.authModal, "show", $$v)},expression:"authModal.show"}},[_c('div',{staticClass:"auth-modal"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"close-btn",on:{"click":function($event){return _vm.hideAuthModal()}}},[_c('b-icon',{staticClass:"close-btn-icon",attrs:{"icon":"x"}})],1),_c('div',{staticClass:"d-flex text-center"},[_c('div',{class:_vm.activeTab === 'signIn' ? 'tab active' : 'tab',on:{"click":function($event){return _vm.changeActiveTab('signIn')}}},[_vm._v("SIGN IN")]),_c('div',{class:_vm.activeTab === 'signUp' ? 'tab active' : 'tab',on:{"click":function($event){return _vm.changeActiveTab('signUp')}}},[_vm._v("REGISTER ")])]),(_vm.activeTab === 'signIn')?_c('b-form',{staticClass:"login-form",on:{"submit":function($event){$event.preventDefault();return _vm.onLoginClick()}}},[_c('label',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.loginError))]),_c('validation-provider',{staticClass:"input-widget",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userName),expression:"userName"}],attrs:{"type":"text","placeholder":"Username"},domProps:{"value":(_vm.userName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.userName=$event.target.value}}}),(!_vm.fieldErrors.length)?_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.userNameErrorMsg))])]}}],null,false,1860612404)}),_c('validation-provider',{staticClass:"input-widget",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"name":"password","type":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(!_vm.fieldErrors.length)?_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.passwordErrorMsg))])]}}],null,false,1695841816)}),_c('b-button',{staticClass:"login-btn btn-hover",attrs:{"type":"submit"}},[(_vm.isLoading)?_c('b-spinner'):_c('span',[_c('img',{attrs:{"src":require("../assets/icons/user.svg"),"alt":"user"}}),_vm._v("Log in")])],1),_c('div',{staticClass:"forgot-password-text btn-hover"},[_vm._v("Forgot password?")])],1):_c('b-form',{staticClass:"login-form",on:{"submit":function($event){$event.preventDefault();return _vm.onRegisterClick()}}},[_c('label',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.loginError))]),_c('validation-provider',{staticClass:"input-widget",attrs:{"rules":!_vm.userName ? 'required|email' : 'email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"name":"email","type":"email","placeholder":"E-mail"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),(!_vm.fieldErrors.length)?_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.emailErrorMsg))])]}}])}),_c('validation-provider',{staticClass:"input-widget",attrs:{"rules":!_vm.email ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userName),expression:"userName"}],attrs:{"name":"userName","type":"text","placeholder":"Username"},domProps:{"value":(_vm.userName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.userName=$event.target.value}}}),(!_vm.fieldErrors.length)?_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.userNameErrorMsg))])]}}])}),_c('validation-provider',{staticClass:"input-widget",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"name":"password","type":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(!_vm.fieldErrors.length)?_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.passwordErrorMsg))])]}}])}),_c('div',{staticClass:"accept-terms d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.acceptTerms),expression:"acceptTerms"}],staticClass:"mr-4",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.acceptTerms)?_vm._i(_vm.acceptTerms,null)>-1:(_vm.acceptTerms)},on:{"change":function($event){var $$a=_vm.acceptTerms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.acceptTerms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.acceptTerms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.acceptTerms=$$c}}}}),_c('label',{class:_vm.accepted ? '' : 'error-msg'},[_vm._v("I accept terms and conditions")])]),_c('b-button',{staticClass:"login-btn btn-hover",attrs:{"type":"submit"}},[(_vm.isLoading)?_c('b-spinner'):_c('span',[_vm._v("Register")])],1)],1),_c('div',{staticClass:"auth-modal-footer d-flex align-items-center justify-content-center"},[_c('img',{staticClass:"btn-hover",attrs:{"src":require("../assets/icons/facebook_colored.svg"),"alt":"facebook"},on:{"click":_vm.fbLogin}}),_c('div',{attrs:{"id":"google-auth-button"}})])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }