<template>
  <div class="pb-4">
    <responsive-header title="Gif" @backClick="goToBack()"></responsive-header>
    <div class="gif-preview-container general-frame">
      <div class="left-column">
        <div class="video-container">
          <video :muted="isVideoMuted" loop class="video" ref="video" v-show="videoUrl"
                 :key="memeToLoad? memeToLoad.uuid : ''" autoplay
                 playsinline>
            <source :src="videoUrl" type="video/mp4"/>
          </video>
          <div class="play-icon-container" @click="toggleVideo"
               ref="playIcon">
            <div v-if="error">
              <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
              <span class="text-danger ml-2">{{ error }}</span>
            </div>
            <div v-else>
              <transition name="fade">
                <div class="play-icon">
                  <b-icon v-if="isVideoMuted" icon="volume-mute-fill" :class="animateVolumeIcon? 'animated-audio' : '' "
                          style="color: #ffffff; width: 1.5rem; height: 1.5rem"></b-icon>
                  <b-icon v-else icon="volume-up-fill"
                          style="color: #ffffff; width: 1.5rem; height: 1.5rem"></b-icon>
                </div>
              </transition>
            </div>
          </div>
          <div class="link-on-video">
            <div class="copy-link-on-video">
              <input type="text" v-model="memeUrl" ref="memeUrl">
              <div @click="copyURL" id="copy-btn" class="icon-container">
                <Sprite symbol="icon-copy" class="icon"/>
              </div>
              <b-tooltip target="copy-btn" placement="top"><b>Copy URL</b></b-tooltip>
              <div class="pipe-delimiter"></div>
              <div @click="downloadVideo" class="icon-container" id="download-btn">
                <Sprite symbol="icon-download" class="icon"/>
              </div>
              <b-tooltip target="download-btn" placement="top"><b>Download Video</b></b-tooltip>
            </div>
          </div>
        </div>
        <div class="info-icons-res">
          <div class="info-icon" @click="onReactClick()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" v-if="reacted" class="cameleon-icon">
              <path fill="none" d="M0 0H20V20H0z"/>
              <path :fill="profileColor"
                    d="M-29.205 915.072a4.446 4.446 0 0 0-1.736-1.671 3.988 3.988 0 0 0-2.526-.485 4.307 4.307 0 0 0-2.331 1.148l-.245.232a.594.594 0 0 0-.056-.067 4.153 4.153 0 0 0-3.226-1.333 4.05 4.05 0 0 0-2.831 1.28 4.5 4.5 0 0 0-1.263 3.774 4.569 4.569 0 0 0 1.3 2.709c1.6 1.668 2.821 2.955 4.24 4.445l1.016 1.066.066.072a1.025 1.025 0 0 0 .748.339h.012a1.024 1.024 0 0 0 .743-.321l.13-.136 1.54-1.615 3.717-3.9a4.447 4.447 0 0 0 1.286-3.133 4.75 4.75 0 0 0-.584-2.404z"
                    transform="translate(46.035 -908.723)"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="cameleon-icon">
              <path fill="none" d="M0 0H20V20H0z"/>
              <path fill="#1a1a1a"
                    d="M-29.205 915.072a4.446 4.446 0 0 0-1.736-1.671 3.988 3.988 0 0 0-2.526-.485 4.307 4.307 0 0 0-2.331 1.148l-.245.232a.594.594 0 0 0-.056-.067 4.153 4.153 0 0 0-3.226-1.333 4.05 4.05 0 0 0-2.831 1.28 4.5 4.5 0 0 0-1.263 3.774 4.569 4.569 0 0 0 1.3 2.709c1.6 1.668 2.821 2.955 4.24 4.445l1.016 1.066.066.072a1.025 1.025 0 0 0 .748.339h.012a1.024 1.024 0 0 0 .743-.321l.13-.136 1.54-1.615 3.717-3.9a4.447 4.447 0 0 0 1.286-3.133 4.75 4.75 0 0 0-.584-2.404z"
                    transform="translate(46.035 -908.723)"/>
            </svg>
            {{ totalReactions }}
          </div>
          <div class="info-icon" @click="onViewCommentsClick()">
            <img class="mr-2" src="../assets/icons/comment_dark.svg"
                 alt="comment"/>{{ meme && meme.total_comments ? meme.total_comments : 0 }}
          </div>
          <div class="info-icon">
            <img class="mr-2" src="../assets/icons/flag.svg" @click="showCollectionsList()"
                 alt="flag"/>{{ meme && meme.total_collections ? meme.total_collections : 0 }}
            <add-to-collection-modal :is-mine="isMine" :meme="meme" :show-collections="showCollections"
                                     @onHideCollectionsList="hideCollectionsList"/>
          </div>
          <div class="info-icon" id="share-btn" @click="copyShareLink(meme.slug)">
            <img class="mr-2" src="../assets/icons/share.svg" alt="share"/>
            {{ meme && meme.total_shares ? meme.total_shares : 0 }}
          </div>
          <div class="info-icon"><img class="mr-2" src="../assets/icons/eye.svg"
                                      alt="view"/>{{ meme && meme.total_views ? meme.total_views : 0 }}
          </div>

        </div>

        <div class="avatar">
          <div v-if="meme" class="d-flex align-items-center">
            <b-avatar badge-variant="info" :src="meme.author.avatar_url">
            </b-avatar>
            <router-link :to="{name:'user', params:{id: meme.author.username}}">
              <span class="ml-3 username">@{{ meme ? meme.author.username : '' }}</span>
            </router-link>
          </div>

          <div class="date">{{ meme ? formatDate(meme.created_at) : '' }}</div>
        </div>
        <div class="description">
          <h1 class="title">{{ meme ? meme.title : '' }}</h1>
          <h2 class="description">{{
              (meme && meme.description ? meme.description : '') + (meme && meme.source && meme.description ? ' - ' : '') + (meme && meme.source ? meme.source : '')
            }}</h2>
        </div>
        <div class="tags" v-if="meme">
          <router-link v-for="(tag,index) in meme.tags" :key="`tag_${index}`"
                       :to="{name: 'tag.view', params: {'tag': tag}}">
            <div class="tag btn-hover">#{{ tag }}
            </div>
          </router-link>
        </div>
      </div>
      <div class="right-column">
        <div class="info-icons">
          <div class="info-icon" @click="onReactClick()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" v-if="reacted" class="cameleon-icon">
              <path fill="none" d="M0 0H20V20H0z"/>
              <path :fill="profileColor"
                    d="M-29.205 915.072a4.446 4.446 0 0 0-1.736-1.671 3.988 3.988 0 0 0-2.526-.485 4.307 4.307 0 0 0-2.331 1.148l-.245.232a.594.594 0 0 0-.056-.067 4.153 4.153 0 0 0-3.226-1.333 4.05 4.05 0 0 0-2.831 1.28 4.5 4.5 0 0 0-1.263 3.774 4.569 4.569 0 0 0 1.3 2.709c1.6 1.668 2.821 2.955 4.24 4.445l1.016 1.066.066.072a1.025 1.025 0 0 0 .748.339h.012a1.024 1.024 0 0 0 .743-.321l.13-.136 1.54-1.615 3.717-3.9a4.447 4.447 0 0 0 1.286-3.133 4.75 4.75 0 0 0-.584-2.404z"
                    transform="translate(46.035 -908.723)"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="cameleon-icon">
              <path fill="none" d="M0 0H20V20H0z"/>
              <path fill="#1a1a1a"
                    d="M-29.205 915.072a4.446 4.446 0 0 0-1.736-1.671 3.988 3.988 0 0 0-2.526-.485 4.307 4.307 0 0 0-2.331 1.148l-.245.232a.594.594 0 0 0-.056-.067 4.153 4.153 0 0 0-3.226-1.333 4.05 4.05 0 0 0-2.831 1.28 4.5 4.5 0 0 0-1.263 3.774 4.569 4.569 0 0 0 1.3 2.709c1.6 1.668 2.821 2.955 4.24 4.445l1.016 1.066.066.072a1.025 1.025 0 0 0 .748.339h.012a1.024 1.024 0 0 0 .743-.321l.13-.136 1.54-1.615 3.717-3.9a4.447 4.447 0 0 0 1.286-3.133 4.75 4.75 0 0 0-.584-2.404z"
                    transform="translate(46.035 -908.723)"/>
            </svg>
            {{ totalReactions }}
          </div>
          <div class="info-icon">
            <img class="mr-2" src="../assets/icons/comment_dark.svg"
                 alt="comment"/>{{ meme && meme.total_comments ? meme.total_comments : 0 }}
          </div>
          <div class="info-icon position-relative">
            <img class="mr-2" src="../assets/icons/flag.svg" @click="showCollectionsList()"
                 alt="flag"/>{{ meme && meme.total_collections ? meme.total_collections : 0 }}

            <add-to-collection-modal :is-mine="isMine" :meme="meme" :show-collections="showCollections"
                                     @onHideCollectionsList="hideCollectionsList"/>
          </div>
          <div class="info-icon" id="share-btn-res" @click="copyShareLink(meme.slug)">
            <img class="mr-2" src="../assets/icons/share.svg" alt="share"/>
            {{ meme && meme.total_shares ? meme.total_shares : 0 }}
          </div>
          <div class="info-icon"><img class="mr-2" src="../assets/icons/eye.svg"
                                      alt="view"/>{{ meme && meme.total_views ? meme.total_views : 0 }}
          </div>

        </div>
        <div class="position-relative">
          <div :class="comments.data.length ? 'comments-height comments':'comments'" ref="comments">
            <Comment v-for="(comment, index) in comments.data" :key="`comments_${index}`" :data="comment"
                     @onEdit="editComment"/>
            <div class="no-comments" v-if="!comments.data.length">
              <img src="../assets/icons/no_comments.png" alt="no_comment"/>
              <div>
                No comments yet.
              </div>
              <div>Wanna add one?</div>
            </div>
          </div>
          <div class="fader" v-if="comments.data.length"></div>
        </div>
        <form @submit.prevent=onCommentSubmit v-if="currentUser">
          <div class="comment-input-container d-flex justify-content-between">
            <div class="comment-input">
              <input type="text" placeholder="Type comment here..." v-model="comment" name="comment" autocomplete="off"
                     ref="comment"/>
            </div>
            <button type="submit" class="send-btn btn-hover">
              <b-icon icon="play-fill" style="color: #ffffff; width:2rem; height: 2rem"></b-icon>
            </button>
          </div>
          <div class="error-msg">{{ commentError }}</div>
        </form>
        <div class="log-in-to-comment" v-if="!currentUser">
          To add comment, please
          <br>
          <span @click="openAuthModal('signIn')" class="link">Log in</span> or <span
            @click="openAuthModal('signUp')" class="link">Register</span>
        </div>
      </div>
    </div>
    <section-title main="related" size="md" class="related-gifs-title"/>
    <trending :key="`${$route.params.id}`" :is-related='true'
              :related-meme-uuid="$route.params.id"/>
    <div class="comments-popup" v-if="commentsPopupShow">
      <b-icon icon="x" class="close-btn" @click="onHideCommentsClick()"></b-icon>
      <div class="comments position-relative" ref="commentsRes">
        <Comment v-for="(comment, index) in comments.data" :key="`comments_${index}`" :data="comment"
                 @onEdit="editComment"/>
        <div class="no-comments" v-if="!comments.data.length">
          <img src="../assets/icons/no_comments.png" alt="no_comment"/>
          <div>
            No comments yet.
          </div>
          <div>Wanna add one?</div>
        </div>
      </div>
      <form @submit.prevent=onCommentSubmit v-if="currentUser">
        <div class="comment-input-container d-flex justify-content-between">
          <div class="comment-input">
            <input type="text" placeholder="Type comment here..." v-model="comment" name="comment" autocomplete="off"/>
          </div>
          <button type="submit" class="send-btn btn-hover">
            <b-icon icon="play-fill" style="color: #ffffff; width:2rem; height: 2rem"></b-icon>
          </button>
        </div>
        <div class="error-msg" v-if="commentError">{{ commentError }}</div>
      </form>
      <div class="log-in-to-comment" v-if="!currentUser">
        To add comment, please
        <br>
        <span @click="openAuthModal('signIn')" class="link">Log in</span> or <span
          @click="openAuthModal('signUp')" class="link">Register</span>
      </div>
    </div>
    <!--    <info-popup :show-popup="showPopup" @afterHidden="showPopup=false"></info-popup>-->
  </div>
</template>

<script>
import Comment from "./widgets/Comment";
import {mapActions, mapState} from "vuex";
import Trending from "./widgets/Trending";
import ResponsiveHeader from "./MobileDeviceComponents/ResponsiveHeader";
import AddToCollectionModal from "./widgets/AddToCollectionModal";
import SectionTitle from "./widgets/SectionTitle";
import Sprite from "./widgets/Sprite";

export default {
  name: "GifView",
  components: {Sprite, SectionTitle, AddToCollectionModal, ResponsiveHeader, Trending, Comment},
  data() {
    return {
      meme: null,
      memeToLoad: null,
      error: null,
      videoPlaying: true,
      comment: '',
      commentError: null,
      memeUrl: '',
      showIconPopover: false,
      videoUrl: '',
      totalReactions: 0,
      reacted: false,
      loading: false,
      commentsPopupShow: false,
      showCollections: false,
      isMine: false,
      prevRoute: '',
      editing: false,
      commentBeingEdited: null,
      onPurposePause: false,
      lowVolume: false,
      animateVolumeIcon: false,
      isVideoMuted: false,
    }
  },
  jsonld() {
    let result = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      // 'name': ',
      // 'description': '',
      // 'thumbnailUrl': '',
      // 'uploadDate': '2015-04-05T08:00:00+02:00',
      // 'duration': 'PT1M33S', // TODO
      // 'contentUrl': 'http://www.example.com/movie.movs',
      // 'embedUrl': 'http://www.example.com/embed?videoetc',
      // "author": {
      //   "@type": "Person",
      //   "name": "Jason Lord"
      // }
    }

    if (this.meme && this.meme.title) {
      result['name'] = this.meme.title
      result['description'] = this.meme.description ? this.meme.description : `Share ${this.meme.title} Clip from KIKLIKO. Discover your favorite moments from Movies, Shows and Random Videos to make your conversations more positive and fun.`

      if (this.meme.created_at) {
        result['uploadDate'] = `${this.meme.created_at.replace(' ', 'T')}+04:00`
      }

      if (this.meme.file && this.meme.file.thumbnail_url_webp) {
        result['thumbnailUrl'] = this.meme.file.thumbnail_url_webp
      } else if (this.meme.file && this.meme.file.thumbnail_url) {
        result['thumbnailUrl'] = this.meme.file.thumbnail_url
      }

      if (this.meme.file && this.meme.file.watermarked_url) {
        result['contentUrl'] = this.meme.file.watermarked_url
      }

      if (this.meme.author && this.meme.author.username) {
        result['author'] = {
          '@type': 'Person',
          'name': this.meme.author.username
        }
      }
    }

    if (this.$route.params.id) {
      result['embedUrl'] = this.$helper.apiUrl(`/embed/${this.$route.params.id}`, false)
    }

    return result;
  },
  metaInfo() {
    if (!this.meme || !this.meme.slug || !this.meme.title) {
      return [];
    }
    let title = this.meme.title ? this.meme.title : null;
    let description = this.meme.description ? this.meme.description : `${this.meme.title}`
    let meta = [
      {
        vmid: 'description',
        name: 'description',
        content: `Share ${this.meme.title} Clip from KIKLIKO. Discover your favorite moments from Movies, Shows and Random Videos to make your conversations more positive and fun.`,
      },
      {
        property: 'og:url',
        vmid: 'og:url',
        content: this.$helper.getMemeUrl(this.meme.slug),
      },
      {
        property: 'og:type',
        vmid: 'og:type',
        content: 'video.other',
      },
      {
        property: 'og:title',
        vmid: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        vmid: 'og:description',
        content: description,
      },
    ];

    if (this.meme.file && this.meme.file.thumbnail_url) {
      meta.push({
        property: 'og:image',
        vmid: 'og:image',
        content: this.meme.file.thumbnail_url,
      })
    }

    if (this.meme.file && this.meme.file.watermarked_url) {
      meta.push({
        property: 'og:video',
        vmid: 'og:video',
        content: this.meme.file.watermarked_url,
      })
      meta.push({
        property: 'og:video:url',
        vmid: 'og:video:url',
        content: this.meme.file.watermarked_url,
      })
      meta.push({
        property: 'og:video:secure_url',
        vmid: 'og:video:secure_url',
        content: this.meme.file.watermarked_url,
      })
    }

    return {
      title: title ? title : null,
      meta: meta,
      link: [
        {
          rel: 'canonical',
          href: this.$helper.getMemeUrl(this.meme.slug),
        },
      ],
    };
  },
  beforeMount() {
    this.getMemeData();
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 0)
      let el = this.$refs.commentsRes ? this.$refs.commentsRes : this.$refs.comments
      el.addEventListener('scroll', this.loadCommentsHandler)
      window.addEventListener('scroll', this.pauseIfNotInViewPort)
      this.memeUrl = window.location.href;
      this.updateHideHeader(true)
    })

  },
  beforeDestroy() {
    let el = this.$refs.commentsRes ? this.$refs.commentsRes : this.$refs.comments
    el.removeEventListener('scroll', this.loadCommentsHandler);
    window.removeEventListener('scroll', this.pauseIfNotInViewPort);
    this.clearComments();
    this.updateHideHeader(false)
  },

  computed: {
    ...mapState({
      comments: state => state.comments,
      currentUser: state => state.currentUser,
      tag: state => state.filter.tag,
      profileColor: state => state.profileColor,
      overlay: state => state.showOverlay,
      isMuted: state => state.isMuted
    }),
  },
  watch: {
    'videoUrl': {
      handler() {
        if (this.videoUrl) {
          let vid = this.$refs.video
          vid.play().catch(() => {
          })
          if (vid.paused) {
            this.animateVolumeIcon = true
            vid.muted = true;
            this.isVideoMuted = true
            vid.play().catch(() => {
            })
          } else {
            this.isVideoMuted = false
          }
        }
      }
    },
    'memeToLoad': {
      deep: true,
      handler() {
        if (this.memeToLoad) {
          this.meme = this.memeToLoad
          this.totalReactions = this.meme.total_reactions;
          this.reacted = this.meme.liked;
          this.loadComments();
        }
      }
    },
    $route() {
      this.memeUrl = window.location.href;
      if (this.$attrs.id) {
        this.getMemeData();
        this.clearComments();
      }
    },
    currentUser() {
      if (this.currentUser && this.meme) {
        this.isMine = this.meme.author.username === this.currentUser.username;
      }
    },
  },
  methods: {
    ...mapActions([
      'getMeme',
      'getComments',
      'addComment',
      'clearComments',
      'updateFilter',
      'removeReaction',
      'addReaction',
      'openAuthModal',
      'updateHideHeader',
      'showOverlay',
      "hideOverlay",
      'updateComment',
      'updateInfoPopup',
      'shareCounterRequest',
      'increaseDownload',
      'updateShareModal']),
    async getMemeData() {
      this.error = null;
      let response = await this.getMeme(this.$route.params.id)
      if (response.result) {
        this.memeToLoad = response.data
        this.videoUrl = response.data.file.url
      } else {
        this.error = response.errors.message[0]
      }
      this.loadComments();
    },
    loadCommentsHandler() {
      let commentsContainer = this.$refs.commentsRes ? this.$refs.commentsRes : this.$refs.comments

      if (commentsContainer.offsetHeight + commentsContainer.scrollTop >= commentsContainer.scrollHeight) {
        this.loadComments(this.comments.current_page + 1, this.comments.per_page);
      }

    },
    async loadComments(page, per_page) {
      if (this.isLoading) {
        return
      }
      if ((this.comments.total === this.comments.data.length) && this.comments.total !== 0) {
        return
      }

      this.isLoading = true;
      let res = await this.getComments({page, per_page, id: this.memeToLoad.uuid})
      if (res.result) {
        this.isLoading = false;
      } else {
        this.error = 'error!'
      }
    },
    toggleVideo() {
      this.isVideoMuted = !this.isVideoMuted
      this.animateVolumeIcon = false
      if (this.$refs.video) {
        this.$refs.video.muted = this.isVideoMuted;
        this.onPurposePause = this.$refs.video.muted === true;
      }
    },
    async onCommentSubmit() {
      this.commentError = null;

      if (this.loading) {
        return
      }
      this.loading = true;
      let response;
      if (this.editing) {
        response = await this.updateComment(
            {
              data: {comment: this.comment},
              uuid: this.commentBeingEdited
            }
        )
        this.editing = false;
      } else {
        response = await this.addComment(
            {
              model: "voicememe",
              id: this.meme.uuid,
              comment: this.comment
            }
        )
        if (!response.result) {
          this.commentError = response.errors.message
        }
      }
      if (response.result && !this.editing && !isNaN(this.meme.total_comments)) {
        this.meme.total_comments = parseInt(this.meme.total_comments) + 1
      }
      this.comment = '';
      this.loading = false;
    },
    async copyURL($event) {
      await this.shareCounterRequest(this.meme.uuid)
      $event.stopPropagation()
      let Url = this.$refs.memeUrl;
      Url.select();
      document.execCommand("copy");
      this.updateInfoPopup({show: true})
    },
    async downloadVideo($event) {
      $event.stopPropagation()

      this.$fileHelper.downloadFile(
          this.$helper.apiUrl(`/web/download-with-watermark/${this.meme.file.uuid}`),
          this.meme.title + '.mp4',
          () => {
            this.increaseDownload(this.meme.slug)
          }
      )

    },
    async onReactClick() {
      if (!this.currentUser) {
        this.openAuthModal()
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true;
      let response;
      let data = {
        model: "voicememe",
        id: this.meme.uuid,
      }
      if (this.reacted) {
        if (!isNaN(this.totalReactions)) {
          this.totalReactions = parseInt(this.totalReactions) - 1;
        }
        response = await this.removeReaction(data)
      } else {
        if (!isNaN(this.totalReactions)) {
          this.totalReactions = parseInt(this.totalReactions) + 1;
        }
        response = await this.addReaction({...data, type: "like"})
      }
      this.reacted = !this.reacted;
      if (!response.result) {
        this.error = response.errors.message[0]
      }
      this.loading = false;

    },
    async copyShareLink(link) {
      this.shareCounterRequest(this.meme.uuid)
      let url = this.$helper.getMemeUrl(link);
      this.updateShareModal({show: true, link: url, uuid: this.meme.uuid, meme: this.meme});
    },
    onViewCommentsClick() {
      this.commentsPopupShow = true;
      this.showOverlay()
    },
    onHideCommentsClick() {
      this.commentsPopupShow = false;
      this.hideOverlay()
    },
    showCollectionsList() {
      if (!this.currentUser) {
        this.openAuthModal()
        return
      }
      this.showCollections = true;
      this.showOverlay();
    },
    hideCollectionsList() {
      this.showCollections = false;
      this.hideOverlay();
    },
    pauseIfNotInViewPort() {
      if (this.onPurposePause) {
        return
      }
      const rect = this.$refs.video ? this.$refs.video.getBoundingClientRect() : null;
      if (rect) {
        this.$refs.video.muted = rect.bottom < 0;
      }
    },
    goToBack() {
      this.$router.path = '/';
      this.$router.push({name: 'main'});
    },
    editComment(commentUuid) {
      this.commentBeingEdited = commentUuid;
      this.editing = true;
      this.$refs.comment.focus();
      this.comment = this.comments.data.filter(c => c.uuid === commentUuid)[0].comment
    },
    formatDate(date) {
      date = new Date(date.replace(/-/g, "/"));
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()

      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return `${day}-${month}-${year}`
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.name
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../style";

.comments-popup {
  display: none;
}

.gif-preview-container {
  display: flex;
  padding: 40px;

  .left-column {
    display: flex;
    flex-direction: column;
    width: 700px;

    .info-icons-res {
      display: none;
    }
  }

  .right-column {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    width: calc(100% - 700px);
  }

  .video-container {
    position: relative;
    height: 400px;

    .video {
      width: 100%;
      height: 100%;
      background: black;
    }

    .play-icon-container {
      margin: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: right;

      .play-icon {
        padding: 10px;
      }
    }

    .link-on-video {
      //width: 42%;
      position: absolute;
      bottom: 20px;
      right: 20px;

      .copy-link-on-video {
        display: flex;
        align-items: center;
        border-radius: 32px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #d8d8d8;
        background-color: #ffffff;
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        font-weight: 100;
        line-height: 1.2;
        color: #1a1a1a;
        cursor: pointer;
        padding: 0 15px;

        .icon-container {
          max-width: 30px;
          max-height: 30px;

          .icon {
            max-height: 30px;
            padding: 5px 0;
          }
        }

        img {
          width: 25px;
          margin: 2px auto;
        }

        input {
          box-shadow: unset;
          border: none;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: 0;
          opacity: 0;
        }

        div {
          &.pipe-delimiter {
            padding: 0 10px;
            margin: 0;

            &::before {
              content: "";
              display: inline;
              border-right: 2px solid #1a1a1a;
              border-radius: 32px 0 0 32px;
            }
          }
        }
      }
    }
  }

  .avatar {
    margin: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /deep/ .b-avatar {
      width: 40px;
      height: 40px;
    }

    .username {
      font-family: "Exo 2", sans-serif;
      font-size: 30px;
      font-weight: 900;
      line-height: 1.2;
      text-align: left;
      color: #1a1a1a;
    }

    /deep/ .b-avatar-badge {
      background: #ff9d00;
      z-index: 0;
    }

    .date {
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      font-weight: normal;
      line-height: 1.2;
      color: #d8d8d8;
    }
  }

  .description {
    width: 100%;
    overflow-wrap: break-word;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
    color: #696969;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    cursor: pointer;

    .tag {
      border-radius: 32px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
      background-color: #ffffff;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      font-weight: normal;
      line-height: 2;
      color: #ff9d00;
      padding: 0 1rem;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }
  }


  .info-icons {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 1.2rem;
    color: #696969;
    display: flex;
    justify-content: space-between;
    font-size: 11px;

    .info-icon {
      cursor: pointer;

      img,
      .cameleon-icon {
        width: 25px;
        height: auto;
      }
    }
  }


  .fader {
    bottom: 0;
    height: 8em;
    background: linear-gradient(
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
    );
    position: absolute;
    width: 100%;
  }

  .comment-input-container {
    padding: 2rem 0;

    .comment-input {
      width: 80%;

      input {
        margin-bottom: 0;
        width: 100%;
        height: 100%;
      }
    }

    .send-btn {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
      background-color: #1a1a1a;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.log-in-to-comment {
  width: 100%;
  padding: 20px 68px;
  margin-top: 50px;
  border-radius: 32px;
  background: #f0f0f0;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #707070;

  .link {
    color: #0000ff;
    font-weight: 500;
    cursor: pointer;
  }
}

.comments {
  overflow: auto;
  border-bottom: 1px solid #f0f0f0;


  &.comments-height {
    height: 500px;
  }

  .no-comments {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 340px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #707070;
  }
}

@media only screen and (max-width: 1600px) {
  .log-in-to-comment {
    padding: 15px 30px;
    font-size: 14px;
  }

  .comments {
    &.comments-height {
      height: 350px;
    }

    .no-comments {
      min-height: 280px;
      font-size: 12px;

      img {
        width: 50%;
      }
    }
  }
  .gif-preview-container {
    padding: 20px;

    .video-container {
      .link {
        .copy-link {
          font-size: 16px;
          padding: 2px 0;

          input {
            padding: 0 10px
          }

          div {
            min-width: 140px;
            padding: 0 0 0 1rem;

            img {
              width: 25px;
            }
          }
        }
      }

    }


    .left-column {
      width: 580px;
    }

    .right-column {
      width: calc(100% - 600px);
    }

    .video-container {
      height: 350px;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .gif-preview-container {

    .left-column {
      width: 570px;
    }

    .right-column {
      width: calc(100% - 600px);
    }

    .video-container {
      height: 350px;

      .link {
        width: 40%;
        bottom: 70px;
        right: 20px;

        .copy-link {
          font-size: 12px;
          padding: 0;
          justify-content: space-between;

          img {
            width: 20px;
          }

          input {
            width: calc(100% - 130px);
          }

          div {
            padding: 0;
            min-width: 100px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .gif-preview-container {

    .avatar {
      .username {
        font-size: 30px;
      }

      /deep/ .b-avatar-badge {
        background: #ff9d00;
      }

      .date {
        font-size: 18px;
      }
    }

    .description,
    .tags .tag {
      font-size: 15px;
    }

    .right-column {
      width: calc(100% - 600px);
    }

    .video-container {
      height: 350px;

      .link {
        width: 60%;
        bottom: 70px;
        right: 20px;

        .copy-link {
          font-size: 12px;
          padding: 0;

          img {
            width: 20px;
          }

          input {
            width: calc(100% - 130px);
          }

          div {
            padding: 0;
            min-width: 140px;
          }
        }
      }
    }

    .info-icons {
      .info-icon {
        font-size: 12px;

        img,
        .cameleon-icon {
          width: 20px;
        }
      }
    }
  }
  .comments {
    height: 400px;
  }
}

@media only screen and (max-width: 992px) {
  .gif-preview-container {
    padding: 20px;

    .left-column {
      width: 450px;
    }

    .right-column {
      width: calc(100% - 490px);
    }

    .video-container {
      height: 300px;

    }

  }

  .comments {
    height: 350px;
  }
}

@media only screen and (max-width: 992px) {
  .gif-preview-container {
    flex-direction: column;

    .left-column {
      width: 100%;
    }

    .right-column {
      margin-top: 20px;
      margin-left: 0;
      width: 100%
    }

    .video-container {
      height: auto;

      .link {
        .copy-link {
          padding: 5px;

          img {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .comments {
    height: 350px;
  }
}

@media only screen and (max-width: 428px) {
  .link-on-video {
    display: none !important;
  }
  .general-frame {
    margin-bottom: 0;
    border-radius: 12px 12px 0 0;
    box-shadow: none;
  }
  .video-container {
    .link {
      display: none;
    }
  }

  .general-frame {
    padding-top: 0;
  }

  .video-container {
    .video {
      border-radius: 12px;
    }
  }
  .gif-preview-container {
    margin-top: 80px;

    .left-column {
      .info-icons-res {
        display: flex;
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 1.2rem;
        padding-top: 25px;
        color: #696969;
        justify-content: space-between;


        .info-icon {
          font-size: 11px;

          img,
          .cameleon-icon {
            width: 30px;
            height: auto;
          }
        }

      }
    }

    .right-column {
      display: none;
    }

    .avatar {
      .username {
        font-size: 20px;
      }

      /deep/ .b-avatar {
        width: 35px;
        height: 35px;
      }

      /deep/ .b-avatar-badge {
        width: 10px;
        height: 10px;
        min-height: 10px;
        min-width: 10px;

        svg {
          margin-left: -4px;
          margin-top: -4px;
        }
      }

    }
  }

  .description,
  .tags .tag {
    font-size: 14px;
  }

  .related-gifs-title {
    margin-bottom: 30px;
  }
  .comments-popup {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 4;
    background: #ffffff;
    left: 0;
    border-radius: 12px 12px 0 0;
    padding: 30px 30px 25px 30px;
    overflow: hidden;
    height: 60%;


    .comments {
      height: 80%;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .log-in-to-comment {
      margin-top: 20px;
    }
  }


  .fader {
    bottom: 120px;
    height: 8em;
    background: linear-gradient(
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
    );
    position: fixed;
    width: 100%;
    left: 0;
  }

  .comment-input-container {
    padding: 2rem 0 0 0;
    z-index: 5;

    .comment-input {
      width: 80%;
      height: 40px;

      input {
        margin-bottom: 0;
        width: 100%;
        height: 100%;
      }
    }

    .send-btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
      background-color: #1a1a1a;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .close-btn {
    float: right;
    font-weight: bold;
    width: 32px;
    height: 32px;
  }
}

h1.title {
  font-size: 20px;
}


</style>