<template>
  <div class="tab-content">
    <trending :key="currentUser ? currentUser.uuid : ''+ likedMemes?likedMemes.data.length:''" :data="likedMemes.data"/>
    <div class="d-flex justify-content-center mb-3 mt-3" v-if="isLoading">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>

  import Trending from "../widgets/Trending";
  import {mapActions, mapState} from "vuex";

  export default {
    name: "LikedGifsTab",
    // eslint-disable-next-line vue/no-unused-components
    components: {Trending},
    computed: {
      ...mapState(['currentUser', "likedMemes"])
    },
    data() {
      return {
        isMe: false,
        error: '',
        hasNext: true,
        isLoading: false,
      }
    },
    metaInfo() {
      return {
        title: `@${this.$route.params.id.toUpperCase()} Liked GIFs`,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `Find ${this.$route.params.id.toUpperCase()} Liked GIFs on KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`,
          },
        ],
      };
    },
    mounted() {
      window.addEventListener('scroll', this.loadLikedMemesHandler)
      this.loadLikedMemes();
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.loadLikedMemesHandler);
      this.clearLikedMemes();
    },
    methods: {
      ...mapActions(['getLikedMemes', 'clearLikedMemes']),
      async loadLikedMemes(page, per_page) {
        if (this.isLoading) {
          return
        }
        if (!this.hasNext) {
          return
        }
        this.error = ''
        this.isLoading = true;
        let res = await this.getLikedMemes({username: this.$route.params.id, page, per_page})
        if (res.result) {
          this.hasNext = res.data.has_next
        } else {
          this.error = res.errors.message[0];
        }
        this.isLoading = false;

      },
      loadLikedMemesHandler() {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (!this.hasNext) {
          return
        }
        if (bottomOfWindow && this.likedMemes.data.length) {
          this.loadLikedMemes(this.likedMemes.current_page + 1);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .grid-view {
    grid-template-columns: repeat(3, 1fr);
  }
</style>
