<template>
  <transition name="fade">
    <div class="add-to-collection-modal" v-if="showCollections">
      <b-icon icon="x" class="close-btn" @click="hideCollections()"></b-icon>
      <div class="modal-items">

        <div v-if="isMine" @click="onEditMemeClick(meme)" class="btn-hover edit-btn">Edit</div>
        <div v-if="isMine" class="error-msg delete-btn btn-hover" @click="onDeleteMemeClick(meme.uuid)">Delete</div>
        <div class="title">
          Add to collection
        </div>
        <div v-for="(collection, index) in collections" :key="`collection_${index}`"
             @click="onAddMemeToCollectionClick(collection,meme)">
          <div class="checkbox-container">
            <input type="checkbox" v-model="collection.checked"/>
            <div class="checkbox-label btn-hover">{{ collection.title }}</div>
          </div>
        </div>
        <div @click="showCollectionModal()">
          <button class="new-collection-btn">
            <b-icon icon="plus-circle-fill" class="plus-icon"></b-icon>
            Create new collection
          </button>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
  export default {
    name: "AddToCollectionModal",
    props: {
      isMine: {
        type: Boolean,
      },
      showCollections: {
        type: Boolean,
      },
      meme: {
        type: Object,
        default: () => {
        }
      }
    },
    computed: {
      ...mapState({
        myCollections: state => state.collections.data,
      }),
    },
    data() {
      return {
        checked: false,
        collections: [],
        memeCollections: []
      }
    },
    watch: {
      async showCollections() {
        if (this.showCollections && this.myCollections) {
          await this.getMemeData()
          this.collections = this.myCollections.map(c => {
            return {...c, checked: this.memeCollections.includes(c.uuid)}
          })
        }
      },
      myCollections() {
        if (this.myCollections) {
          this.collections = this.myCollections.map(c => {
            return {...c, checked: this.memeCollections.includes(c.uuid)}
          })
        }
      }
    },
    methods: {
      ...mapActions(['showCollectionModal', 'addMemeToCollection', 'removeMemeFromCollection', 'deleteMeme', 'hideOverlay', 'getMeme', 'updateInfoPopup', 'showOverlay']),
      async onAddMemeToCollectionClick(collection, meme) {
        let collectionIndex = this.collections.findIndex(c => c.uuid === collection.uuid)
        this.collections[collectionIndex].checked = !this.collections[collectionIndex].checked
        let res;
        let infomsg = ''
        if (collection.checked) {
          res = await this.addMemeToCollection({
            collection_uuid: collection.uuid,
            voice_memes: [meme.uuid]
          })
          infomsg = `Added to ${collection.title}`
        } else {
          res = await this.removeMemeFromCollection({
            collection_uuid: collection.uuid,
            voice_memes: [meme.uuid]
          })
          infomsg = `Removed from ${collection.title}`
        }
        if (!res.result) {
          console.log("error")
        } else {
          this.updateInfoPopup({show: true, text: infomsg})
        }
        await this.getMemeData();
        this.hideCollections();
      },
      async onDeleteMemeClick(memeUuid) {
        let res = await this.deleteMeme(memeUuid)
        if (!res.result) {
          this.error = res.errors.message[0]
        }
        this.hideOverlay()
        this.updateInfoPopup({show: true, text: 'Deleted'})
      },
      onEditMemeClick(meme) {
        this.hideCollections();
        this.hideOverlay()
        this.$router.path = '/';
        this.$router.push({name: 'edit', params: {id: meme.slug, uuid: meme.uuid}});
      },
      hideCollections() {
        this.$emit('onHideCollectionsList')
      },
      async getMemeData() {
        let respMeme = await this.getMeme(this.meme.uuid)
        if (respMeme.result) {
          this.memeCollections = respMeme.data.collections
        }
      }
    }

  }

  import {mapActions, mapState} from "vuex";
</script>

<style lang="scss" scoped>

  .add-to-collection-modal {
    cursor: default;
    width: 330px;
    max-height: 450px;
    overflow: auto;
    border-radius: 12px;
    padding: 18px 30px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;

    .delete-btn,
    .edit-btn {
      font-family: "Exo", sans-serif;
      font-size: 16px;
    }

    .checkbox-container {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .checkbox-label {
        margin-left: 20px;
        font-family: "Exo", sans-serif;
        font-size: 15px;
        color: #1a1a1a;
      }

      input[type=checkbox] {
        width: 25px;
        height: 25px;
      }
    }

    .modal-items {
      clear: both;
    }

    .close-btn {
      width: 32px;
      height: 32px;
      float: right;
      cursor: pointer;
    }

    .title {
      font-family: "Exo 2", sans-serif;
      font-size: 16px;
      font-weight: 900;
      color: #1a1a1a;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    .new-collection-btn {
      width: 250px;
      height: 50px;
      margin: 6px 0 0 0;
      border-radius: 30px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
      background-color: #fff;
      font-size: 15px;
      font-family: "Exo", sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;

      .plus-icon {
        margin-right: 20px;
        width: 30px;
        height: 30px;
      }
    }
  }

  @media only screen and (max-width: 428px) {

    .add-to-collection-modal {
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 4;
      border-radius: 12px 12px 0 0;
      top: unset;
    }
  }
</style>