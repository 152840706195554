<template>
  <div class="profile row">
    <div class="col-md-4 col-sm-12 profile-col">
      <responsive-header :title="loadedUser ? '@' + loadedUser.username.toUpperCase() : ''" @backClick="$router.go(-1)">
        <template slot="right-button" v-if="loadedUser && currentUser && loadedUser.username===currentUser.username">
          <div class="dropdown-settings" @click="goToSettings()">
            <img class="float-end" src="../../src/assets/icons/settings.svg" alt="menu">
          </div>
        </template>
      </responsive-header>
      <div class="user-name">@{{ loadedUser ? loadedUser.username.toUpperCase() : '' }}</div>
      <div class="profile-container">
        <div class="user-img">
          <img v-if="user && user.avatar" :src="user.avatar.url" alt="user">
          <img v-else src="../assets/icons/user_dark.svg" alt="user">
        </div>
        <div class="followers-info" v-if="loadedUser">
          <b-tooltip target="likes">Likes</b-tooltip>
          <label class="mr-1" id="likes">
            <img class="mr-1" src="../assets/icons/heart_dark.svg" alt="like"/>
            {{ loadedUser.total_likes ? loadedUser.total_likes : 0 }}
          </label>
          <b-tooltip target="followers">Followers</b-tooltip>
          <label class="mr-1" id="followers">
            <img class="mr-1" src="../assets/icons/user_arrow_left.svg" alt="following"/>
            {{ loadedUser.total_followers ? loadedUser.total_followers : 0 }}
          </label>
          <b-tooltip target="following">Following</b-tooltip>
          <label class="mr-1" id="following">
            <img class="mr-1" src="../assets/icons/user_arrow_right.svg" alt="follower"/>
            {{ loadedUser.total_followings ? loadedUser.total_followings : 0 }}
          </label>
          <b-tooltip target="views">Views</b-tooltip>
          <label class="mr-1" id="views">
            <img class="mr-1" src="../assets/icons/eye.svg" alt="views"/>
            {{ loadedUser.total_views ? loadedUser.total_views : 0 }}
          </label>
          <b-tooltip target="uploads">Uploads</b-tooltip>
          <label id="uploads">
            <b-icon icon="upload" class="upload-btn-icon" style="width: 15px;height: 10px"></b-icon>
            {{ loadedUser.total_voicememes ? loadedUser.total_voicememes : 0 }}
          </label>
        </div>
        <div class="follow-btn-cont">
          <router-link to="/account" v-if="isMyProfile" class="edit-profile-btn btn-hover">
            <div>Edit Profile</div>
          </router-link>
          <button v-else class="follow-btn" @click="onFollowClick()"
                  :style="{background:profileColor}">
            <span v-if="loadedUser && loadedUser.is_followed">Unfollow</span>
            <span v-else>Follow</span>
          </button>
          <label class="error-msg" v-if="error">{{ error }}</label>
          <div class="info-text">
            {{ loadedUser ? loadedUser.bio : '' }}
          </div>
        </div>
        <hr class="hr">
        <div class="social-media" v-if="loadedUser">
          <a :href="loadedUser.facebook_url" target="_blank" v-if="loadedUser.facebook_url">
            <img src="../assets/images/facebook_circle.svg" alt="facebook" class="btn-hover social-media-icon">
          </a>
          <a :href="loadedUser.instagram_url" target="_blank" v-if="loadedUser.instagram_url">
            <img src="../assets/images/instagram.svg" alt="instagram" class="btn-hover social-media-icon">
          </a>
          <a :href="loadedUser.tiktok_url" target="_blank" v-if="loadedUser.tiktok_url">
            <img src="../assets/images/tiktok.svg" alt="tiktok" class="btn-hover social-media-icon tiktok">
          </a>
        </div>
        <a :href="loadedUser.website_url" target="_blank" v-if="loadedUser && loadedUser.website_url" class="d-flex ">
          <img src="../assets/images/chain_dark.svg" alt="website">
          <div style="padding-right: 5px">{{ loadedUser.website_url }}</div>
        </a>
      </div>
    </div>
    <div class="col-md-8 col-sm-12 tabs-container">
      <hr class="res-hr"/>
      <div class="tabs">
        <router-link :to="{ name:'profile.gallery'}">
          <div class="tab-item" @click="setActiveTab('gallery')">
            <img v-if="activeTab === 'gallery'" class="mr-1" src="../assets/icons/gallery_icon.svg" alt="icon"/>
            <img v-else class="mr-1" src="../assets/icons/gallery_icon_nav_gray.svg" alt="icon"/>
            <span :class="activeTab==='gallery'? 'active' : ''">{{ 'Gallery'.toUpperCase() }}</span>
          </div>
        </router-link>
        <router-link :to="{ name:'profile.liked-gifs' }">
          <div class="tab-item" @click="setActiveTab('liked-gifs')">
            <img v-if="activeTab === 'liked-gifs'" class="mr-1" src="../assets/icons/heart_icon_nav.svg" alt="icon"/>
            <img v-else class="mr-1" src="../assets/icons/heart_icon_nav_gray.svg" alt="icon"/>
            <span :class="activeTab==='liked-gifs'? 'active' : ''">{{ 'Liked gifs'.toUpperCase() }}</span>
          </div>
        </router-link>
        <router-link :to="{ name:'profile.collection' }">
          <div class="tab-item" @click="setActiveTab('collection')">
            <img v-if="activeTab === 'collection'" class="mr-1" src="../assets/icons/collection_icon_nav.svg"
                 alt="icon"/>
            <img v-else class="mr-1" src="../assets/icons/collection_icon_nav_gray.svg" alt="icon"/>
            <span :class="activeTab==='collection'? 'active' : ''">{{ 'Collections'.toUpperCase() }}</span></div>
        </router-link>
      </div>
      <hr class="res-hr"/>
      <div class="router">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import ResponsiveHeader from "./MobileDeviceComponents/ResponsiveHeader";

  export default {
    name: "Profile",
    components: {ResponsiveHeader},
    data() {
      return {
        isMyProfile: null,
        loadedUser: null,
        user: null,
        error: '',
        showDropdown: ''
      }
    },
    computed: {
      ...mapState(
        {
          currentUser: state => state.currentUser,
          collections: state => state.collections,
          activeTab: state => state.activeTab,
          profileColor: state => state.profileColor,
        }),
    },
    watch: {
      $route(to) {
        let tab = to.path.split('/')[to.path.split('/').length - 1]
        this.setActiveTab(tab)
        this.getUser()
      },
      'currentUser': {
        handler() {
          if (this.currentUser) {
            this.getUser()
          }
        },
      }
    },
    mounted() {
      this.getUser()
      let tab = this.$route.path.split('/')[this.$route.path.split('/').length - 1]
      if (this.$route.path.includes('collection')) {
        tab = 'collection'
      }
      this.setActiveTab(tab)
      this.updateHideHeader(true)
    },
    beforeDestroy() {
      this.updateHideHeader(false)
    },
    methods: {
      ...mapActions(['setActiveTab', 'getUserProfile', 'follow', 'unfollow', 'updateHideHeader']),
      async getUser() {
        let response = await this.getUserProfile(this.$route.params.id)
        if (response.result) {
          this.user = response.data
          if (this.currentUser) {
            this.isMyProfile = this.currentUser.username === this.user.username
          }
          this.setUser()
        }
      },
      setUser() {
        // if (this.isMyProfile) {
        //   this.loadedUser = this.currentUser
        // } else {
        this.loadedUser = this.user
        // }
      },
      async onFollowClick() {
        if (!this.currentUser) {
          return
        }
        let response;
        if (this.loadedUser.is_followed) {
          response = await this.unfollow(this.loadedUser.username)
        } else {
          response = await this.follow(this.loadedUser.username)
        }
        if (response.result) {
          await this.getUser()
        } else {
          this.error = response.errors.message[0]
        }
      },
      goToSettings() {
        this.$router.path = '/';
        this.$router.push({name: 'settings'});
      }
    }
  }
</script>

<style lang="scss" scoped>
  .profile {
    margin-top: 40px;
  }

  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2.5rem;
  }

  .user-name {
    font-family: "Exo 2", sans-serif;
    font-size: 35px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #1a1a1a;
    padding: 0 2.5rem;
    overflow-wrap: anywhere;
  }

  .user-img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin-top: 48px;
    margin-bottom: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .followers-info {
    label {
      font-weight: bold;
      font-size: 12px;
    }

    img {
      width: 15px;
    }

    .upload-btn-icon {
      /deep/ path {
        stroke: #1a1a1a;
        stroke-width: 2;
      }
    }
  }

  .follow-btn-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .edit-profile-btn,
  .follow-btn {
    width: 100%;
    height: 50px;
    border-radius: 32px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    background-color: #d8d8d8;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #fff !important;
    cursor: pointer;
    margin-top: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 37px;
  }

  .follow-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: 0.7;
    }
  }

  .info-text {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a1a;
    padding-bottom: 25px;
    border-bottom: solid 1px #f0f0f0;
  }

  .social-media {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;

    .social-media-icon {
      margin-left: 5px;
      margin-right: 5px;
      width: 25px;
    }

    .tiktok {
      margin-left: 3px;
    }
  }

  .tabs-container {
    margin-top: 30px;
  }

  .tabs {
    font-family: "Exo 2", sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #696969;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8rem;
    cursor: pointer;

    .tab-item {
      display: flex;
      align-items: center;
      color: #696969;
      font-weight: bold;
      white-space: nowrap;

      &:hover {
        opacity: 0.7;
      }
    }
  }


  @media only screen and (max-width: 1600px) {
    .profile {
      .user-name {
        font-size: 45px;
        padding: 0;
      }

      .profile-container {
        padding: 0;
      }

      .tabs {
        font-size: 25px;
        padding: 0 6rem
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .profile {
      .tabs {
        font-size: 21px;
        padding: 0 4rem
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .profile {
      .achievements {
        .card {
          span {
            font-size: 12px;
          }

          .amount {
            font-size: 20px;
          }

        }
      }

      .tabs {
        font-size: 18px;
        padding: 0 3rem

      }
    }
  }

  @media only screen and (max-width: 768px) {
    .profile {
      .achievements {
        .card {
          span {
            font-size: unset;
          }

          .amount {
            font-size: 30px;
          }
        }
      }

      .tabs {
        font-size: 20px;
        padding: 0 4rem;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .profile {
      .tabs {
        font-size: 18px;
        padding: 0 2rem
      }
    }
  }

  @media only screen and (max-width: 428px) {
    .profile {
      margin: 0;

      .social-media-icon {
        width: 18px;
      }

      .profile-col {
        padding-right: 0;
        padding-left: 0;
      }

      .info-text {
        border-bottom: none;
      }

      .tabs {
        font-size: 15px;
        padding: 0
      }
    }

    .profile-container {
      margin: 60px 0 0 0;
    }
    .user-name {
      display: none;
    }

    .user-img {
      width: 105px;
      height: 105px;
      margin-top: 10px;
      margin-bottom: 16px;
    }
    .dropdown-settings {
      .dropdown-content {
        position: absolute;
        width: calc(100% + 20px);
        right: 0;
        background: #ffffff;
        text-align: center;
        top: 60px;
        padding: 50px 0;
        border-radius: 0 0 12px 12px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.16);
        font-family: "Open Sans", sans-serif;
        font-size: 20px;
        line-height: 2.75;
        left: -10px;
      }
    }
    .edit-profile-btn {
      display: none;
    }
    .follow-btn-cont {
      align-items: center;
    }
    .info-text {
      padding-bottom: 0;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .follow-btn {
      margin-top: 0;
      margin-bottom: 0;
      width: 70%;
      height: 50px;

      &:hover {
        opacity: 1;
      }
    }

    .tabs-container {
      margin: 0;
      padding-right: 0;
      padding-left: 0;

      .tabs {
        padding: 0 95px;
      }

      .tab-item {
        span {
          display: none
        }
      }
    }
  }
</style>