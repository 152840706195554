<template>
  <transition name="fade">
    <div class="cookie-policy-box">
      <div class="text">
        <div class="header">Cookie Policy</div>
        <p>
          KIKLIKO uses cookies to enhance site navigation and improve functionality, analyze site usage, and assist in
          our
          marketing and advertising efforts. By clicking “Agree and close,” you consent to our usage of cookies as
          described
          in our
          <router-link to="/documentation/privacy">
            <span>Privacy Policy</span>
          </router-link>
          .
        </p>
      </div>
      <div class="agree-button-container">
        <div class="button-general agree-button" :style="{background: profileColor}" @click="acceptCookies()">
          Agree and close
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "Cookies",
    computed: {
      ...mapState(['profileColor'])
    },
    methods: {
      acceptCookies() {
        localStorage.setItem('cookiesAccepted', 'accepted')
        this.$emit('onAcceptCookies')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cookie-policy-box {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px 150px;
    background-color: #000000b3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #eaeaea;

    .text {
      padding: 0 50px 0 0;

      .header {
        font-size: 20px;
        font-weight: 600;
      }

      span {
        text-decoration: underline;
      }
    }

    .agree-button-container {
      width: 40%;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .agree-button {
        font-size: 18px;
        width: 230px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }
  }

  @media only screen and (max-width: 1366px) {
    .cookie-policy-box {
      padding: 15px 70px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .cookie-policy-box {
      padding: 15px 20px;
    }
  }

  @media only screen and (max-width: 428px) {

    .cookie-policy-box {
      flex-direction: column;
      font-size: 12px;
      bottom: unset;
      top: 0;
      z-index: 5;

      .agree-button-container {
        width: 60%;
      }

      .text {
        padding: 0;
      }
    }
  }
</style>
