<template>
  <transition name="fade">
    <div class="open-app for-mobile" v-if="show">
      <img src="../../assets/images/kikliko.png" alt="kikliko" class="kikliko">
      <div style="font-size: 22px">For better experience please</div>
      <div class="app-text button" @click="onOpenAppClick()">
        <button>OPEN IN APP</button>
      </div>
      <div class="app-text" @click="onNotNowClick()">Not now</div>
    </div>
  </transition>
</template>

<script>
  import {mapActions, mapState} from "vuex";

  export default {
    name: "OpenApp",
    props: {
      show: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      ...mapActions(['showOverlay', 'hideOverlay']),
      onOpenAppClick() {
        this.$emit('openApp')
      },
      onNotNowClick() {
        let currentTime = new Date()
        localStorage.setItem('notNowClickTime', currentTime.getTime().toString())
        this.$emit('notNow')
      }
    },
    computed: {
      ...mapState(['openAppSticky'])
    },
    watch: {
      show() {
        if (this.show) {
          this.showOverlay()
        } else {
          this.hideOverlay()
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .open-app {
    width: 100%;
    height: 320px;
    border-radius: 30px 30px 0 0;
    padding: 28px 30px 40px 30px;
    background-color: #fff;
    position: fixed;
    z-index: 10;
    bottom: 0;
    right: 0;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #1a1a1a;

    .kikliko {
      width: 93px;
    }

    .app-text {
      font-size: 20px;
      font-weight: bold;
      color: #696969;
      width: 100%;
      text-align: center;
      font-family: "Open Sans", sans-serif;

      button {
        font-weight: bold;
        width: 100%;
        padding: 18px;
        border-radius: 60px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
        color: #ffffff;
        background: rgb(255, 114, 71);
        background: linear-gradient(90deg, rgba(255, 114, 71, 1) 0%, rgba(255, 115, 113, 1) 100%);
      }
    }
  }
</style>
