<template>
  <div>
    <section-title main="Extensions"/>
    <div class="row">
      <extension-box v-for="(e,i) in extensions" :key="`extension_${i}`" :extension="e"/>
    </div>
  </div>
</template>

<script>
  import SectionTitle from "./widgets/SectionTitle";
  import ExtensionBox from "./widgets/ExtensionBox";

  export default {
    name: "Extensions",
    components: {SectionTitle, ExtensionBox},
    data() {
      return {
        extensions: [
          {
            key: 'chromeExtension',
            title: 'Kikliko Chrome Extension!',
            version: '',
            logo: require('../assets/images/kikliko.png'),
            desc: 'Our Extension allows you to search and view Clips & Memes without changing a tab in your browser',
            videoSrc: require('../assets/images/extension.mp4'),
            href: 'https://chrome.google.com/webstore/detail/kikliko-gifs-with-sound/eecamihehojmibkhhpfijgpmnbikhjgi'
          },
          {
            title: 'Android App',
            version: '1.0',
            logo: require('../assets/images/kikliko.png'),
            desc: 'Find us on PlayStore and Send Clips & Memes straight as a video.',
            imgSrc: require('../assets/images/kikliko.png'),
            href: 'https://play.google.com/store/apps/details?id=com.kikliko'
          },
          {
            title: 'iOS App',
            version: '1.1',
            logo: require('../assets/images/kikliko.png'),
            desc: 'Find us on AppStore and Send Clips & Memes straight as a video.',
            imgSrc: require('../assets/images/kikliko.png'),
            href: 'https://apps.apple.com/us/app/kikliko-com-gifs-with-sound/id1598936040'
          }
        ]
      }
    },
    metaInfo() {
      return {
        title: 'Extensions',
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `Extensions and mobile apps of KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`
          },
        ],
      };
    },
    mounted() {
      this.$nextTick(() => {
        this.getExtensionVersion()

      })
    },
    methods: {
      getExtensionVersion() {
        let indexExt = this.extensions.findIndex(e => e.key === 'chromeExtension')
        this.extensions[indexExt].version = document.getElementsByTagName('html')[0].getAttribute('chrome-ext-v') ?
          document.getElementsByTagName('html')[0].getAttribute('chrome-ext-v') : '2.1';
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>