<template>
  <div class="header mb-4">
    <div class="scrollable-hashtags">
      <router-link :to="{name: 'main'}">
        <div @click="clearFilter()" class="main-page">
          <SectionTitle main="TAGS" secondary="#" id="section-tags"/>
        </div>
      </router-link>
      <div class="section scrollable scrollable-gifs" v-if="showTagWithPicture">
        <div class="scroll-btn left" @click="scroll_left()"><span>&#x2039;</span></div>
        <div class="d-flex justify-content-center mb-3" v-if="isLoading">
          <b-spinner></b-spinner>
        </div>
        <div class="scrollable-container text-nowrap mr-0 ml-0">
          <div class="hash-tag-box" v-for="(tag,i) in tags" :key="i" @click="filterTrends(tag)">
            <gif-box :data="tag.voice_meme" :show-bottom-settings="false" :show-settings-button="false"
                     :tag="getTitle(tag.tag)" :is-muted="isMuted" is-tag
                     :height="'100%'"
                     @onVolumeChange="changeVolume" :index="i"
                     :box-route-object="{name: 'tag.view', params: {'tag': tag.tag}}"
            />
          </div>
        </div>
        <div class="scroll-btn right" @click="scroll_right()"><span>&#x203A;</span></div>
      </div>
      <div class="scrollable" v-else>
        <div class="scroll-btn left" @click="scroll_left()"><span>&#x2039;</span></div>
        <div class="scrollable-container m-0">
          <router-link v-for="(item,i) in tags" :key="`tags_${i}`" :to="{name: 'tag.view', params: {'tag': item.tag}}">
            <div class="category-btn mr-3 btn-hover"
                 :class="`color${i%5}`" @click="filterTrends(item)">
              <div class="tag">{{ getTitle(item.tag) }}</div>
            </div>
          </router-link>
        </div>
        <div class="scroll-btn right" @click="scroll_right()"><span>&#x203A;</span></div>
      </div>
    </div>
    <div class="scrollable-hashtags-res" id="scrollableHashtagsRes" v-if="!hideHeader">
      <div class="scrollable">
        <div class="scrollable-container row m-0" id="scrollable-container">
          <router-link :to="{name: 'main'}">
            <div class="logo  " @click="clearFilter()">
              <img src="../../assets/images/kikliko.png" alt="kikliko"/>
            </div>
          </router-link>
          <router-link :to="{name: 'main'}">
            <div class="no-color-tag" :class="highlightDiscover ? 'category-btn color0' : ''" @click="clearFilter()">
              <div class="tag-res">#Discover</div>
            </div>
          </router-link>
          <router-link v-for="(item,i) in tags" :key="`tags2_${i}`" :to="{name: 'tag.view', params: {'tag': item.tag}}">
            <div class="no-color-tag" @click="filterTrends(item,i)" :id="`tag_${i}`">
              <div class="tag-res">{{ getTitle(item.tag) }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <HeaderSettings @onHomeClick="clearFilter"/>
  </div>
</template>

<script>
  import SectionTitle from "./SectionTitle";
  import HeaderSettings from "./HeaderSettings";
  import {mapActions, mapState} from "vuex";
  import GifBox from "./GifBox";
  import {EventBus} from '../../main'

  export default {
    name: "Header",
    components: {GifBox, HeaderSettings, SectionTitle},
    computed: {
      ...mapState(['currentUser', 'tags', 'hideHeader', 'isMuted', 'memeResultsTopPos']),
      showTagWithPicture() {
        return ['main', 'tag.view', 'query.view'].includes(this.$route.name)
      },
      highlightDiscover() {
        return ['main', 'query.view'].includes(this.$route.name)
      },
    },
    data() {
      return {
        isLoading: true,
        active: false,
        lastActiveTagIndex: 0,
        filtered: false,
      }
    },
    mounted() {
      this.loadTags();
      EventBus.$on("onSwipe", (data) => {
        this.swipe(data)
      });
    },
    methods: {
      ...mapActions(['getTags', 'updateFilter', "getTrends", 'clearTrends', 'updateFilterError', 'changeIsMuted']),
      scroll_left() {
        let content = document.querySelector(".scrollable-container");
        content.scrollLeft -= 700;
      },
      scroll_right() {
        let content = document.querySelector(".scrollable-container");
        content.scrollLeft += 700;
      },
      async loadTags() {
        this.isLoading = true;
        let res = await this.getTags()
        if (res) {
          this.isLoading = false;
        }
      },
      clearFilter() {
        this.filtered = false
        if (document.getElementById('tag_' + this.lastActiveTagIndex)) {
          document.getElementById('tag_' + this.lastActiveTagIndex).className = 'no-color-tag'
        }

        this.lastActiveTagIndex = 0;
      },
      async filterTrends(item, index) {
        window.scrollTo({left: 0, top: this.memeResultsTopPos, behavior: "smooth"});
        if (document.getElementById('scrollableHashtagsRes') && (window.getComputedStyle(document.getElementById('scrollableHashtagsRes')).display !== 'none')) {
          document.getElementById('tag_' + this.lastActiveTagIndex).className = 'no-color-tag'
          document.getElementById('tag_' + index).classList.add(`category-btn`, `color${index % 5}`)
          this.filtered = true
        }
        this.lastActiveTagIndex = index
      },
      getTitle(text) {
        let temp = text
        if (text.length > 8) {
          temp = text.slice(0, 8).concat('...')
        }
        return '#' + temp
      },
      changeVolume(val) {
        this.changeIsMuted(val.isMuted)
      },
      swipe(to) {
        let indx = this.lastActiveTagIndex;
        let tag = null;
        if (to === 'right') {
          tag = indx <= 0 ? this.tags[0] : this.tags[indx - 1]
          if (indx === 0) {
            this.clearFilter();
            return
          }
          indx = indx <= 0 ? 0 : indx - 1

        } else {
          tag = !this.filtered && indx === 0 ? this.tags[0] : this.tags[indx + 1]
          indx = !this.filtered && indx === 0 ? 0 : indx + 1
          if (indx === this.tags.length) {
            return
          }

        }
        const rect = document.getElementById(`tag_${indx}`).getBoundingClientRect();
        const rectBefore = indx === 0 ? '' : document.getElementById(`tag_${indx - 1}`).getBoundingClientRect();
        let content = document.getElementById("scrollable-container");
        if (to === 'left') {
          content.scrollLeft += rect.left - 70;
        } else {
          content.scrollLeft = 0
          if (rectBefore) {
            content.scrollLeft += rectBefore.left - 130
          }
        }
        this.filterTrends(tag, indx)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hash-tag-box {
    height: 170px;
    width: 170px;
    display: inline-block;
    margin-right: 10px;
  }

  .header {
    .main-page {
      cursor: pointer;
    }

    /deep/ .slide {
      top: 0;
    }

    .no-color-tag {
      text-align: center;
      display: flex;
      align-items: center;
      height: 50px;
      margin-right: 25px;

      .tag-res {
        font-family: "Open Sans", sans-serif;
        font-size: 20px;
        font-weight: bold;
        white-space: nowrap;
      }
    }

    .scrollable-container {
      white-space: nowrap;
    }

    .category-btn {
      color: #ffffff;
      border-radius: 12px;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      width: 200px;
      height: 50px;
      position: relative;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);

      .tag {
        position: absolute;
        font-weight: bold;
        font-size: 20px;
        font-family: "Open Sans", sans-serif;
        line-height: 1.2;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

    }


    .color1 {
      background: rgb(255, 0, 156);
      background: linear-gradient(90deg, rgba(255, 0, 156, 1) 0%, rgba(241, 2, 152, 1) 24%, rgba(92, 20, 114, 1) 100%);
    }

    .color2 {
      background: rgb(252, 33, 13);
      background: linear-gradient(90deg, rgba(252, 33, 13, 1) 0%, rgba(247, 206, 56, 1) 100%);
    }

    .color3 {
      background: rgb(129, 252, 111);
      background: linear-gradient(90deg, rgba(129, 252, 111, 1) 0%, rgba(11, 129, 111, 1) 100%);
    }

    .color4 {
      background: rgb(174, 187, 248);
      background: linear-gradient(90deg, rgba(174, 187, 248, 1) 0%, rgba(201, 115, 252, 1) 100%);
    }

    .color0 {
      background: rgb(192, 255, 200);
      background: linear-gradient(90deg, rgba(192, 255, 200, 1) 0%, rgba(23, 165, 167, 1) 100%);
    }
  }

  .scrollable-hashtags-res {
    display: none;
  }

  @media only screen and (max-width: 1600px) {
    .hash-tag-box {
      width: 150px;
      height: 150px;
    }

    .header {
      .category-btn {
        height: 50px;
        width: 180px;

        .tag {
          font-size: 22px;
        }
      }
    }
  }

  @media only screen and (max-width: 1366px) {
    .header {
      .category-btn {
        height: 40px;
        width: 140px;

        .tag {
          font-size: 18px;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .hash-tag-box {
      width: 120px;
      height: 120px;
    }
    .header {
      .category-btn {
        height: 40px;
        width: 160px;

        .tag {
          font-size: 20px;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .scrollable {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 992px) {
    .hash-tag-box {
      width: 100px;
      height: 100px;
    }
    .header {
      .category-btn {
        .tag {
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .hash-tag-box {
      width: 80px;
      height: 80px;
    }
    //.header {
    //  .tag {
    //    font-size: 16px;
    //  }
    //}
  }

  @media only screen and (max-width: 576px) {
    .hash-tag-box {
      width: 60px;
      height: 60px;
    }
    .header {
      .tag {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 428px) {
    .header {
      .no-color-tag {
        margin-right: 15px;
      }
    }
    .scrollable-hashtags {
      display: none;
    }
    .scrollable-hashtags-res {
      padding: 7px 10px;
      display: block;
      position: fixed;
      width: 100%;
      top: 0;
      background: #ffffff;
      z-index: 2;
      margin-left: -10px;

      .logo {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #f7dc3c;
        }
      }

      .category-btn {
        height: 50px;
        border-radius: 25px;
        padding: 0 30px;
        white-space: nowrap;
        width: auto;
        position: unset;
        display: flex;
        align-items: center;

        .tag-res {
          font-size: 20px;
          white-space: nowrap;
        }
      }

    }
  }
</style>
