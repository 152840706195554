<template>
  <div class="section-title" :class="responsive? 'justify-content-center' : '' ">
    <div class="mr-2 large" :class="size">
      {{ main ? main.toUpperCase() : '' }}
    </div>
    <span class="medium" :class="size">{{ secondary }}</span>
  </div>
</template>

<script>
  export default {
    name: "SectionTitle",
    props: {
      main: {
        type: String,
      },
      secondary: {
        type: String,
      },
      size: {
        type: String,
        default: 'lg',
      },
      responsive: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>
<style lang="scss" scoped>
  .section-title {
    font-family: "Exo 2", sans-serif;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #1a1a1a;
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;
  }

  .large {
    font-size: 35px;

    &.md {
      font-size: 20px;
    }
  }


  .medium {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;

    &.md {
      font-size: 25px;
    }
  }

  @media only screen and (max-width: 1366px) {
    .large {
      font-size: 30px;

      &.md {
        font-size: 15px;
      }
    }

    .medium {
      font-size: 20px;

      &.md {
        font-size: 13px;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .section-title {
      margin-bottom: 0.5rem;
    }
    .large {
      font-size: 30px;

      &.md {
        font-size: 20px;
      }
    }

    .medium {
      font-size: 20px;

      &.md {
        font-size: 15px;
      }
    }
  }

  @media only screen and (max-width: 428px) {
    .section-title {
      margin-bottom: 0;
    }
    .large {
      font-size: 23px;

      &.md {
        font-size: 23px;
      }
    }
    .medium {
      font-size: 22px;

      &.md {
        font-size: 21px;
      }
    }
  }
</style>