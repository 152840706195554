<template>
  <div>
    <div v-show="duration > 0">
      <youtube :video-id="url" ref="youtube"></youtube>
    </div>
    <div v-if="duration > 0" class="range-selector mt-5">
      <vue-slider :interval="0.1" ref="slider" :value="range" :enable-cross="false"
                  @dragging="slideEnd" :real-time="true" :max="this.duration"
                  :tooltip-formatter="formatter" :clickable="false"></vue-slider>
      <div class="time-input">
        <div class="d-flex align-items-center ">
          <label class="mr-2">From:</label>
          <input v-model="input_range[0]" @change="inputModified(true)" @keypress="validateNumber"/>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <label class="mr-2">To:</label>
          <input v-model="input_range[1]" @change="inputModified(false)"/>
        </div>
      </div>
    </div>
    <div v-if="error">
      <h2>{{ error }}</h2>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'YoutubeVideoCutter',
  components: {VueSlider},
  props: {
    'url': {},
  },
  data() {
    return {
      link: '',
      duration: 0,
      error: '',
      range: [0, 10],
      input_range: ['00:00:00.0', '00:00:10.0'],
      playerTimeout: null,
      max_distance: 10,
      formatter: v => this.formatSliderData(v),
      video_playing: true,
    }
  },
  mounted() {
    this.$refs.youtube.player.addEventListener('onStateChange', (state) => {
      // https://developers.google.com/youtube/iframe_api_reference#Playback_status
      // playing or buffering
      this.video_playing = state.data === 1 || state.data === 3
    })
    this.$refs.youtube.player.getDuration().then(duration => {
      this.duration = duration;
      if (duration <= 0) {
        this.error = 'Check the video URL!';
      }
      if (this.duration < 10) {
        this.seekAndSetData(0, this.duration)
      } else {
        this.seekAndSetData(0, 10)
      }
    })
  },
  methods: {
    inputModified(from) {
      let mappedInputRange = this.input_range
      this.input_range = mappedInputRange.map(v => this.deFormatSliderData(v))
      if (from) {
        if (this.input_range[0] > this.duration - this.max_distance) {
          this.input_range[0] = this.duration - this.max_distance
        }
        if (this.input_range[0] > this.input_range[1]) {
          this.input_range[1] = Math.min(this.input_range[0] + this.max_distance, this.duration);
        }
        if (this.input_range[0] + this.max_distance < this.input_range[1] || this.input_range[0] === this.input_range[1]) {
          this.input_range[1] = this.input_range[0] + this.max_distance;
        }
      } else {
        if (this.input_range[1] > this.duration) {
          this.input_range[1] = this.duration
        }
        if (this.input_range[1] < this.input_range[0]) {
          this.input_range[0] = Math.max(this.input_range[1] - this.max_distance, 0);
        }
        if (this.input_range[1] - this.max_distance > this.input_range[0]) {
          this.input_range[0] = this.input_range[1] - this.max_distance;
        }
      }
      this.seekAndSetData(Math.max(this.input_range[0], 0), Math.min(this.input_range[1], this.duration))
    },
    slideEnd(range, isRight, renew_inputs = true) {
      this.seekAndSetData(range[0], range[1], renew_inputs)
    },
    seekAndSetData(start, end, renew_inputs = true) {
      let range = [start, end]
      let right_direction = end > this.range[1];
      if (end - start > this.max_distance) {
        range = right_direction ? [end - this.max_distance, end] : [start, start + this.max_distance]
        this.$refs.slider.setValue(range)
      }
      start = parseFloat(parseFloat(range[0]).toFixed(2))
      end = parseFloat(parseFloat(range[1]).toFixed(2))
      range = [start, end]
      this.range = range
      let mappedRange = [...this.range]
      if (renew_inputs) {
        this.input_range = mappedRange.map(v => this.formatSliderData(v))
      }
      this.restartTimeout()
    },
    restartTimeout(force = false) {
      if (force || this.video_playing) {
        this.$refs.youtube.player.playVideo()
        this.$refs.youtube.player.seekTo(this.range[0])
        this.$emit('rangeSet', this.range)
        clearTimeout(this.playerTimeout)
        this.playerTimeout = setTimeout(() => {
          this.slideEnd(this.range, 0, false)
        }, (this.range[1] - this.range[0]) * 1000)
      }
    },
    formatSliderData(v) {
      let sec_num = parseFloat(v);
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);
      seconds = seconds.toFixed(1)
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return hours + ':' + minutes + ':' + seconds;
    },
    deFormatSliderData(v) {
      let hours;
      let minutes;
      let seconds;
      if (v.split(':').length === 1) {
        hours = '00'
        minutes = '00'
        seconds = v.split(':')[0]
      } else if (!v.split(':')[2]) {
        hours = '00'
        minutes = v.split(':')[0]
        seconds = v.split(':')[1]
      } else {
        hours = v.split(':')[0]
        minutes = v.split(':')[1]
        seconds = v.split(':')[2]
      }
      return (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);
    },
    validateNumber(e) {
      const charCode = String.fromCharCode(e.keyCode);
      if (!/[0-9:.]/.test(charCode)) {
        e.preventDefault();
      }
    }
  },
  watch: {
    video_playing: {
      handler(val) {
        if (val) {
          this.restartTimeout(true)
        }
      }
    },
    url: {
      handler(val) {
        if (this.link !== val) {
          this.link = val;
        }
      },
      immediate: true,
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .range-selector {
  .vue-slider-rail {
    .vue-slider-dot:nth-child(2) {
      margin-left: -7px;
    }

    .vue-slider-dot:nth-child(3) {
      margin-left: 7px;
    }
  }
}

.range-selector {
  .time-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    input {
      width: 60%;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    @media only screen and (max-width: 992px) {
      input {
        width: 70%;
      }
    }
  }
}

label {
  font-family: "Exo 2", sans-serif;
  font-weight: bold;
}

@media only screen and (max-width: 428px) {
  .range-selector {
    .time-input {

      input {
        padding: 10px;
        width: 100%;
      }
    }
  }

}
</style>
