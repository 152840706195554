<template>
  <div>
    <div class="tab-content grid-view">
      <div v-if="isMe" class="m-3 position-relative btn-hover" @click="showCollectionModal()">
        <div class="collection">
          <div class="gif-container create-new-gif-btn">
            <div class="plus-btn" :style="{background:profileColor}">
              +
            </div>
            <div class="create-text">Create new collection</div>
          </div>
          <div class="collection collection-back">
          </div>
        </div>
      </div>
      <div v-for="(collection,index) in computedCollections.data"
           :key="`collection_${index}`"
           class="collection-container">
        <collection :data="collection" :is-me="isMe"/>
      </div>
    </div>
    <collection-modal/>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import Collection from "../widgets/Collection";
  import CollectionModal from "../CollectionModal";

  export default {
    name: "CollectionsTab",
    components: {CollectionModal, Collection},
    data() {
      return {
        selectedCollection: {},
        isMe: false,
      }
    },
    metaInfo() {
      return {
        title: `@${this.$route.params.id.toUpperCase()} Collections`,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `Find ${this.$route.params.id.toUpperCase()} Collections on KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`,
          },
        ],
      };
    },
    computed: {
      ...mapState(['currentUser', 'collections', 'profileColor', 'otherUsersCollections']),
      computedCollections() {
        return this.isMe ? this.collections : this.otherUsersCollections
      }
    },
    watch: {
      currentUser() {
        this.init();
      },
      $route() {
        this.init();
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      ...mapActions(['showCollectionModal', "getCollections", 'updateCollection']),
      init() {
        this.getCollections(this.$route.params.id);
        if (this.currentUser) {
          this.isMe = this.$route.params.id === this.currentUser.username
        }
      },
    },

  }
</script>

<style lang="scss" scoped>

  .create-new-gif-btn {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;

    .create-text {
      width: 50%;
    }
  }

  .gif-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .create-new-gif-btn, {
    .plus-btn {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      color: #ffffff;
      font-size: 41px;
      line-height: 3.12;
      margin-bottom: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .collection-back {
    z-index: -1;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .grid-view {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;

  }

  .collection-container {
    position: relative;
    margin: 16px;
  }

  @media only screen and (max-width: 1200px) {
    .create-new-gif-btn {
      font-size: 15px;

      .plus-btn {
        width: 30px;
        font-size: 20px;
        height: 30px;
        margin-bottom: 10px
      }
    }
  }


  @media only screen and (max-width: 768px) {
    .create-new-gif-btn {
      font-size: 18px;

      .plus-btn {
        width: 60px;
        font-size: 20px;
        height: 60px;
        margin-bottom: 10px
      }
    }
  }

  @media only screen and (max-width: 428px) {
    .collection-container {
      margin: 10px;
    }
    .tab-content {
      margin-top: 0;
    }
    .grid-view {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: dense;
      gap: 5px;
    }

    .create-new-gif-btn {
      font-size: 16px;

      .create-text {
        width: 85%;
      }
    }
  }


</style>
