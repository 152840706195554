<template>
  <div>
    <div class="m-0 settings-container not-for-mobile">
      <router-link :to="{name: 'main'}">
        <div class="logo right-space btn-hover" @click="clearFilter()">
          <img src="../../assets/images/kikliko.png" alt="Kikliko - Clips & Memes"/>
        </div>
      </router-link>
      <div class="position-relative right-space search">
        <form @submit.prevent="filterTrends()">
          <input type="text" placeholder="Search by" name="search" v-model="searchText" autocomplete="off">
          <button type="submit" class="position-absolute search-button">
            <img class="search-icon" src="../../../src/assets/icons/search.svg" alt="search"/>
          </button>
        </form>
      </div>
      <div class="d-flex right-settings">
        <button :style="{background: profileColor}" class="create-btn btn-hover create-btn-active right-space"
                @click="onCreateClick()">
          Create
        </button>
        <div class="notifications right-space" v-if="currentUser">

          <b-dropdown right class="notifications mr-2 ml-2" v-if="currentUser" no-caret @show="showNotifications()">
            <template #button-content>
              <div
                class="w-100 h-100 d-flex align-items-center justify-content-center">
                <img class="bell-icon" src="../../assets/icons/bell.svg" alt="bell"/>
                <div v-if="notificationsTotal" class="notifications-badge" :style="{background: profileColor}">
                  {{ notificationsTotal }}
                </div>
              </div>
            </template>
            <template v-if="notifications && !notifications.data.length">
              <b-dropdown-item>
                <div class="no-notifications" v-if="notifications && !notifications.data.length">
                  <div class="text">No one cares about you</div>
                  <img src="../../../src/assets/icons/forever_alone.png" alt="forever_alone"/>
                </div>
              </b-dropdown-item>
            </template>
            <template v-else>
              <b-dropdown-text v-if="newNotifications.length > 0">New</b-dropdown-text>
              <b-dropdown-item v-for="notification in newNotifications" :key="notification.uuid">
                <notification :item="notification"/>
              </b-dropdown-item>
              <b-dropdown-divider v-if="newNotifications.length > 0 && earlierNotifications.length > 0"></b-dropdown-divider>
              <b-dropdown-text v-if="newNotifications.length > 0 && earlierNotifications.length > 0">Earlier</b-dropdown-text>
              <b-dropdown-item v-for="notification in earlierNotifications" :key="notification.uuid"
                               class="notifications-earlier">
                <notification :item="notification"/>
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-form @submit.stop.prevent class="text-center" v-if="notifications.has_next">
                <div @click="onLoadMoreNotificationsClick()"><b>Load more...</b></div>
              </b-dropdown-form>
            </template>
          </b-dropdown>
        </div>
        <button class="user login-btn btn-hover " @click="openAuthModal()" v-if="!currentUser">
          <img src="../../assets/icons/user.svg" alt="user"/> Log in
        </button>
        <b-dropdown variant="outline-none" right class="user login-btn" v-else>
          <template #button-content>
            <b-avatar class="user-avatar-img"
                      v-if="currentUser"
                      :src="currentUser.avatar_url ? currentUser.avatar_url : require('../../assets/icons/user.svg') "
                      alt="user"/>
            <div class="user-name">
              @{{ computedUsername }}
            </div>
          </template>
          <b-dropdown-item class="color-dropdown">
            <div class="d-flex position-relative" id="color-dropdown"
                 @click.stop="isColorDropdownVisible = !isColorDropdownVisible">
              <b-icon icon="circle-fill" :style="{color: profileColor, fill:profileColor}" aria-hidden="true"
                      class="cameleon color-picker-icon ml-1 mr-1"></b-icon>
              <label class="ml-3 mr-3">Profile color</label>
              <div class="color-palette" v-if="isColorDropdownVisible">
                <b-icon v-for="(c, index) in colorPalette" icon="circle-fill" aria-hidden="true"
                        :style="{color:c.color}"
                        :key="index" @click="setColor(c.color)"></b-icon>
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item :to="{name:'user', params:{id: currentUser.username}}">
            <svg xmlns="http://www.w3.org/2000/svg" id="Component_43_2" width="30" height="30" viewBox="0 0 40 40"
                 :style="{color: profileColor, fill:profileColor}">
              <path id="Rectangle_2022" fill="none" d="M0 0H40V40H0z"/>
              <g id="Group_4123" transform="translate(11.816 9.869)">
                <ellipse id="Ellipse_12" cx="5.028" cy="5.228" class="cls-2" rx="5.028" ry="5.228"
                         transform="translate(3.156 0)"/>
                <path id="Path_2"
                      d="M-168.219 351.328c-6.811 0-7.982 6.841-8.181 8.934a.565.565 0 0 0 .586.6h15.191a.565.565 0 0 0 .586-.6c-.2-2.093-1.371-8.934-8.182-8.934z"
                      class="cls-2" transform="translate(176.402 -340.871)"/>
              </g>
            </svg>
            <label class="ml-3 mr-3">Profile</label>
          </b-dropdown-item>
          <b-dropdown-item @click="setActiveTab('liked-gifs')"
                           :to="{ name:'profile.liked-gifs', params:{id:currentUser.username}}">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40"
                 :style="{color: profileColor, fill:profileColor}">
              <path fill="none" d="M0 0H40V40H0z"/>
              <path
                d="M-21.965 916.035a6.575 6.575 0 0 0-2.618-2.4 6.263 6.263 0 0 0-3.809-.7 6.625 6.625 0 0 0-3.515 1.652c-.117.1-.233.21-.369.334a.873.873 0 0 0-.085-.1 6.419 6.419 0 0 0-4.865-1.917 6.243 6.243 0 0 0-4.27 1.841 6.287 6.287 0 0 0-1.9 5.429 6.455 6.455 0 0 0 1.961 3.9c2.406 2.4 4.254 4.251 6.394 6.394l1.527 1.532.1.1a1.579 1.579 0 0 0 1.129.487h.018a1.581 1.581 0 0 0 1.121-.462l.2-.2 2.322-2.323c1.847-1.848 3.471-3.472 5.606-5.61a6.249 6.249 0 0 0 1.939-4.508 6.584 6.584 0 0 0-.886-3.449z"
                transform="translate(52.268 -902.734)"/>
            </svg>
            <label class="ml-3 mr-3">Liked</label></b-dropdown-item>
          <b-dropdown-item @click="setActiveTab('collection')"
                           :to="{ name:'profile.collection', params:{id:currentUser.username}}">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40"
                 :style="{color: profileColor, fill:profileColor}">
              <path fill="none" d="M0 0H40V40H0z"/>
              <path
                d="M915.782 899.445v-1.216-6.08a.98.98 0 0 0-1.087-1.086h-16.512a.981.981 0 0 0-1.119 1.114v19.188a1.131 1.131 0 0 0 .55 1.078.9.9 0 0 0 .432.117 1.267 1.267 0 0 0 .765-.336l.01-.008q3.054-2.377 6.125-4.76l1.482-1.151.084.065 1.377 1.071c2.015 1.566 4.1 3.186 6.171 4.8a1.069 1.069 0 0 0 1.176.187 1.021 1.021 0 0 0 .549-1q-.003-5.99-.003-11.983z"
                transform="translate(-886.422 -881.92)"/>
            </svg>
            <label class="ml-3 mr-3">Collections</label>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item to="/account">
            <b-icon icon="gear" aria-hidden="true" class="cameleon color-picker-icon ml-1 pl-1 mr-2" size="3rem"></b-icon>
            <label class="ml-2 mr-3">Account Settings</label>
          </b-dropdown-item>
<!--          <b-dropdown-item to="/documentation/privacy" @click="setActiveTab('privacy')">-->
<!--            <img src="../../assets/icons/privacy.svg" alt="privacy" class="help-privacy"/>-->
<!--            <label class="ml-3 mr-3">Privacy</label>-->
<!--          </b-dropdown-item>-->
<!--          <b-dropdown-item to="/documentation/terms" @click="setActiveTab('terms')">-->
<!--            <img src="../../assets/icons/help.svg" alt="help" class="help-privacy"/>-->
<!--            <label class="ml-3 mr-3">Terms</label>-->
<!--          </b-dropdown-item>-->
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item class="pl-5" @click="onLogOutClick()"><strong>Log out</strong></b-dropdown-item>
        </b-dropdown>
        <b-dropdown class="hamburger" right variant="outline-none" no-caret>
          <template #button-content>
            <img src="../../assets/icons/humburger.svg" alt="menu" class="hamburger-img"/>
          </template>
          <b-dropdown-item to="/extensions">
            <div class="d-flex align-items-center"><img src="../../assets/icons/extensions.svg" alt="extensions"/>
              <div class="px-3">Extensions</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item to="/documentation" @click="setActiveTab('about')">
            <div class="d-flex align-items-center"><img src="../../assets/icons/documents.svg" alt="documents"/>
              <div class="px-3">Documentation</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item :to="{name:'api'}">
            <div class="d-flex align-items-center">
              <b-icon icon="journal-bookmark" class="api-reference"></b-icon>
              <div class="px-3">API</div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="responsive-sticky for-mobile-flex mobile-menu" v-if="showMenu">
      <div class="home-btn-res" @click="clearFilter(true)">
        <img src="../../assets/images/home-light.svg" alt="home" v-if="!home">
        <img src="../../assets/icons/home.svg" alt="home" v-else>
      </div>
      <div class="search-btn-res" @click="openSearch()">
        <img src="../../assets/images/search_opened.svg" v-if="searchIsOpen" alt="search">
        <img src="../../assets/images/search_closed.svg" alt="search" v-else>
      </div>
      <div class="create-btn-res" @click="onCreateOptionsModal()">
        <div class="create"
             :style="showCreateOptionsModal? { background:profileColor , transform: 'scale(1.5)'} : {background:profileColor}">
          <div>+</div>
        </div>
      </div>
      <div class="notifications-res" @click="showNotifications()">
        <img class="bell-icon" src="../../assets/images/bell_opened.svg" alt="bell" v-if="!notificationOpen"/>
        <img class="bell-icon" src="../../assets/images/bell_closed.svg" alt="bell" v-else/>
        <div v-if="notificationsTotal" class="notifications-badge" :style="{background:profileColor}">
          {{ notificationsTotal }}
        </div>
      </div>
      <div class="profile-res">
        <img class="user-avatar-img"
             v-if="currentUser && currentUser.avatar_url"
             :src="currentUser.avatar_url"
             alt="user" @click="showProfile()"/>
        <img v-else-if="currentUser && !currentUser.avatar_url"
             :src="!profileOpened ? require('../../assets/images/profile_opened.svg') : require('../../assets/images/profile_closed.svg') "
             alt="user" @click="showProfile()"/>
        <img v-else src="../../assets/images/profile_opened.svg"
             alt="user" @click="openAuthModal()"/>
      </div>
      <div class="create-options" v-if="showCreateOptionsModal">
        <div class="upload-btn-wrapper">
          <div class="card-container">
            <div class="upload-card">
              <span>Upload from gallery</span>
            </div>
          </div>
          <input type="file" name="file" @change="onFileChange"/>
        </div>
        <hr>
        <div class="upload-btn-wrapper" @click="onShowYoutubeUrlModal()">
          <div class="card-container">
            <div class="upload-card">
              <span>Paste Url</span>
            </div>
          </div>
        </div>
      </div>
      <youtube-url-modal v-show="youtubeUrlModal.show"/>
    </div>
    <div class="notification-container-res for-mobile" v-if="notificationOpen">
      <responsive-header title="Notifications" @backClick="closeNotifications"/>
      <div class="no-notifications" v-if="notifications && !notifications.data.length">
        <div class="text">No one cares about you</div>
        <img src="../../../src/assets/icons/forever_alone.png" alt="forever_alone"/>
      </div>
      <div class="notifications-inner" v-else>
        <div class="text" v-if="newNotifications.length > 0">New</div>
        <div v-for="notification in newNotifications" :key="notification.uuid">
          <notification :item="notification" @closeNotifications="closeNotifications"/>
        </div>
        <hr v-if="newNotifications.length > 0 && earlierNotifications.length > 0">
        <div class="text" v-if="newNotifications.length > 0 && earlierNotifications.length > 0">Earlier</div>
        <div v-for="notification in earlierNotifications" :key="notification.uuid" class="notifications-earlier">
          <notification :item="notification" @closeNotifications="closeNotifications"/>
        </div>
      </div>
      <div v-if="notifications.has_next" class="text-center mt-3"
           @click="onLoadMoreNotificationsClick()"><b>Load more...</b></div>
    </div>
    <search v-if="searchIsOpen" @onSearchClick="openSearch"/>
  </div>

</template>

<script>
  import {mapActions, mapState} from "vuex";
  import Notification from "./Notification";
  import YoutubeUrlModal from "../MobileDeviceComponents/YoutubeUrlModal";
  import ResponsiveHeader from "../MobileDeviceComponents/ResponsiveHeader";
  import Search from "../MobileDeviceComponents/Search";

  export default {
    name: "HeaderSettings",
    components: {Search, ResponsiveHeader, YoutubeUrlModal, Notification},
    data() {
      return {
        isColorDropdownVisible: false,
        showCreateOptionsModal: false,
        searchText: '',
        error: '',
        url: '',
        loading: false,
        searchIsOpen: false,
        isLoading: false,
        notificationOpen: false,
        profileOpened: false,
        home: true,
      }
    },
    methods: {
      ...mapActions(['openAuthModal',
        'logOut',
        'setActiveTab',
        'getTrends',
        'updateFilter',
        'clearTrends',
        'updateFilterError',
        'updateHideHeader',
        'showYoutubeUrlModal',
        'setProfileColor',
        'getNotifications',
        'clearNotifications',
        'seeRenderedNotifications',
        'getNotificationsTotal',
        'disableScroll']),
      setColor(color) {
        this.setProfileColor(color);
        localStorage.setItem('profileColor', this.profileColor)
      },
      async filterTrends() {
        window.scrollTo({left: 0, top: this.memeResultsTopPos, behavior: "smooth"});
        if (this.searchText) {
          this.$router.replace({name: 'query.view', params: {query: this.searchText}});
        } else {
          this.updateFilter({searchText: ''})
          if (this.$route.path !== '/') {
            this.$router.path = '/';
            this.$router.push({name: 'main'});
          }
        }
      },
      onCreateClick() {
        if (!this.currentUser) {
          this.openAuthModal();
        } else {
          if (this.$route.path !== '/create') {
            this.$router.path = '/';
            this.$router.push({name: 'create'});
          }
        }
      },
      async onLogOutClick() {
        let res = await this.logOut()
        if (res.result) {
          localStorage.removeItem('token')
          if (this.$route.path !== '/') {
            this.$router.path = '/';
            await this.$router.push({name: 'main'});
          }
        }
        /*global gapi*/
        let auth2;
        if (gapi && gapi.auth2) {
          auth2 = gapi.auth2.getAuthInstance();
          auth2.signOut()
        }
      },
      clearFilter(isHomeBtnPress) {
        if (this.home && isHomeBtnPress) {
          this.$router.push({name: 'main'});
        }
        this.updateHideHeader(false)
        this.home = true;
        this.searchIsOpen = false;
        this.profileOpened = false;
        this.notificationOpen = false;
        this.showCreateOptionsModal = false;
        this.$emit('onHomeClick')
      },

      onCreateOptionsModal() {
        this.home = true
        this.searchIsOpen = false;
        this.profileOpened = false;
        this.notificationOpen = false;
        this.disableScroll(false)
        if (!this.currentUser) {
          this.openAuthModal();
        } else {
          this.showCreateOptionsModal = !this.showCreateOptionsModal;
        }
      },
      onFileChange(e) {
        this.showCreateOptionsModal = false
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.$router.path = '/';
        this.$router.push({name: 'preview', params: {files: files}});
      },
      onShowYoutubeUrlModal() {
        this.showCreateOptionsModal = false
        this.showYoutubeUrlModal(true)
      },
      openSearch() {
        // if (this.$route.name !== 'main') {
        //   this.$router.path = '/';
        //   this.$router.push({name: 'main'});
        // }
        this.profileOpened = false;
        this.showCreateOptionsModal = false;
        this.showYoutubeUrlModal(false)
        this.notificationOpen = false;
        this.searchIsOpen = !this.searchIsOpen;
        this.home = !this.searchIsOpen;
        if (this.searchIsOpen) {
          this.updateHideHeader(true)
          this.disableScroll(true)
        } else {
          this.updateHideHeader(false)
          this.disableScroll(false)
        }
      },
      showProfile() {
        this.disableScroll(false)
        this.searchIsOpen = false;
        this.notificationOpen = false;
        this.profileOpened = !this.profileOpened;
        this.home = !this.profileOpened;

        if (this.profileOpened) {
          if (this.$route.name === 'profile.gallery') {
            return
          }
          this.$router.path = '/';
          this.$router.push({name: 'user', params: {id: this.currentUser.username}});

        } else {
          this.$router.path = '/';
          this.$router.push({name: 'main'});
        }

      },
      async loadNotifications(page = 1, per_page = 10) {
        if (this.isLoading || !this.notifications.has_next) {
          return
        }

        this.isLoading = true;
        let res = await this.getNotifications({page, per_page})
        if (res.result) {
          this.isLoading = false;
        } else {
          this.error = 'error!'
        }
      },
      onLoadMoreNotificationsClick() {
        this.loadNotifications(this.notifications.current_page + 1)
      },
      closeNotifications() {
        this.updateHideHeader(false)
        this.notificationOpen = false;
      },
      showNotifications() {
        this.searchIsOpen = false;
        this.profileOpened = false;
        if (!this.currentUser) {
          this.openAuthModal()
          return
        }
        this.notificationOpen = !this.notificationOpen
        this.home = !this.notificationOpen;
        if (this.notificationOpen) {
          this.disableScroll(true)
          this.updateHideHeader(true)
        } else {
          this.disableScroll(false)
          this.updateHideHeader(false)
        }
        if (this.notifications && this.notifications.data.length) {
          let toSeeNotifications = this.notifications.data.filter(i => !i.seen).map(n => n.uuid)
          if (toSeeNotifications.length) {
            this.seeRenderedNotifications({notifications: toSeeNotifications})
            this.getNotificationsTotal()
          }
        }
      },
    },
    computed: {
      ...mapState(["currentUser", "youtubeUrlModal", 'profileColor', 'colorPalette', 'showMenu', 'notificationsTotal', 'notifications', 'memeResultsTopPos', 'openAppSticky']),
      ...mapState({
        stateSearchText: state => state.filter.searchText
      }),
      newNotifications() {
        return this.notifications.data.filter((n) => !n.seen)
      },
      earlierNotifications() {
        return this.notifications.data.filter((n) => n.seen)
      },
      computedUsername() {
        if (this.currentUser) {
          if (this.currentUser.username) {
            return this.currentUser.username.length <= 7 ? this.currentUser.username : this.currentUser.username.slice(0, 7).concat('..')
          }
        }
        return ''
      }
    },
    watch: {
      currentUser: {
        handler() {
          if (this.currentUser) {
            this.clearNotifications();
            this.loadNotifications();
          }
        },
        immediate: true,
      },
      notificationsTotal() {
        if (this.notificationsTotal) {
          this.clearNotifications()
          this.loadNotifications();
        }
      },
      '$route.name': {
        handler() {
          if (this.$route.name !== 'profile.gallery') {
            this.profileOpened = false;
          }
        }
      },
      $route(to, from) {
        if (from.name === 'main') {
          this.searchText = ''
        }
      },
      stateSearchText(val) {
        this.searchText = val;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../style/index';


  .settings-container {
    display: flex;
  }

  .api-reference{
      width: 40px;
      height: 30px;
  }

  .logo {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #f7dc3c;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .right-space {
    margin-right: 15px;
  }

  .notifications {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    background-color: #1a1a1a;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    /deep/ .dropdown-menu {
      transform: translate3d(-85%, 35px, 0px) !important
    }


    /deep/ button {
      background: none;
      width: 100%;
      border: none;
      height: 100%;
      border-radius: 50%;
    }

    /deep/ ul {
      height: 450px;
      overflow: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #e9ecef;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #e9ecef;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #c2c2c2;
      }
    }

    /deep/ .b-dropdown-text {
      font-family: "Open Sans", sans-serif;
      font-size: 0.75rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.35;
      letter-spacing: normal;
      text-align: left;
    }

    .no-notifications {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      /deep/ .text {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #707070;
      }
    }

    .notifications-inner {
      max-height: 550px;
      overflow: auto;
    }

    .bell-icon {
      width: 30px;
      height: 30px;
      position: absolute;
    }

    .notifications-badge {
      color: #ffffff;
      border-radius: 50%;
      text-align: center;
      width: 30px;
      height: 30px;
      justify-content: center;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      right: -0.5rem;
    }

    .notifications {
      /deep/ .b-dropdown-text {
        font-size: 0.75rem;
      }
    }
  }

  .login-btn,
  .create-btn,
  {
    height: 50px;
    width: 180px;
    border: 0;
    border-radius: 32px;
    color: #ffffff;
    text-align: center;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    background-color: #1a1a1a;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
  }

  .login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user {
    background: #1a1a1a;

    .user-name {
      text-transform: uppercase;
      font-weight: bold;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      margin-top: 3px;
      white-space: nowrap;
    }

    .user-avatar-img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 10px;
      margin-left: 10px;
    }

    /deep/ .btn {
      color: white !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /deep/ .dropdown-toggle {
      &:after {
        border-top: 0.5em solid;
        margin-left: 30px;
        //margin-top: -3px;
        margin-right: 10px;
        margin-top: 2px;
      }
    }

    #color-dropdown {
      /deep/ .btn {
        color: unset !important;
      }
    }

    .color-picker-icon {
      margin: 0 10px
    }

    .color-dropdown {
      &:hover {
        background: unset !important;
        opacity: unset !important;
      }

      .color-palette {
        background: #ffffff;
        width: 100%;
        top: 2rem;
        border-radius: 13px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
        position: absolute;
        z-index: 3;
        padding: 1rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        gap: 10px;

        svg {
          &:hover {
            border: 2px solid black;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .hamburger {
    margin-left: 15px;
    width: 50px;
    height: 50PX;
    border-radius: 50%;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;

    .dropdown-toggle {
      background-color: #ffffff !important;
    }

    .hamburger-img {
      position: absolute;
      right: 3px;
      top: 3px;
      width: 40px;
    }
  }

  /deep/ .dropdown-menu.show {
    top: 2rem !important;
    padding: 0.5em 0;
    border-radius: 12px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  }

  .help-privacy {
    width: 12px;
    margin: 5px 5px 5px 10px;
  }


  @media only screen and (max-width: 1600px) {
    .search {
      //width: 543px;

      img {
        height: 30px;
      }
    }

    .search,
    {
      input {
        height: 40px;
      }
    }

    .login-btn,
    .create-btn,
    .user {
      height: 40px;
      width: 190px;
    }
    .logo {
      height: 40px;
      width: 40px;
      min-width: 40px;
      max-width: 40px;
    }

    .hamburger {
      width: 40px;
      height: 40PX;

      .hamburger-img {
        right: 0;
        top: 0;
        width: 40px;
      }
    }
    .notifications {
      width: 40px;
      height: 40px;

      .bell-icon {
        width: 30px;
        height: 30px;
      }

      .notifications-badge {
        width: 20px;
        height: 20px;
      }
    }
    .user {
      /deep/ .dropdown-toggle {
        &:after {
          margin-left: 15px;
        }
      }
    }
    .right-space {
      margin-right: 7px;
    }
  }

  @media only screen and (max-width: 1200px) {

    .login-btn,
    .create-btn,
    .user {
      width: 175px
    }
    .user {
      .user-avatar-img {
        margin-right: 7px;
      }
    }
    .right-space {
      margin-right: 7px;
    }
  }

  @media only screen and (max-width: 1024px) {

    .settings-container {
      .search {
        input {
          height: 40px;
        }

        img {
          height: 35px;
        }
      }

      .login-btn,
      .create-btn,
      .user {
        height: 40px;
        width: 187px
      }

      .search {
        //width: 299px;
      }

      .logo {
        height: 40px;
        width: 40px;
        min-width: 40px;
        max-width: 40px;
      }

      .hamburger {
        width: 40px;
        height: 40px;

        .hamburger-img {
          width: 30px;
          right: 3px;
          top: 2px;
        }
      }

      .notifications {
        width: 40px;
        height: 40px;

        .bell-icon {
          width: 20px;
          height: 20px;
        }

        .notifications-badge {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .notifications {
      width: 40px;
      height: 40px;
    }
    .settings-container {
      .login-btn,
      .create-btn,
      .user {
        width: 150px;
        font-size: 16px;
      }

      .user {
        .user-name {
          font-size: 12px;
        }

        .user-avatar-img {
          width: 25px;
          height: 25px;
        }
      }

      .search {
        margin-right: 0.5rem;

        .search-icon {
          right: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .settings-container {
      .search {

        input {
          height: 30px;
        }

        img {
          height: 25px;
        }

        .search-icon {
          width: 20px;
        }
      }

      .search {
        //width: 185px;
      }

      .login-btn,
      .create-btn,
      .user {
        height: 30px;
        width: 90px;
      }

      .logo {
        height: 30px;
        width: 30px;
        min-width: 30px;
        max-width: 30px;
      }

      .notifications {
        height: 30px;
        width: 30px;

        .notifications-badge {
          width: 15px;
          height: 15px;
        }
      }

      .user {
        /deep/ .btn {
          padding: 0;
        }

        .user-name {
          display: none;
        }

        .user-avatar-img,
        img {
          width: 20px;
          height: 20px;
        }
      }

      .hamburger {
        height: 30px;
        width: 30px;
        margin-left: 5px;

        .hamburger-img {
          right: 3px;
          top: 3px;
          width: 20px;
        }
      }

    }
  }

  @media only screen and (max-width: 576px) {
    .settings-container {

      margin: 0 1rem;

      .right-settings {
        justify-content: space-between;
      }

      .search {
        padding: 0;
        margin-bottom: 0.5rem;

      }

      .login-btn,
      .create-btn,
      .user {
        width: 65px;
        font-size: 12px;
      }


      .user {
        .user-name {
          font-size: 12px;
        }

        .user-avatar-img {
          width: 25px;
          height: 25px;
        }

        /deep/ .dropdown-toggle {
          &:after {
            margin-left: 0;
          }
        }

      }

      .user-settings {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        margin-left: 0;

        .user {
          margin: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 428px) {

    .notifications-badge {
      color: #ffffff;
      border-radius: 50%;
      text-align: center;
      width: 15px;
      height: 15px;
      justify-content: center;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 12px;
    }

    .notification-container-res {
      position: fixed;
      bottom: 0;
      background: #ffffff;
      z-index: 1;
      width: 100%;
      padding: 15px;
      margin-right: -10px;
      margin-left: -10px;
      max-height: 100%;
      overflow: auto;
      height: 100%;

      .notifications-inner {
        margin-top: 60px;
      }

      .no-notifications {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .text {
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-weight: 500;
          color: #707070;
        }
      }

      .text {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.13;
        letter-spacing: normal;
        text-align: left;
      }
    }

    .mobile-menu {
      z-index: 3;
    }
  }

  @-webkit-keyframes scaling {
    From {
      -webkit-transform: scale(1.0);
    }
    To {
      -webkit-transform: scale(1.05);
    }
  }
  ::v-deep .notifications-earlier {
    opacity: 0.7;
  }

  .notification-container-res.for-mobile {
    z-index: 5;
  }

</style>
