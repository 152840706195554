<template>
  <div class="documentation">
    <h3>KIKLIKO Community Guidelines</h3>
    <p>
      Last Updated Nov 8, 2021<br><br>

      KIKLIKO.COM is dedicated to providing a fun and safe way to search, create, and share the best Clips & Memes on
      the internet. Our Clips & Memes, once indexed, can be seen by anybody using KIKLIKO.COM Website, Mobile Apps or
      Browser Extension; therefore, it’s important to be mindful of the type of Clips & Memes that are appropriate for
      the platform. To do that, we’ve set up a few guidelines that we ask you follow. Thanks for helping us make KIKLIKO
      a positive experience for everyone!<br><br>

      <b>KIKLIKO Golden Rules:</b><br><br>

      Keep Clips & Memes Friendly: The purpose of Clips & Memes is to add a bit of fun to our online lives, so keep
      your Clips & Memes friendly. Do not create or upload any Clips & Memes meant to hurt another
      individual.<br><br>

      Respect Privacy: It’s important to respect and honor the privacy and consent of the individuals depicted in your
      Clips & Memes. Make sure you’re authorized to use any video or images from the appropriate people before you use
      them to make a Clips & Memes.<br><br>

      Credit the Community: People work hard to make their Clips & Memes, and we want to make sure they get their
      proper dues. Help us support the community by using KIKLIKO'S share tools to make sure that the original creator
      is getting credit for the work.<br><br>

      Sharing: Sharing is great. Please use KIKLIKO's embed options, available on each Clips & Memes individual page,
      for proper sharing on your blog or social media accounts. Before you use Clips & Memes you find on KIKLIKO or
      anywhere else on the web, you'll want to make sure you're comfortable that your use complies with any laws
      applicable where you're located.<br><br>

      <b>Clips & Memes that are not allowed on KIKLIKO:</b><br><br>

      Pornography & Explicit Nudity: You can’t upload anything that depicts any form of sex act or other sexually
      explicit material. Straight up: Your Clips & Memes can’t show any explicit nudity at all. Sorry! In general, if
      it’s not something that you’d show your family, don’t upload or create it on KIKLIKO.<br><br>

      Violence: We don’t mind Clips & Memes that show fictional violence (in other words, pretend play where no one
      actually gets hurt), but if it’s real, then it’s not allowed. Any Clips & Memes that portray real death,
      mutilation, gore, or assault are not permitted.<br><br>

      Self-Harm: You can’t create, upload, or share anything that encourages or promotes self-harm. This includes
      activities or behavior related to suicide, self-mutilation, or eating disorders.<br><br>

      Animal Cruelty: Absolutely no Clips & Memes of animals being harmed or abused are permitted. We love
      animals!<br><br>

      Bullying, Stalking, Harassment, or Threats: You can’t create, upload, or share Clips & Memes to bully, harass,
      or threaten another individual.<br><br>

      Hate Speech: Clips & Memes are meant for everyone, so we don’t allow any that slander, demean, or belittle
      another group or person based upon their race, religion, gender, or sexual orientation. Those are a definite
      no.<br><br>

      Illegal Activity: Don’t create, upload, or share Clips & Memes of illegal activity on KIKLIKO. You also can’t
      use KIKLIKO to engage in or promote illegal activity.<br><br>

      Private Information: You can’t create, upload, or share Clips & Memes that reveal any private or sensitive
      information about another person. This includes things such as addresses, phone numbers, passwords, and anything
      else that could be considered personal.<br><br>

      Spam: Making Clips & Memes that advertise your businesses for your own marketing purposes is perfectly fine;
      however, you cannot use inaccurate tags to try to make your Clips & Memes appear in search results that are not
      related to your business or promotion.<br><br>

      DMCA: If you’ve had your copyrighted material used to create a Clips & Memes on KIKLIKO without permission,
      please follow the DMCA guidelines.

      Violating any of these rules may result in the removal of your content, the suspension of your account, or you may
      be banned from using KIKLIKO. We don’t like doing that, so please respect these rules and help us keep KIKLIKO an
      awesome place for all of our community.<br><br>

      <b>To Flag a Clips & Memes you think is violating these guidelines:</b><br><br>

      Above every Clips & Memes on KIKLIKO.com, if you would like to report a Clips & Memes or user that is acting
      inappropriately, you can contact us at info@kikliko.com<br><br>

      <b>Have fun!</b><br><br>

      <b>The KIKLIKO.com Team</b><br><br>
    </p>
  </div>
</template>

<script>
  export default {
    name: "CommunityGuidelines",
    metaInfo() {
      return {
        title: 'Community Guidelines',
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `Community guidelines of KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`
          },
        ],
      };
    },
  }
</script>

<style scoped>

</style>
