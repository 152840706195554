<template>
  <div class="documentation">
    <h3>About</h3>
    <p>
      Last Updated Nov 8, 2021<br><br>

      KIKLIKO.COM is worlds first platform of Clips & Memess. Here you can find 3-10 second moments from popular movies,
      tv shows or videos, which you can share directly to any messenger using our iOS & Android mobile App,
      Website or our Browser Extension, which allows you to watch/listen to our content straight from the website,
      in which you are messaging or scrolling.

      <br><br>
      KIKLIKO.COM was founded 3 entrepreneurs from European country Georgia, Tbilisi who are:
      nominees of Forbes 30under30, Board Members of UN Global Compact Network and Advisors tech
      faculties at universities. The main vision is to change the way we people express feelings
      in social media by using Clips & Memes.
      <br><br>

      Feel free to contact us at: info@kikliko.com
    </p>
  </div>
</template>

<script>
  export default {
    name: "About",
    metaInfo() {
      return {
        title: 'About Us',
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `About KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`
          },
        ],
      };
    },
  }
</script>

<style lang="scss" scoped>

</style>
