import router from '../router';

class Helper {
    constructor() {
        // Do stuff
    }

    getMemeUrl(slug) {
        let route = router.resolve({name: 'meme.view', params: {id: slug}})
        return new URL(route.href, window.location.origin).href;
    }

    get404Url() {
        let route = router.resolve({name: 'error.404'})
        return new URL(route.href, window.location.origin).href;
    }

    redirectTo404() {
        window.location.href = this.get404Url()
    }

    apiUrl(path = '', withPrefix = true) {
        let url = process.env.VUE_APP_API_BASE_URL
        if (!withPrefix) {
            url = (new URL(url)).origin
        }
        return `${url}${path}`
    }
}

// Usage:
// this.$helper.getMemeUrl('slug-1');
export default {
    install(Vue) {
        Vue.prototype.$helper = new Helper();
    },
};
