<template>
  <div class="documentation">
    <h3>Privacy</h3>
    <p>
      Last Updated Nov 8, 2021<br><br>

      This Privacy Policy describes the policies and procedures of KIKLIKO, Inc. (“we”, “our” or “us”) on the
      collection, use and disclosure of your information in connection with your use of www.KIKLIKO.com (the “Site”),
      our mobile applications, and the services, features, or content we offer (collectively with the Site, the
      “Services”). We receive information about you from various sources, including: (i) if you register for an account
      on the Services, through your user account (your “Account”); (ii) your use of the Services generally; and (iii)
      from third party websites and services. When you use the Services, you are consenting to the collection, transfer,
      manipulation, storage, disclosure and other uses of your information as described in this Privacy Policy.<br><br>

      <b>What Does This Privacy Policy Cover?</b><br><br>

      This Privacy Policy covers the treatment of personally identifiable information (“Personal Data”) gathered when
      you are using or accessing the Services. This Privacy Policy also covers our treatment of any Personal Data that
      our business partners share with us or that we share with our business partners.<br><br>

      This Privacy Policy does not apply to the practices of third parties that we do not own or control, including but
      not limited to any third party websites, services and applications (each a “Third Party Service”) that you elect
      to access through the Service or to individuals that we do not manage or employ. While we attempt to facilitate
      access only to those Third Party Services that share our respect for your privacy, we cannot take responsibility
      for the content or privacy policies of those Third Party Services. We encourage you to carefully review the
      privacy policies of any Third Party Services you access.<br><br>

      <b>What Information Do We Collect About You?</b><br><br>

      We collect Personal Data about you when you provide such information directly to us, when third parties such as
      our business partners or service providers provide us with Personal Data about you, or when Personal Data about
      you is automatically collected in connection with your use of our Services. The information we gather enables us
      to improve, understand, and continue to operate the Services. In connection with certain aspects of the Services,
      we may request, collect and/or display some of your Personal Data. Below explains in greater detail the types of
      information that we collect about our users.<br><br>

      <b>Information You Provide to Us</b><br><br>

      Account Information:<br><br>

      If you create an Account, register for a brand or artist channel, or register for a verified user profile, or have
      registered for Electric Objects, you will provide information that could be Personal Data, such as your email
      address, username, contact name, password, display name (for artist channel) and art preferences (for Electric
      Objects). You may also choose to provide additional Personal Data, including an avatar/profile picture,
      information about yourself, basic demographic information, a description of your channel, a brand parent company
      (brand or artist channel), location (brand or artist channel), your KIKLIKO Channel URL (for verified user
      profile), attachments to your application (for verified user profile) and links to your website or social media
      profiles.<br><br>

      Additionally, if you register for or access the Services using a Third Party Service (such as your social media
      account login credentials), we may receive Personal Data that you have made available to share through such Third
      Party Service, which may include, without limitation, your name. You may also choose to include your email
      address. You acknowledge that this information may be personal to you, and by creating an Account and providing
      Personal Data to us, you allow others, including us, to identify you and therefore may not be anonymous. We may
      use your contact information to send you information about our Services.<br><br>

      User Content:<br><br>

      Some features of the Services allow you to provide content to the Services, and related Personal Data if you so
      choose. User content includes, but are not limited to, Clips & Memes, written comments, links, and pictures. All
      content submitted by you to the Services and related data (e.g., technical specifications of a GIF with Sound) may
      be retained by us indefinitely, even after you terminate your Account or your access to the Services is
      terminated. We may continue to disclose such content to third parties in a manner that does not reveal Personal
      Data, as described in this Privacy Policy.<br><br>

      <b>Information From Others</b><br><br>

      As mentioned above, you may be able to register for or access the Services using a Third Party Service, such as
      your social media account (e.g. Facebook, Twitter, etc.). Occasionally, you can also use your Account on our
      Services to interact with your accounts on these other Third Party Services. For example, you may be able to
      access posting and sharing tools on the Services that allow you to post information to your social networks
      outside of the Services (“Share”). By using these tools or Third Party Services, you acknowledge that some
      information (such as your IP address, language preferences, timestamp and identifiers related to your request) may
      be transmitted to us, and that such information and content is covered by this Privacy Policy. Furthermore, if a
      tool, such as the Share tool, is operated by a Third Party Service, the Third Party Service that operates the tool
      may collect information about your browser or online activity, which would be subject to the Third Party Service’s
      privacy policy and your account settings selected through that Third Party Service. When you use these tools, some
      of your information from the Services may be shared with the Third Party Service and others, including selected
      GIF with Sound. Therefore, we encourage you to read the privacy policies and other policies of any Third Party
      Services, including without limitation any applicable social networks, that you use in connection with the
      Services.<br><br>

      <b>Information Collected Automatically</b><br><br>

      We automatically receive and record information from your web browser or device when you interact with the
      Services, including when you search for, select, view or receive a GIF with Sound, such as your IP address, device
      ID, user query information and cookie information. This information is used to enable us to deliver GIFs with
      Sound to you, for fighting spam/malware, to improve the service and also to facilitate collection of data
      concerning your interaction with the Services (e.g., what links you have clicked on).<br><br>

      Generally, the Services automatically collect usage information, such as the number and frequency of visitors to
      the Site and the Services. We may use this data in aggregate form, for example, as a statistical measure or in
      other anonymous forms, but not in a manner that would identify you personally. This type of aggregate data enables
      us and third parties authorized by us to figure out how often individuals use parts of the Services so that we can
      analyze and improve them.<br><br>

      <b>Information Collected Using Cookies</b><br><br>

      • Cookies are pieces of text that may be provided to your computer or device through your web browser when you
      access a website. Your browser stores cookies in a manner associated with each website you visit. We may use
      cookies to enable our servers to recognize your web browser and tell us how and when you visit the Site and
      otherwise use the Services.<br><br>

      • Our cookies do not, by themselves, contain Personal Data. However, we may match cookies with your Personal Data
      to identify you, and we may use cookies to identify that your web browser has accessed aspects of the Services and
      may associate that information with your Account if you have one.<br><br>

      • We may allow third party web analytics service providers to use cookies or similar technologies to collect
      information about your use of the Services. We use Google Analytics, a service provided by Google, Inc. (“Google”)
      to help us understand how users use the Services and to enhance your experience when you use the Services. For
      more information on Google’s privacy practices, please go to
      https://policies.google.com/technologies/partner-sites and to opt out of data recording and analysis by Google
      Analytics on the Services, please visit https://tools.google.com/dlpage/gaoptout.<br><br>

      • Most browsers have an option for turning off the cookie feature, which will prevent your browser from accepting
      new cookies, as well as (depending on the sophistication of your browser software) allowing you to decide on
      acceptance of each new cookie in a variety of ways. We strongly recommend that you leave cookies active, because
      they enable you to take advantage of the most attractive features of the Services.<br><br>

      • This Privacy Policy covers our use of cookies only and does not cover the use of cookies by third parties. We do
      not control when or how third parties place cookies on your computer or device. For example, third party websites
      to which a link points may set cookies on your computer or device.<br><br>

      <b>Aggregate Information</b><br><br>

      We collect statistical information about how both unregistered and registered users, collectively, use the
      Services (“Aggregate Information”). Some of this information is derived from Personal Data. This statistical
      information is not Personal Data and cannot be tied back to you, your Account, or your web browser.<br><br>

      <b>Personal Data of Children</b><br><br>

      As noted in the Terms of Service, we do not knowingly collect or solicit Personal Data from anyone under the age
      of 13. If you are under 13, please do not attempt to register for the Services or send any Personal Data about
      yourself to us. If we learn that we have collected Personal Data from a child under age 13, we will delete that
      information as quickly as possible. If you believe that a child under 13 may have provided us Personal Data,
      please contact us at info@kikliko.com.<br><br>

      <b>Facial Scans</b><br><br>

      In order to use the facial scan feature through our mobile application, you must first read through our “Facial
      Scan Consent” and grant us permission by selecting the “I Accept” button on your mobile device. We only use your
      facial scans in connection with your use of the Services in order to provide, personalize, and improve the
      Services for you. We don’t use your facial scans or any related facial data captured in the facial scans
      internally, and we don’t share your facial scans or any related facial data with other users or any other third
      parties. We also do not store your facial scans and related facial data. Please read our “Facial Scan Consent” to
      learn more about our facial scans feature.<br><br>

      <b>How, and With Whom, Is My Information Shared?</b><br><br>

      The Services are designed to help you share information with others. As a result, some of the information
      generated through the Services is shared publicly or with Third Party Services. What information is shared
      publicly depends on your privacy settings. Below further describes the ways in which we may share your
      information. We do not sell your Personal Data.<br><br>

      <b>Public Information About You and Your Activity on the Services</b><br><br>

      User profile and channel information that you choose to provide, including your username, contact name, GIFs with
      Sound you upload, information about yourself, your avatar/profile picture, a description of your channel, and
      links to your website or social media profiles, may be displayed to other users to facilitate user interaction
      within the Services. This information will be displayed to other users depending on your privacy settings. We will
      not directly reveal user email addresses to other users.<br><br>

      Some of your activity on and through the Services is public by default. This may include, but is not limited to,
      content you have posted publicly on the Site or otherwise through the Services. For registered users, all content
      you post publicly (or privately) will be associated with your account. Unregistered users will not have this
      association, but information concerning their use of the Services (such as what pages they have visited) may be
      tracked through the use of cookies and stored by us.<br><br>

      Please remember that if you choose to provide Personal Data using certain public features of the Services, then
      that information is governed by the privacy settings of those particular features and may be publicly available.
      Individuals reading such information may use or disclose it to other individuals or entities without our control
      and without your knowledge, and search engines may index that information. We therefore urge you to think
      carefully about including any specific information you may deem private in content that you create or information
      that you submit through the Services.<br><br>

      <b>Information Shared Through Third Party Services</b><br><br>

      You may access other Third Party Services through the Services, for example by clicking on links to those Third
      Party Services from within our Services. We are not responsible for the privacy policies and/or practices of these
      Third Party Services, and you are responsible for reading and understanding those Third Party Services’ privacy
      policies. This Privacy Policy only governs information collected on the Services.<br><br>

      <b>Information Shared In Connection with Related Services</b><br><br>

      While we collect and store IP address and device ID information, that information is not made public. We do at
      times, however, share this information with our service providers and as otherwise specified in this Privacy
      Policy.<br><br>

      We also share Aggregate Information with our partners, service providers and other persons with whom we conduct
      business. We share this type of statistical data so that our partners can understand how and how often people use
      our Services and their services or websites, which facilitates improving both their services and how our Services
      interface with them. In addition, these third parties may share with us non-private, aggregated or otherwise non
      Personal Information about you that they have independently developed or acquired.<br><br>

      <b>Information Shared with Our Service Providers</b><br><br>

      We employ and contract with people and other entities that perform certain tasks on our behalf and who are under
      our control (our “service providers”). We may need to share Personal Data with our service providers in order to
      provide products or services to you. Unless we tell you differently, our service providers do not have any right
      to use Personal Data or other information we share with them beyond what is necessary to assist us. You hereby
      consent to our sharing of Personal Data with our service providers.<br><br>

      <b>Information Disclosed Pursuant to a Change in Control or Sale</b><br><br>

      In some cases, we may choose to buy or sell assets. In these types of transactions, user information is typically
      one of the transferred business assets. Moreover, if we, or substantially all of our assets, were acquired, or if
      we go out of business or enter bankruptcy, user information would be one of the assets that is transferred or
      acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of us or our
      assets may continue to use your Personal Data as set forth in this policy.<br><br>

      <b>Information Disclosed for Our Protection and the Protection of Others</b><br><br>

      We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is
      necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce this
      Privacy Policy and our Terms of Service, including investigation of potential violations hereof, (iii) detect,
      prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v)
      protect our rights, property or safety, our users and the public. This includes exchanging information with other
      companies and organizations for fraud protection and spam/malware prevention.<br><br>

      <b>What Choices Do I Have Regarding My Information?</b><br><br>

      Access and Control Your Personal Data<br><br>

      • If you are a registered user, you can access information associated with your Account by logging into the
      Services. Registered and unregistered users can access and delete cookies through their web browser settings.

      • You can use many of the features of the Services without registering, thereby limiting the type of information
      that we collect.

      • You can always opt not to disclose certain information to us, but please note that such information may be
      needed to take advantage of some of our features.

      • You can opt-out of certain cookies and tracking technologies by following the steps set forth in the section
      titled “Information Collected Using Cookies” above.

      • Your browser may offer you a “Do Not Track” or “DNT” option, which allows you to signal to operators of
      websites, and web applications, and services that you do not wish such operators to track certain of your online
      activities over time and across different websites.

      Delete Your Account<br><br>

      Should you ever decide to delete your Account, you may do so by emailing info@kikliko.com. Please note that we
      will need to verify that you have the authority to delete the Account. If you terminate your Account, any
      association between your Account and information we store will no longer be accessible through your Account.
      However, given the nature of sharing on the Services, any public activity on your Account prior to deletion will
      remain stored on our servers and may remain accessible to the public.<br><br>

      <b>How Long Do We Retain Your Personal Data?</b><br><br>

      We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to
      provide you Services. In some cases we retain Personal Data for longer, if doing so is necessary to comply with
      our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable
      law, rule or regulation. Afterwards, we retain some information in a depersonalized or aggregated form but not in
      a way that would identify you personally.<br><br>

      <b>Is the Information About Me Secure?</b><br><br>

      Your Account information will be protected by a password for your privacy and security. You need to prevent
      unauthorized access to your Account and Personal Data by selecting and protecting your password appropriately and
      limiting access to your computer and browser by signing off after you have finished accessing your Account.<br><br>

      We seek to protect Account information to ensure that it is kept private; however, we cannot guarantee the
      security of any Account information. We store all of our information, including your IP address information, using
      industry-standard techniques. We do not guarantee or warrant that such techniques will prevent unauthorized access
      to information about you that we store, Personal Data or otherwise. Unauthorized entry or use, hardware or
      software failure, and other factors, may compromise the security of user information at any time.<br><br>

      <b>What Happens When There Are Changes to this Privacy Policy?</b><br><br>

      We may amend this Privacy Policy from time to time. Use of information we collect now is subject to the Privacy
      Policy in effect at the time such information is collected. If we make changes in the way we collect or use
      information, we will notify you by posting an announcement on the Services or sending you an email. A user is
      bound by any changes to the Privacy Policy when he or she uses the Services after such changes have been first
      posted.<br><br>

      <b>What If I Have Questions or Concerns?</b><br><br>

      If you have any questions or concerns regarding privacy using the Services, please send us a detailed message to
      info@kikliko.com. We will make every effort to resolve your concerns.<br><br>

      <b>EU or UK users</b><br><br>

      If you are located in the European Union (“EU”), United Kingdom, Lichtenstein, Norway, or Iceland, you may have
      additional rights under the EU or UK General Data Protection Regulation (the “GDPR”) with respect to your Personal
      Data, as outlined below.<br><br>

      For this section, we use the terms “Personal Data” and “processing” as they are defined in the GDPR, but “Personal
      Data” generally means information that can be used to individually identify a person, and “processing” generally
      covers actions that can be performed in connection with data such as collection, use, storage and disclosure. We
      will be the controller of your Personal Data processed in connection with the Services. If there are any conflicts
      between this section and any other provision of this Privacy Policy, the policy or portion that is more protective
      of Personal Data shall control to the extent of such conflict. If you have any questions about this section or
      whether any of the following applies to you, please contact us at info@kikliko.com.<br><br>

      We will only process your Personal Data if we have a lawful basis for doing so. Lawful bases for processing
      include consent, contractual necessity and our “legitimate interests” or the legitimate interest of others, as
      further described below. The legal bases depend on the Services you use and how you use them.<br><br>

      • Contractual Necessity: We also process the following categories of Personal Data as a matter of “contractual
      necessity”, meaning that we need to process the data to perform under our Terms of Service with you, which enables
      us to provide you with the Services. When we process data due to contractual necessity, failure to provide such
      Personal Data will result in your inability to use some or all portions of the Services that require such data.<br><br>

      IP Address<br><br>

      Profile Data<br><br>

      Device ID<br><br>

      Cookie Data<br><br>

      • Legitimate Interest: We process the following categories of Personal Data when we believe it furthers the
      legitimate interest of us or third parties (which is not overridden by your data protection interests):

      IP Address<br><br>

      Profile Data<br><br>

      Device ID<br><br>

      Cookie Data<br><br>

      Examples of these legitimate interests include:<br><br>

      Operation and improvement of our business, products and services<br><br>

      Marketing of our products and services<br><br>

      Provision of user support<br><br>

      Protection from fraud or security threats<br><br>

      Compliance with legal obligations<br><br>

      Completion of corporate transactions<br><br>

      • Consent: In some cases, including facial scans, we may process Personal Data based on the consent you expressly
      grant to us at the time we collect such data. When we process Personal Data based on your consent, it will be
      expressly indicated to you at the point and time of collection.

      • Other Processing Grounds: From time to time we may also need to process Personal Data to comply with a legal
      obligation, if it is necessary to protect the vital interests of you or other data subjects, or if it is necessary
      for a task carried out in the public interest.

      • Personal Data of Children: We do not knowingly collect or solicit Personal Data from anyone under the age of 16.
      If you are under 16, please do not attempt to register for the Services or send any Personal Data about yourself
      to us. If we learn that we have collected Personal Data from a child under age 16, we will delete that information
      as quickly as possible. If you believe that a child under 16 may have provided us Personal Data, please contact us
      at info@kikliko.com.

      What Rights Do You Have Regarding Your Personal Data? You have certain rights with respect to your Personal Data,
      including those set forth below. For more information about these rights, or to submit a request, please email
      info@kikliko.com. Please note that in some circumstances, we may not be able to fully comply with your request,
      such as if it is frivolous or extremely impractical, if it jeopardizes the rights of others, or if it is not
      required by law, but in those circumstances, we will still respond to notify you of such a decision. In some
      cases, we may also need to you to provide us with additional information, which may include Personal Data, if
      necessary to verify your identity and the nature of your request.<br><br>

      • Access: You can request more information about the Personal Data we hold about you and request a copy of such
      Personal Data.

      • Rectification: If you believe that any Personal Data we are holding about you is incorrect or incomplete, you
      can request that we correct or supplement such data. You can also correct some of this information directly by
      updating any of the information contained in your user profile.

      • Erasure: You can request that we erase some or all of your Personal Data from our systems.

      • Withdrawal of Consent: If we are processing your Personal Data based on your consent (as indicated at the time
      of collection of such data), you have the right to withdraw your consent at any time. Please note, however, that
      if you exercise this right, you may have to then provide express consent on a case-by-case basis for the use or
      disclosure of certain of your Personal Data, if such use or disclosure is necessary to enable you to utilize some
      or all of our Services.

      • Portability: You can ask for a copy of your Personal Data in a machine-readable format. You can also request
      that we transmit the data to another controller where technically feasible.

      • Objection: You can contact us to let us know that you object to the further use or disclosure of your Personal
      Data for certain purposes.

      • Restriction of Processing: You can ask us to restrict further processing of your Personal Data.

      • Right to File Complaint: You have the right to lodge a complaint about KIKLIKO’s practices with respect to your
      Personal Data with the supervisory authority of your country or EU Member State.<br><br>


      <b>Personal Information We Collect</b><br><br>

      We collect certain “Personal Information,” as defined by the California Consumer Privacy Act 2018 (“CCPA”) and as
      listed in the section of the main body of the Privacy Policy titled “What Information Do We Collect About You?”
      and have done so within the past 12 months.<br><br>

      Personal Information does not include: (a) publicly available information from government records; (b)
      deidentified information or aggregate consumer information.<br><br>

      We obtain the categories of Personal Information listed in the “What Information Do We Collect About You?” section
      above from the following categories of sources:<br><br>

      • Directly from you

      • Indirectly when you use our services (e.g. via your web browser or device, or via cookies)

      • Third Party Services (e.g. Facebook or Twitter)

      • Integration Partners<br><br>

      <b>Use of Personal Information</b><br><br>

      • We may use the Personal Information we collect for the following purposes:

      • Improve, understand, personalize and operate our Services

      • Promote the safety, integrity, and security of our Services

      • Communicate with you

      • Perform other business purposes<br><br>

      <b>Sharing Personal Information</b><br><br>

      We do not sell your Personal Information. We disclose Personal Information with the third parties and for the
      purposes listed in the section of the main body of the Privacy Policy titled “How, and With Whom, Is My
      Information Shared?” and have done so within the past 12 months. In particular, over the past 12 months we have
      shared:<br><br>

      • Identifiers (such as name, IP address and email address) with service providers that provide cloud computing,
      analytics, performance and availability monitoring, content moderation services, email marketing and messaging
      notification services, and with Third Party Services that you used or otherwise interacted with.

      • Internet network activity information (such as your interaction with our Services and related log data) with
      service providers that provide cloud computing, analytics, performance and availability monitoring and content
      moderation services.

      • Electronic information with service providers that provide cloud computing, analytics and performance and
      availability monitoring services, and with Third Party Services that you used or otherwise interacted with.

      • Geolocation data with service providers that provide cloud computing, analytics, performance and availability
      monitoring and content moderation services, and with Third Party Services that you used or otherwise interacted
      with.<br><br>

      <b>Rights</b><br><br>

      If you are a resident of California, you will have additional rights under the CCPA with respect to your Personal
      Information, as outlined below.

      • Right to Know: You may, not more than twice in a twelve-month period, request more information about the
      Personal Information we collect, use, or disclose, and request a copy of such Personal Information by emailing us
      at info@kikliko.com. You can also access certain of your Personal Information by logging on to your account.

      • Right to Request Deletion: You may request that we delete the Personal Information that we have collected from
      you by emailing us at info@kikliko.com. However, we may not be required to delete your Personal Information under
      certain circumstances, including if such data is necessary for us to provide you with the Services, if we use such
      data only for our internal analytical use, and if such data is needed to complete a transaction or other action
      you have requested. If you request that we delete your Personal Information, you may no longer be able to use the
      Services.

      • Right to Non-Discrimination: We will not discriminate against you for exercising your privacy rights under the
      CCPA.

      To protect your information, we may need to verify your identity before processing your request to exercise your
      “right to know” and “right to request deletion.” In some cases we may need to collect additional information to
      verify your identity, such as a government issued ID.<br><br>

      Under the CCPA, you may exercise these rights yourself or you may designate an authorized agent to make these
      requests on your behalf. We may request that your authorized agent have written permission from you to make
      requests on your behalf and may need to verify your authorized agent’s identity.<br><br>

      If you have questions about this section or how to exercise your rights, please contact us by emailing
      info@kikliko.com.<br><br>
    </p>
  </div>
</template>

<script>
  export default {
    name: "Privacy",
    metaInfo() {
      return {
        title: 'Privacy Policy',
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `Privacy policy of KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`
          },
        ],
      };
    },
  }
</script>

<style lang="scss" scoped>

</style>
