<template>
  <div class="iframe-container">
    <iframe src="https://app.theneo.io/kikliko/api" class="api-iframe"
            frameborder="0" scrolling="yes"></iframe>
  </div>
</template>

<script>
  export default {
    name: "Extensions",
    metaInfo() {
      return {
        title: 'API',
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: `API of KikLiko.com. Discover your favorite GIFs, stickers and clips to make your conversations more positive and fun. All kind of Reactions and impressions in one platform.`
          },
        ],
      };
    },
    data() {
      return {
        section_tags_style_display: '',
      }
    },

    mounted() {
      this.$nextTick(() => {
        document.getElementById('app').style.marginBottom = '0'
        document.getElementById('app').classList.remove('custom-container')

        this.section_tags_style_display = document.getElementById('section-tags').style.display
        document.getElementById('section-tags').style.display = 'none'
      })
    },
    beforeDestroy() {
      document.getElementById('app').classList.add('custom-container')
      document.getElementById('section-tags').style.display = this.section_tags_style_display
    }
  }
</script>

<style lang="scss" scoped>
  .iframe-container {
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);

    .api-iframe {
      display: block;
      width: 100%;
      height: calc(100vh - 174px);
    }
  }

  @media only screen and (max-width: 1600px) {
    .iframe-container {
      .api-iframe {
        height: calc(100vh - 164px);
      }
    }
  }

  @media only screen and (max-width: 1366px) {
    .iframe-container {
      .api-iframe {
        height: calc(100vh - 154px);
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .iframe-container {
      .api-iframe {
        height: calc(100vh - 130px);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .iframe-container {
      .api-iframe {
        height: calc(100vh - 120px);
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .iframe-container {
      .api-iframe {
        height: calc(100vh - 128px);
      }
    }
  }

  @media only screen and (max-width: 428px) {
    .iframe-container {
      .api-iframe {
        height: calc(100vh - 155px);
      }
    }
  }
</style>
